<template>
  <v-dialog
    persistent
    v-model="tmpdialog"
    max-width="500px"
  >
    <v-card>
      <v-card-text>
        <v-row>
          <v-text-field
              hide-details
              prepend-icon="mdi-magnify"
              single-line
              v-model="tmpfilter.text"
              class="mt-3"
              label="項目"
              @change="bkOrigin"
          ></v-text-field>
        </v-row>
        <v-row>
          <v-select
            v-model="tmpfilter.status"
            hide-details
            :items="listStatus"
            label="状況"
            clearable
            class="mt-3"
            multiple
            @change="bkOrigin"
          >
            <template v-slot:selection="{item,index}">
              <v-chip v-if="index===0">
                <span>{{ item }}</span>
              </v-chip>
              <span
                v-if="index === 1"
                class="grey--text text-caption"
              >
                (+{{ tmpfilter.status.length - 1 }} others)
              </span>
            </template>
          </v-select>
        </v-row>
        <v-row>
          <v-select
            v-model="tmpfilter.usersname"
            hide-details
            :items="listUsername"
            label="担当"
            clearable
            class="mt-3"
            multiple
            @change="bkOrigin"
          >
              <template v-slot:selection="{item,index}">
              <v-chip v-if="index===0">
                  <span>{{ item }}</span>
              </v-chip>
              <span
                  v-if="index === 1"
                  class="grey--text text-caption"
              >
                  (+{{ tmpfilter.usersname.length - 1 }} others)
              </span>
              </template>
          </v-select>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="blue darken-1"
          text
          @click="resetCondition()"
        >
          リセット
        </v-btn>
        <v-btn
          color="blue darken-1"
          outlined
          right
          @click="cancelCondition"
        >
          キャンセル
        </v-btn>
        <v-btn
          color="blue darken-1"
          outlined
          right
          @click="setCondition()"
        >
          設定
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// import axios from 'axios'
export default {
  props: [
    'tmpdialog',
    'listStatus',
    'listUsername',
    'tmpfilter'
  ],
  data: () => ({
    countupdate: 0
  }),
  methods: {
    bkOrigin: function () {
      this.countupdate = this.countupdate + 1
    },
    setCondition: function () {
      this.tmpfilter.filtername = ''
      this.closeForm()
    },
    resetCondition: function () {
      this.tmpfilter.text = ''
      this.tmpfilter.status = []
      this.tmpfilter.usersname = []
    },
    cancelCondition: function () {
      // キャンセルにてフォームクローズする場合には設定を戻す
      if (this.countupdate !== 0) {
        var tmpFil = JSON.parse(sessionStorage.getItem('condition'))
        this.tmpfilter.text = tmpFil.text
        this.tmpfilter.status = tmpFil.status
        this.tmpfilter.usersname = tmpFil.usersname
      }
      this.closeForm()
    },
    closeForm: function () {
      sessionStorage.removeItem('condition')
      this.countupdate = 0
      this.tmpdialog = false
      this.$emit('updateCurrentProfile')
    }
  }
}
</script>
