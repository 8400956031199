<template>
  <v-app>
    <!-- 外部アクセス時のヘッダー/フッター表示制御 -->
    <div
      v-if="checkURL(['ReserveOfHP', 'TypingGame', 'testupload']) === false"
    >
    <v-app-bar
      dense
      app
      clipped-left
      color="deep-orange lighten-2"
      elevation="0"
    >
      <v-app-bar-nav-icon
        @click.stop="showMenu"
      ></v-app-bar-nav-icon>
      <v-app-bar-title>
        <v-row>
          <v-col class="d-flex">
            <v-img
              aspect-ratio="100"
              src="@/assets/nudibranch_icon.png"
              width="35"
              height="35"
              class="mt-3"
            ></v-img>
            <v-spacer></v-spacer>
            <v-img
              src="@/assets/nudibranch_text.png"
              width="200"
              class="mt-1"
            ></v-img>
          </v-col>
        </v-row>
      </v-app-bar-title>
      <span style="color:#FFF3E0 ; font-size:small">version{{info_app.version}}</span>

      <v-spacer></v-spacer>
      <span style="color:#FFF3E0 ; font-size:small">ユーザー/</span>
      <span style="color:#FFF3E0 ; font-size:midium">{{info_user.username}}</span>
      <v-btn
        text
        @click="logout"
      >
        <v-icon color="black">
          mdi-login
        </v-icon>
        <span style="color: black">Logout</span>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer
      permanent
      app
      clipped
      :expand-on-hover = "flgHover"
      :mini-variant = "flgMini"
      width="210"
    >
      <v-list>
        <v-list-item
          v-for=" item of itemMenu"
          :key=item.text
          @click="actionMenu(item)"
          link
        >
            <v-list-item-icon
            >
              <v-icon color="orange" v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-title v-text="item.text"></v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    </div>
    <v-main>
      <router-view/>
    </v-main>
    <div
      v-if="checkURL(['ReserveOfHP', 'TypingGame', 'testupload']) === false"
    >
    <v-footer
      color="deep-orange lighten-2"
      padless
      app
    >
      <v-col class="text-right">
        <span style="color:#FFF3E0">©{{info_app.copyright_year}}</span>
      </v-col>
      <v-col class="text-left">
          <span style="color:#FFF3E0">{{info_app.copyright_corp}}</span>
      </v-col>
    </v-footer>
    </div>
  </v-app>
</template>

<script>
import { getAuth, signOut } from 'firebase/auth'
const axios = require('axios')
export default {
  name: 'App',
  head: {
    link: [
      {
        rel: 'icon',
        href: '/static/favicon.ico'
      }
    ]
  },
  data: () => ({
    info_app: {
      copyright_year: 'xxx',
      copyright_corp: 'xxx'
    },
    info_user: {},
    flgMini: true,
    flgHover: true,
    itemMenu: [
      {
        icon: 'mdi-home-outline',
        text: 'Ticket',
        path: '/ticketlist/0'
      },
      {
        icon: 'mdi-fish',
        text: 'WorkReport',
        path: '/workreport'
      },
      {
        icon: 'mdi-note-edit',
        text: 'Project',
        path: '/projectlist'
      },
      {
        icon: 'mdi-map',
        text: 'User',
        path: '/userlist'
      },
      {
        icon: 'mdi-book-open-variant',
        text: 'Reservation',
        path: '/reservationlist'
      },
      {
        icon: 'mdi-human-male-board',
        text: 'Events',
        path: '/eventlist'
      },
      {
        icon: 'mdi-calendar-clock',
        text: 'Calendars',
        path: '/calendars'
      }
    ]
  }),
  mounted () {
    if (this.checkURL(['ReserveOfHP', 'TypingGame']) === false) {
      this.getData()
    }
  },
  methods: {
    getData: async function () {
      await axios.get('/api/infoapp'
      ).then(response => {
        if (response.data != null) {
          this.info_app = response.data
          if (sessionStorage.getItem('user') !== null) {
            this.info_user = JSON.parse(sessionStorage.getItem('user'))
          }
        }
      }).catch(error => {
        alert(error)
      })
    },
    showMenu: function () {
      if (this.flgMini) {
        this.flgMini = false
        this.flgHover = false
      } else {
        this.flgMini = true
        this.flgHover = true
      }
    },
    actionMenu: function (item) {
      this.$router.push({ path: item.path })
    },
    // ログアウト
    logout: function () {
      axios.post('/api/logout')
      const auth = getAuth()
      signOut(auth).then(() => {
        sessionStorage.removeItem('user')
        this.info_user = {}
        alert('サインアウトしました。')
        this.$router.push({ path: '/login' })
      }).catch((error) => {
        alert(error)
      })
    },
    checkURL: function (ul) {
      if (ul.includes(this.$route.name)) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>
