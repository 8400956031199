<template>
  <v-dialog
    persistent
    v-model="flgDialog"
    width="500"
  >
    <v-card
      class="mx-auto"
    >
      <v-card-title class="ml-2">ユーザー登録</v-card-title>
      <v-card-text class="mt-3">
        <v-row dense>
          <v-col cols=8>
            <v-text-field
              label="ID"
              v-model="userdetail.id"
              :disabled="flgEdit"
            >
            </v-text-field>
          </v-col><v-col>
            <v-text-field
              label="社員番号"
              v-model="userdetail.staff_id"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
          <v-text-field
            label="Name"
            v-model="userdetail.name"
          >
          </v-text-field>
          </v-col><v-col>
            <v-text-field
              label="User Name"
              v-model="userdetail.username"
            >
          </v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-select
            v-model="userdetail.corp"
            :items="corps"
            label="会社名"
          >
          </v-select>
        </v-row>
        <v-row>
          <v-col cols="3">
            <v-checkbox
              v-model="userdetail.work"
              label="出勤簿"
            ></v-checkbox>
          </v-col>
          <v-col cols="3">
            <v-select
              v-model="userdetail.status"
              :items="statuslist"
              label="Status"
            ></v-select>
          </v-col>
          <v-col cols="6">
            <v-menu
              v-model="menu1"
              :close-on-content-click="false"
              max-width="290"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  label="入社日"
                  v-model="userdetail.dt_entry"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="userdetail.dt_entry"
                no-title
                scrollable
                @input="menu1=false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="3">
            <v-select
              v-model="userdetail.permission"
              :items="permissionlist"
              label="権限"
            ></v-select>
          </v-col>
           <v-col cols="3">
            <v-checkbox
              v-model="userdetail.external"
              label="外部"
            ></v-checkbox>
          </v-col >
          <v-col cols="6" class="ml-8 mt-5">最終更新日:{{userdetail.dt_update}}</v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="red"
          outlined
          right
          @click="deleteDB"
        >
          削除
        </v-btn>
        <v-spacer/>
        <v-btn
          color="deep-purple lighten-2"
          outlined
          right
          @click="closeForm"
        >
          キャンセル
        </v-btn>
        <v-btn
          color="green lighten-2"
          outlined
          right
          @click="uploadData"
        >
          更新
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>>

<script>
const axios = require('axios')
export default {
  props: [
    'flgDialog',
    'corps',
    'userdetail',
    'flgEdit',
    'permissionlist',
    'statuslist'
  ],
  data: () => ({
    menu1: false
  }),
  methods: {
    // キャンセル
    closeForm: function () {
      this.flgDialog = false
      this.$emit('flgReset', false)
      this.$router.go({ path: this.$router.currentRoute.path, force: true })
    },
    // 新規登録
    uploadData: async function () {
      if (this.userdetail.id !== '') {
        await axios.post('/api/users', this.userdetail).then(function (response) {
          if (response.data.status === false) {
            alert(response.data.msg)
          }
        })
        this.$router.go({ path: this.$router.currentRoute.path, force: true })
      } else {
        alert('IDを設定してください。')
      }
    },
    // 削除
    deleteDB: async function () {
      await axios.delete('/api/users?id=' + this.userdetail.id).then(function (response) {
        if (response.data.status === false) {
          alert(response.data.msg)
        }
      })
      this.$router.go({ path: this.$router.currentRoute.path, force: true })
    }
  }
}
</script>
