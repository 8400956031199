<template>
  <v-app>
    <v-container fluid fill-height class="home-hero__content">
      <!-- 各種情報 -->
      <v-row dense>
        <v-col
          class = "d-flex"
        >
        <!-- ランキング -->
        <v-card
          class="mx-auto"
          width="370"
          height="370"
        >
          <v-card-subtitle class="text-center text-h6">
            ランキング
          </v-card-subtitle>
          <v-card-text>
            <v-row>
              <v-data-table
                class="mx-auto"
                :headers="headers"
                :items="ranklist"
                items-per-page="5"
                hide-default-footer
                disable-sort
                dense
              >
              </v-data-table>
            </v-row>
            <v-row
              align-content="center"
              justify="center"
            >
              <v-btn
                class="mt-5"
                @click="saveScore"
                outlined
                color="blue"
              >
                とうろく
              </v-btn>
            </v-row>
          </v-card-text>
        </v-card>
        <!-- 課題 -->
        <v-card
          class="mx-auto"
          width="500"
        >
          <!-- 対象単語 -->
            <v-row
              justify="center"
              align-content="center"
              style="line-height: 1.0; height: 100px;"
              class="ma-2"
            >
              <!-- 未入力の場合 -->
              <div
                v-if = "inputword.length === 0"
              >
                <span style = "font-size: 50px;">
                  {{toUpperLowerCaseEx(currentword.word, modeGameUppaer)}}
                </span>
              </div>
              <!-- 入力後の場合 -->
              <div v-else>
                <span style = "color:lightgray; font-size: 50px;"><!--
                -->{{toUpperLowerCaseEx(getCurrentWordDivid(currentword.word, inputword)[0], modeGameUppaer)}}<!--
                --></span><!--
                --><span style="font-size: 50px"><!--
                -->{{toUpperLowerCaseEx(getCurrentWordDivid(currentword.word, inputword)[1], modeGameUppaer)}}<!--
                --></span>
              </div>
            </v-row>
          <!-- 意味 -->
            <v-row style="line-height: 1.0; height: 45px; font-size: 20px;" justify="center">
              <span v-html="currentword.mean"></span>
            </v-row>
          <!-- エラーメッセージ -->
            <v-row style="line-height: 1.0; height: 30px;" justify="center">
              {{msg}}
            </v-row>
          <!-- ゲーム操作 -->
            <!-- レベル等 選択 -->
            <v-row class="mx-auto mt-3" style="height: 70px;">
              <v-col cols="6">
                <v-select
                  label="表示モード"
                  :items="['通常', '大文字', '小文字']"
                  v-model="modeGameUppaer"
                  @change="changeMode"
                  class ="mx-auto"
                  :disabled ="resGame.status"
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-select
                  label="レベル"
                  :items="levelnamelist"
                  v-model="currentLevelName"
                  @change="setWordList"
                  class ="mx-auto"
                  :disabled ="resGame.status"
                ></v-select>
              </v-col>
            </v-row>
            <!-- スタート等操作 -->
            <v-row class="mx-auto mt-2 mb-2" style="height: 50px;">
              <v-col>
                あと---{{ currentlevel.count - inputwordlist.length }}---<ruby><rd>個</rd><rt>こ</rt></ruby>
              </v-col>
              <v-col>
                <v-btn
                  @click="startCountDown"
                  :disabled ="resGame.status"
                  outlined
                  color="green"
                >
                  スタート
                </v-btn>
              </v-col>
              <v-col>
                <v-btn
                  outlined
                  @click="stopGame(true)"
                  color="red"
                >
                  ストップ
                </v-btn>
              </v-col>
            </v-row>
            <!-- 次レベル迄のチャレンジ回数の表示 -->
            <v-row  justify="center">
              <div v-if="getChallengeCount('current').next > 0">
                レベルアップまであと{{ getChallengeCount('current').next }}<ruby><rb>回</rb><rt>かい</rt></ruby>
              </div>
              <div v-if="getChallengeCount('current').next === 0">
                {{currentLevelName}}は<ruby><rb>合格</rb><rt>ごうかく</rt></ruby>しています
              </div>
            </v-row>
          <v-overlay absolute :value="overlay">
            {{numCountDown}}
          </v-overlay>
        </v-card>
        <!-- 結果情報 -->
        <v-card
          class="mx-auto"
          width="370"
          height="370"
        >
          <v-row style="height:130px" dense>
            <v-col>
              <v-card-subtitle class="text-center text-h6">
                [レベル]
              </v-card-subtitle>
              <v-card-text>
                <v-row justify="center">
                  <div style="color:purple; font-size: 30px">{{personaldat.info.level}}</div>
                </v-row>
                <v-row  justify="center">
                  <div style="color:purple; font-size: 12px" class="mt-2">
                    レベルアップした<ruby><rd>日</rd><rt>ひ</rt></ruby><br>
                    {{personaldat.info.dt_level}}
                  </div>
                </v-row>
              </v-card-text>
            </v-col>
            <v-col>
              <v-card-subtitle class="text-center text-h6">
                {{personaldat.info.name}}
              </v-card-subtitle>
            </v-col>
          </v-row>
          <v-row dense>
            <v-card-text>
              <v-col class="align-center">
                [<ruby><rb>最後</rb><rt>さいご</rt></ruby>のチャレンジの<ruby><rb>結果</rb><rt>けっか</rt></ruby>]
                <table>
                  <tr>
                    <td width="120">レベル</td>
                    <td width="10">{{getLastChallenge().level}}</td>
                    <td width="40"></td>
                    <td>*</td>
                    <td width="120">チャレンジ</td>
                    <td v-if="getChallengeCount().all === -1" width="10">0</td>
                    <td v-else width="10">{{getChallengeCount().all}}</td>
                    <td width="40"><ruby><rb>回</rb><rt>かい</rt></ruby></td>
                  </tr>
                  <tr>
                    <td><ruby><rb>時間</rb><rt>じかん</rt></ruby></td>
                    <td>{{getLastChallenge().period}}</td>
                    <td><ruby><rb>秒</rb><rt>びょう</rt></ruby></td>
                    <td>*</td>
                    <td><ruby><rb>入力</rb><rt>にゅうりょく</rt></ruby></td>
                    <td>{{getLastChallenge().count}}</td>
                    <td><ruby><rb>文字</rb><rt>もじ</rt></ruby></td>
                  </tr>
                  <tr>
                    <td>1<ruby><rb>秒</rb><rt>びょう</rt></ruby>あたり</td>
                    <td>{{getLastChallenge().ratio}}</td>
                    <td><ruby><rb>文字</rb><rt>もじ</rt></ruby></td>
                    <td>*</td>
                    <td><ruby><rb>間違</rb><rt>まちが</rt></ruby>い</td>
                    <td>{{getLastChallenge().ratiomiss}}</td>
                    <td>%</td>
                  </tr>
                  <tr>
                    <td><ruby><rb>単語数</rb><rt>たんごすう</rt></ruby></td>
                    <td>{{getLastChallenge().countword}}</td>
                    <td><ruby><rb>個</rb><rt>こ</rt></ruby></td>
                    <td>*</td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>クリア</td>
                    <td v-if="getChallengeCount().ok === -1" width="10">0</td>
                    <td v-else>{{getChallengeCount().ok}}</td>
                    <td><ruby><rb>回</rb><rt>かい</rt></ruby></td>
                    <td>*</td>
                    <td v-if="getChallengeCount().next === 0"><ruby><rb>合格済</rb><rt>ごうかくずみ</rt></ruby></td>
                    <td v-else-if="getChallengeCount().next >= 0">レベルアップ<ruby><rb>迄</rb><rt>まで</rt></ruby></td>
                    <td v-if="getChallengeCount().next > 0">{{getChallengeCount().next}}</td>
                    <td v-if="getChallengeCount().next > 0"><ruby><rb>回</rb><rt>かい</rt></ruby></td>
                  </tr>
                </table>
              </v-col>
            </v-card-text>
          </v-row>
        </v-card>
        </v-col>
      </v-row>
      <!-- キーボード -->
      <v-row dense no-gutters>
        <v-col cols = "2" align = "right">
        <v-card
          width="150"
        >
          <v-img
            src="../assets/handl.png"
            aspect-ratio="1"
            cover
          >
          </v-img>
        </v-card>
        </v-col>
        <v-col cols ="8">
        <v-card
          class="mx-auto"
          width="1000"
          outlined
        >
          <v-card-text>
            <v-row
              v-for= "i of 5" :key= "i"
            >
              <v-col align="center">
                <v-btn
                  v-for ="kb of keybtn[i-1]"
                  :key="kb"
                  :outlined = kb.deactive
                  :color = kb.color
                  width="10"
                  :class="modeStr"
                >
                {{kb.key1}}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        </v-col>
        <v-col cols = "2" align = "left">
        <v-card
          max-width="150"
        >
        <v-img
          src="../assets/handr.png"
          aspect-ratio="1"
          cover
        >
        </v-img>
        </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-overlay :value="overlaymsg">
      <v-img
        src = "../assets/levelup.jpg"
      >
        <p>おめでとうございます。<br>
        {{msglevelup}}</p>
      </v-img>
    </v-overlay>
  </v-app>
</template>

<style scoped>
td {
  text-align: center;
  font-size: 12px;
  height: 5;
}
.home-hero__content {
  background: url("../assets/imgBackground1.png");
  background-size: cover;
  background-position: center bottom;
}
.card-actions {
  position: absolute;
  bottom: 0;
}
p {
  position: absolute;
  top: 50%;
  left: 15%;
  font-size:20px;
  font-weight:bold;
  color:black;
  padding:0;
  margin:0;
}
</style>

<script>
// 音源のリソース
import audiBeep from '../assets/g_beep.mp3'
import audiStart from '../assets/g_start.mp3'
import audiEnd from '../assets/g_end.mp3'
import audiCount from '../assets/g_countdown.mp3'
import audiMiss from '../assets/g_miss.mp3'

const axios = require('axios')
export default {
  data: () => ({
    personaldat: { info: { id: 'NoID' }, score: [] }, // 個人データ
    key: '', // 打鍵されたキー
    keys: {}, // キーコードの設定
    keybtn: [], // 画面描画用 キーボードの配置配列(行/列)
    keybtnarr: {}, // 入力キーをインデックスとしたキーの単純配列
    words: [], // 設問のワードリスト
    wordNum: 0, // ワードリストの入力対象の番号
    currentword: {}, // 入力対象のワード
    inputword: '', // 打鍵入力したワード
    inputwordlist: [], // 打鍵入力したワードリスト
    misstypelist: [], // 打鍵ミスしたワードリスト
    msg: '', // 画面上のメッセージ
    msglevelup: '', // レベルアップ用メッセージ
    timeStart: '', // 打鍵開始時間
    timeEnd: '', // 打鍵終了時間
    resGame: {
      level: '',
      count: 0,
      countword: 0,
      ratio: 0,
      ratiomiss: 0,
      period: 0,
      status: false
    }, // ゲームの結果
    modeGameUppaer: '通常', // アルファベットの表示設定(大文字/小文字)
    modeStr: 'custom-transform-class text-none text-truncate', // 大文字小文字制御 設定文字列
    currentlevel: {}, // 現在のレベル
    currentLevelName: '', // 現在のレベル名
    levellist: {}, // レベルデータのリスト
    levelnamelist: [], // レベル名のリスト
    ranklist: [], // ランキングリスト
    absolute: true,
    overlay: false,
    numCountDown: 3,
    idTimer: '',
    sound: {},
    overlaymsg: false
  }),
  watch: {
    overlaymsg (val) {
      val && setTimeout(() => {
        this.overlaymsg = false
      }, 3000)
    }
  },
  mounted () {
    // ゲーム初期処理
    this.iniGame()
    // 音源の初期処理
    this.iniSound()
    // イベントリスナーの登録
    document.addEventListener('keydown', this.onKeyDown)
  },
  beforeDestroy () {
    // イベントリスナーの削除
    document.removeEventListener('keydown', this.onKeyDown)
  },
  computed: {
    headers () {
      return [
        { text: 'ランク', value: 'rank' },
        { text: 'ネーム', value: 'name', width: 80 },
        { text: 'スピード', value: 'ratio' },
        { text: 'レベル', value: 'level' }
      ]
    }
  },
  methods: {
    // 特定のレベルのチャレンジ回数
    getChallengeCount: function (flg) {
      // flg : 現在のレベル or 個人の履歴データ
      // all : 対象レベルのチャレンジ回数、ok: 対象レベルのクリア回数, 対象レベル迄の必要クリア回数
      var resCount = { all: 0, ok: 0, next: 0 }

      if (this.personaldat.info.id !== 'NoID' && this.personaldat.score.length !== 0) {
        // 登録済ID 且つ 個人の履歴データが存在する場合

        // 規定クリア回数の設定
        const clearcount = 3

        // 対象レベルの設定
        var tmpLevel = null
        if (flg === 'current') {
          tmpLevel = this.currentlevel
        } else {
          var scorecount = this.personaldat.score.length
          tmpLevel = this.levellist[this.personaldat.score[scorecount - 1].level]
        }

        // 全チャレンジ回数
        var tmpCount = this.personaldat.score.filter((value) =>
          value.level === tmpLevel.level
        )
        resCount.all = tmpCount.length

        // 成功チャレンジ回数
        if (tmpLevel.criteriatype === 'speed') {
          tmpCount = tmpCount.filter((value) =>
            value.ratio >= tmpLevel.criteria
          )
          resCount.ok = tmpCount.length
          // レベルアップ迄の回数(3回)
          resCount.next = clearcount - resCount.ok
        } else {
          resCount.ok = resCount.all
          // レベルアップ迄の回数
          resCount.next = tmpLevel.criteria - resCount.ok
        }
        // クリア迄のチャレンジ回数が0より小さい場合には0を設定する
        if (resCount.next < 0) {
          resCount.next = 0
        }
      } else {
        // 対象外の場合
        resCount = { all: -1, ok: -1, next: -1 }
      }

      // データの返却
      return resCount
    },
    // 最後のチャレンジ結果の取得
    getLastChallenge: function () {
      var scorecount = this.personaldat.score.length
      if (this.personaldat.info.id !== 'NoID' && scorecount !== 0) {
        return {
          level: this.personaldat.score[scorecount - 1].level,
          period: this.personaldat.score[scorecount - 1].period,
          count: this.personaldat.score[scorecount - 1].count,
          ratio: this.personaldat.score[scorecount - 1].ratio,
          ratiomiss: this.personaldat.score[scorecount - 1].missratio,
          countword: this.personaldat.score[scorecount - 1].countword
        }
      } else {
        return {
          level: this.resGame.level,
          period: this.resGame.period,
          count: this.resGame.count,
          ratio: this.resGame.ratio,
          ratiomiss: this.resGame.ratiomiss,
          countword: this.resGame.countword
        }
      }
    },
    // カウントダウン
    startCountDown: function () {
      this.overlay = true
      this.idTimer = setInterval(() => {
        // 3秒カウントダウンしたらスタート
        if (this.numCountDown <= 0) {
          clearInterval(this.idTimer)
          this.overlay = false
          this.numCountDown = 3
          // スタート音を設定
          this.sound.start.play()
          // ゲームスタート
          this.startGame()
        }
        this.numCountDown = this.numCountDown - 1
        this.sound.count.play()
      }, 1000)
    },
    // ゲームのスタート処理
    startGame: async function () {
      this.resetGameStatus()
      // 設問用のワードリスト取得
      await this.getWordList()
      // キーボードのアクティブボタンの設定
      this.activateKeyBtn()
      // ゲームステータスの設定
      this.resGame.status = true
      // ゲームスタート時間の設定
      this.timeStart = new Date()
    },
    // ゲームの終了処理
    stopGame: function (flg = false) {
      // ゲームがスタートしていない場合は処理しない
      if (this.resGame.status === false) {
        return
      }
      // アクティブキーのリセット
      for (const k in this.keybtnarr) {
        this.keybtnarr[k].deactive = true
      }
      alert('終了')
      this.resGame.status = false
      if (flg === false) {
        // 終了時間の設定
        this.timeEnd = new Date()
        // 統計データの算出
        this.resGame.level = this.currentlevel.level
        this.resGame.period = (this.timeEnd.getTime() - this.timeStart.getTime()) / 1000
        this.resGame.count = this.countStr(this.inputwordlist)
        this.resGame.countword = this.inputwordlist.length
        this.resGame.ratio = Math.round(this.resGame.count / this.resGame.period * 100) / 100
        this.resGame.ratiomiss = Math.round(this.misstypelist.length / (this.resGame.count + this.misstypelist.length) * 100 * 100) / 100
        this.saveScore()
      }
    },
    // ゲーム情報のリセット
    resetGameStatus: function () {
      // エラーメッセージのリセット
      this.msg = ''
      // ゲーム情報のリセット
      this.resGame.status = false
      // 対象単語/入力単語等のリセット
      this.inputword = ''
      this.currentword = {}
      this.inputwordlist = []
      this.misstypelist = []
      this.wordNum = 0
    },
    // 次に打鍵するキーのアクティブ化
    activateKeyBtn: function () {
      // アクティブキーのリセット
      for (const k in this.keybtnarr) {
        this.keybtnarr[k].deactive = true
      }
      // 入力文字列の長さ取得
      const intPos = this.inputword.length
      // 入力文字列の次の入力文字の取得
      const nextStr = this.currentword.word.substring(intPos, intPos + 1)
      // 次の入力文字列のキーのアクティブ化(deactive=false)
      this.keybtnarr[nextStr.toLowerCase()].deactive = false
    },
    // 次のレベルの設定
    setNextLevel: function () {
      if (this.personaldat.info.level === 'T特1') {
        // 最高レベルに到達している場合には、現在のレベルを設定
        this.currentlevel = this.levellist['T特1']
      } else if (this.personaldat.info.level === 'T33') {
        // 最初のレベル(T33)の場合は、自動的にT32を設定
        this.currentlevel = this.levellist.T32
      } else {
        // その他の場合
        // 現在のレベルの取得
        const cLv = this.personaldat.info.level
        // 現在のレベルの順序を取得
        const cOrder = this.levellist[cLv].order
        // 次のレベルの取得と設定
        for (var lv in this.levellist) {
          if (this.levellist[lv].order === cOrder + 1) {
            this.currentlevel = this.levellist[lv]
            return
          }
        }
      }
    },
    // 選択したレベルを設定
    setWordList: function () {
      // 選択したレベルを設定
      this.currentlevel = this.levellist[this.currentLevelName]
    },
    // 入力時の処理
    onKeyDown: function (event) {
      // エラーメッセージのリセット
      this.msg = ''
      // 打鍵文字の設定
      this.key = event.key
      // 対象単語が設定されていない場合処理抜ける
      if (this.currentword === 0 || this.key === '') {
        return
      }
      // 打鍵完了している場合のチェック(完成形のチェック)
      if (this.checkStr(this.inputword + this.key, this.currentword.word, this.modeGameUppaer) === true) {
        // 打鍵単語リストに追加
        this.inputwordlist.push(this.currentword.word)
        this.inputword = this.inputword + this.key
        // ターゲットの単語数となった場合にゲーム終了
        if (this.inputwordlist.length >= this.currentlevel.count) {
          const playPromise = new Promise((resolve) => {
            this.sound.end.play()
            setTimeout(resolve, 1500)
          })
          playPromise.then(() => {
            this.stopGame()
          })
          return
        } else {
          // 打鍵音(正解)の設定
          this.sound.beep.play()
          // 単語数が満たない場合は次の単語の準備
          // 入力データのクリア
          this.inputword = ''
          // 新しい打鍵単語を設定
          this.wordNum += 1
          this.currentword = this.words[this.wordNum]
          // キーボードのアクティブボタンの設定
          this.activateKeyBtn()
          return
        }
      }
      // 打鍵完了していない場合
      // ターゲット1文字チェック
      const tmpStrCurrent = this.currentword.word.substring(this.inputword.length, this.inputword.length + 1)
      if (this.checkStr(this.key, tmpStrCurrent, this.modeGameUppaer) === true) {
        // 入力が正しい場合
        // 打鍵音(正解)の設定
        this.sound.beep.play()
        // 入力文字列の更新
        this.inputword = this.inputword + this.key
        // キーボードのアクティブボタンの設定
        this.activateKeyBtn()
      } else {
        // 入力間違いの場合
        // 打鍵音(不正解)の設定
        this.sound.miss.play()
        if (this.key !== 'Shift') {
          this.msg = '「' + this.key + '」はちがいます。'
          this.misstypelist.push({ word: this.currentword.word, miss: this.inputword + '[' + this.key + ']' })
        }
      }
    },
    // キーボードの表示文字の設定(大文字/小文字)
    changeMode: function () {
      if (this.modeGameUppaer === '通常') {
        this.modeStr = 'custom-transform-class text-none text-truncate'
      } else if (this.modeGameUppaer === '小文字') {
        this.modeStr = 'custom-transform-class text-none text-truncate'
      } else {
        this.modeStr = ''
      }
    },
    // ニックネームの重複チェック
    existedkName: function (nam) {
      // 特殊番号の場合はエラー
      if (nam === 999) { return true }
      // その他、ランキングリスト内での存在チェック
      for (var k = 0; k < this.ranklist.length; k++) {
        if (nam === this.ranklist[k].name) {
          return true
        }
      }
      return false
    },
    // スコアの登録処理
    saveScore: async function () {
      // データが存在しない場合には保存しない。
      if (this.resGame.count === 0) {
        alert('対象データが存在しません。')
        return
      }
      // ニックネームの登録(ニックネームの登録がない場合)
      if (this.personaldat.info.name === 'NoName') {
        var res = prompt('ランキングに表示する場合には、ニックネームを登録してください。')
        // 3回までエラーの場合に繰り返し、または、キャンセルを押下される迄
        var errCount = 0
        while (errCount <= 3 && res !== null) {
          // 登録アクション回数のカウント
          errCount = errCount + 1
          res = res.trim()
          // 12文字以下/空白以外/既存で存在しない場合には処理進行
          if (res.length > 0 && res.length <= 12 && this.existedkName(res) === false) {
            this.personaldat.info.name = res
            break
          } else {
            res = prompt('異なるニックネームもしくは12文字以下のニックネームを登録してください。')
          }
        }
      }
      var tmpScore = {
        dt: this.comDateToStr(this.timeStart, true),
        id: this.personaldat.info.id,
        name: this.personaldat.info.name,
        level: this.resGame.level,
        period: this.resGame.period,
        count: this.resGame.count,
        countword: this.resGame.countword,
        ratio: this.resGame.ratio,
        missratio: this.resGame.ratiomiss
      }
      // 更新前のレベルの一時保存
      const tmpLevel = this.personaldat.info.level
      await axios.post('/api/gametyping', tmpScore).then(response => {
        if (response.data.status === true) {
          // ランキングデータの更新/設定
          this.ranklist = this.mkRankingList(response.data.rank)

          // 個人の履歴データのチェック(ID登録済みの場合)
          if (this.personaldat.info.id !== 'NoID') {
            this.personaldat = response.data.personal
            // レベルアップしているかチェック
            if (this.personaldat.info.level !== tmpLevel) {
              // レベルアップしている場合
              // メッセージを表示
              this.overlaymsg = true
              this.msglevelup = this.personaldat.info.level + 'にレベルアップしました。'
              // 次の対象のレベルを取得
              this.setNextLevel()
              this.inputwordlist = []
              this.currentLevelName = this.currentlevel.level
            }
          }
        }
      })
    },
    // ランキングデータの生成
    mkRankingList: function (vlist) {
      vlist.sort((a, b) => a.rank - b.rank)
      return vlist.filter(x => x.rank !== 999)
    },
    // *******初期処理*******
    // ゲームの初期処理
    iniGame: async function () {
      // 学童との連携用のパラメータの設定
      var qr = this.$route.query.key
      var tmpUrl = '/api/gametyping'
      if (qr !== undefined) {
        tmpUrl = tmpUrl + '?key=' + encodeURIComponent(qr)
      }

      // データ取得
      await axios.get(tmpUrl).then((response) => {
        if (response.data != null) {
          // パーソナルデータの設定
          this.personaldat = response.data.personal

          // レベルリストの設定
          this.levellist = response.data.level
          // レベル名リストの生成
          for (const k in this.levellist) {
            this.levelnamelist.push(this.levellist[k].level)
          }
          // 次の対象レベルを設定
          this.setNextLevel()
          // 現在のレベル名の設定
          this.currentLevelName = this.currentlevel.level
          // キーボードの設定
          this.keys = response.data.keys
          this.setKeyboard(response.data.keyboard)

          // ランキングデータの設定
          this.ranklist = this.mkRankingList(response.data.rank)
        }
      }).catch(error => {
        alert(error)
      })
    },
    // キーボードの設定(初期)
    setKeyboard: function (kbs) {
      // キーボード描画用の配列の生成(行/列のキーボード配置)
      this.keybtn = [[], [], [], [], []]
      for (let i = 0; i < kbs.length; i++) {
        kbs[i].deactive = true
        this.keybtn[Number(kbs[i].row) - 1].push(kbs[i])
      }
      // キーの並べ替え
      for (let n = 0; n < 4; n++) {
        this.keybtn[n].sort((a, b) => a.rowcol - b.rowcol)
      }
      // キー操作用の配列の生成/key1をインデックスに設定(入力キーインデックスとしたキーの単純配列)
      for (let k = 0; k < 4; k++) {
        for (let m = 0; m < this.keybtn[k].length; m++) {
          this.keybtnarr[this.keybtn[k][m].key1] = this.keybtn[k][m]
        }
      }
    },
    // 効果音/音声の初期処理
    iniSound: function () {
      const listAudi = {
        beep: audiBeep,
        count: audiCount,
        end: audiEnd,
        start: audiStart,
        miss: audiMiss
      }
      // 効果音
      for (const audi in listAudi) {
        var tmpS = new Audio(listAudi[audi])
        tmpS.currentTime = 0
        tmpS.volume = 0.5
        this.sound[audi] = tmpS
      }
    },
    // 汎用関数
    // モードに合わせて文字形式変換(大文字/小文字)
    toUpperLowerCaseEx: function (tmpStr, mode) {
      if (mode === '通常') {
        return tmpStr
      } else if (mode === '小文字') {
        return tmpStr.toLowerCase()
      } else {
        return tmpStr.toUpperCase()
      }
    },
    // モードに合わせて文字の比較処理
    checkStr: function (strI, strC, mode) {
      var tmpStrI = strI
      var tmpStrC = strC
      if (mode === '小文字') {
        tmpStrI = tmpStrI.toLowerCase()
        tmpStrC = tmpStrC.toLowerCase()
      } else if (mode === '大文字') {
        tmpStrI = tmpStrI.toUpperCase()
        tmpStrC = tmpStrC.toUpperCase()
      }
      if (tmpStrI === tmpStrC) {
        return true
      } else {
        return false
      }
    },
    // 文字数の取得
    getCurrentwordLen: function () {
      if (!Object.keys(this.currentword).length) {
        return 0
      } else {
        return this.currentword.word.length + 1
      }
    },
    // 入力済文字を勘案して文字列を2つに分ける関数
    getCurrentWordDivid: function (tword, iword) {
      return [iword, tword.slice(iword.length, tword.length)]
    },
    // 配列に入っている文字の総文字数のカウント
    countStr: function (strlist) {
      var tmpCount = 0
      for (var k = 0; k < strlist.length; k++) {
        tmpCount = tmpCount + strlist[k].length
      }
      return tmpCount
    },
    // レベルに応じた設問を取得する関数
    getWordList: async function () {
      await axios.get('/api/wordlist?level=' + this.currentlevel.level).then(response => {
        if (response.data != null) {
          this.words = response.data // ワードリストの設定
          this.currentword = this.words[0] // 最初のワード設定
        }
      })
    }
  }
}
</script>
