<template>
  <v-card>
    <v-card-title>ファイルリスト</v-card-title>
    <v-card-text>
      <!-- パス -->
      <v-row style="height:40px">
        <v-col cols="5">
          <v-text-field
              label="New Folder"
              v-model="tmpFolder"
              prepend-icon="mdi-folder-plus-outline"
              dense
          >
          </v-text-field>
        </v-col>
        <v-col cols="1" class = "mt-2">/</v-col>
      </v-row>
      <!--ファイル -->
      <v-row style="height:40px">
        <v-col cols="10">
          <v-file-input
            label="Upload File"
            v-model="selectedfile1"
            dense
          ></v-file-input>
        </v-col>
      </v-row>
      <v-row style="height:40px">
        <v-col cols="10">
          <v-file-input
            label="Upload File"
            v-model="selectedfile2"
            dense
          ></v-file-input>
        </v-col>
      </v-row>
      <!-- アップロード -->
      <v-row>
        <v-col cols="2">
          <v-btn
            @click="uploadfile"
            class="mr-5 mt-4"
            text
          >
            <v-icon>
              mdi-upload
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <!--データ取得-->>
      <v-row>
        <v-text-field
          placeholder="DataName"
          v-model="tmpDataName"
        >
        </v-text-field>
        <v-text-field
          placeholder="ID"
          v-model="tmpID"
        >
        </v-text-field>
        <v-btn @click="getData"></v-btn>
      </v-row>
      <v-row>
        <v-text-field
          placeholder="REC"
          v-model="tmpRec"
        >
        </v-text-field>
        <v-text-field
          placeholder="LEVEL"
          v-model="tmpLevel"
        >
        </v-text-field>
      </v-row>
      <v-row>
        {{ resData }}
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
const axios = require('axios')
export default {
  data: () => ({
    selectedfile1: null,
    selectedfile2: null,
    tmpFolder: '',
    tmpDataName: '',
    tmpID: '',
    tmpRec: '',
    tmpLevel: '',
    resData: {}
  }),
  mounted () {
  },
  methods: {
    // ファイルのアップロード
    uploadfile: async function () {
      // アップロード用オブジェクトの生成
      var formData = new FormData()
      // ファイルの設定
      formData.append('file0', this.selectedfile1)
      formData.append('file1', this.selectedfile2)
      // Jsonデータの設定
      var tmpDat = {
        requesttype: 'update',
        dataname: this.tmpDataName,
        id: this.tmpID,
        rec: this.tmpRec,
        level: this.tmpLevel,
        filenum: 2
      }
      formData.append('data', JSON.stringify(tmpDat))
      // アップロード
      await axios.post('/api/schooldb2', formData).then((response) => {
        this.selectedfile1 = null
        this.selectedfile2 = null
        this.tmpFolder = null
      })
    },
    // データ取得
    getData: async function () {
      var formData = new FormData()
      // Jsonデータの設定
      var tmpDat = {
        requesttype: 'get',
        dataname: this.tmpDataName,
        id: this.tmpID
      }
      formData.append('data', JSON.stringify(tmpDat))
      await axios.post('/api/schooldb2', formData).then((response) => {
        this.resData = response.data
      })
    }
  }
}
</script>
