<template>
  <v-card>
    <v-toolbar
        dense
      >
      <v-col cols="1">
        <v-btn
          fab
          small
          color="green"
          @click="showFormNew"
        >
        <v-icon>
          mdi-pencil
        </v-icon>
        </v-btn>
      </v-col>
      <v-col>
        <v-text-field
          hide-details
          prepend-icon="mdi-magnify"
          single-line
          v-model="txtFilter"
          class="mt-2"
          label="プロジェクト"
        ></v-text-field>
      </v-col>
    </v-toolbar>
    <ProjectDetail
      :flgDialog="flgDialog"
      :projectdetail="targetproject"
      :flgEdit="flgEdit"
      :usernamelist="usernamelist"
      :userlist="userlist"
      @flgReset="flgDialog = $event"
    ></ProjectDetail>
    <v-data-table
      :headers="headers"
      :items="projectsitems"
      :search="txtFilter"
      dense
    >
    <template
      v-slot:[`item.id`]="{ item }"
    >
      <v-btn
        text
        class="custom-transform-class text-none"
        @click="showForm(item)"
      >
        {{ item.id }}
      </v-btn>
    </template>
    </v-data-table>
  </v-card>
</template>

<script>
import axios from 'axios'
import ProjectDetail from './ProjectDetail.vue'
export default {
  components: {
    ProjectDetail
  },
  data: () => ({
    flgDialog: false,
    flgEdit: false,
    txtFilter: '',
    projectsitems: [],
    targetproject: {
      id: '',
      name: '',
      external: false,
      member: [],
      memo: '',
      dt_update: '',
      status: ''
    },
    usernamelist: [],
    userlist: []
  }),
  computed: {
    headers () {
      return [
        { text: 'ID', value: 'id' },
        { text: '名前', value: 'name' },
        { text: '社内/社外', value: 'external' },
        { text: 'メンバー', value: 'member' },
        { text: '備考', value: 'memo' },
        { text: '更新日', value: 'dt_update' },
        { text: 'status', value: 'status' }
      ]
    }
  },
  mounted () {
    this.getDataProjects()
    this.getDataUsers()
  },
  methods: {
    // データ取得
    getDataProjects: async function () {
      await axios.get('/api/projects?status=all').then(response => {
        this.projectsitems = response.data
      })
    },
    // ユーザーデータの取得
    getDataUsers: async function () {
      await axios.get('/api/users').then(response => {
        // ユーザーマスタの取得
        this.userlist = response.data
      })
    },
    // 入力画面用のユーザーリストの生成→内部プロジェクト用調整
    getDataUsernamesEx: function (flgExternal) {
      // ユーザーリストの初期化
      this.usernamelist = []

      for (var i in this.userlist) {
        if (flgExternal === false) {
          if (this.userlist[i].external === false) {
            this.usernamelist.push(this.userlist[i].username)
          }
        } else {
          this.usernamelist.push(this.userlist[i].username)
        }
      }
    },
    // 入力/編集画面起動(編集)
    showForm: function (item) {
      this.getDataUsernamesEx(item.external)
      this.flgDialog = true
      this.targetproject = item
      this.flgEdit = true
    },
    // 入力/編集画面起動(新規)
    showFormNew: function () {
      this.getDataUsernamesEx(false)
      this.flgDialog = true
      this.flgEdit = false
      this.targetproject = {
        id: '',
        name: '',
        external: false,
        member: [],
        memo: '',
        dt_update: '',
        status: 'open'
      }
    }
  }
}
</script>
