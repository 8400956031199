<template>
  <v-card>
    <v-app>
      <!-- ヘッダー調整 -->
      <v-app-bar
        height="70"
        elevation="0"
        color="white"
      >
        <v-card
          class="mx-auto"
          elevation="0"
          width="1150"
        >
          <v-row justify="start">
            <v-btn :href="urlNagolab" text elevation="0">
              <div style="font-size: xx-large;">なごらぼ</div>
            </v-btn>
            <v-btn :href="urlNagolab" text elevation="0">
              <v-icon color="black">mdi-home</v-icon>
            </v-btn>
          </v-row>
        </v-card>
      </v-app-bar>
      <v-app-bar
        color= #337ab7
        height="90"
        elevation="0"
      >
        <v-toolbar-title
          class="mx-auto"
        >
          <div style="font-weight:bolder; font-size: xx-large; color: white;">資格試験申し込み</div>
        </v-toolbar-title>
      </v-app-bar>

      <!-- カレンダー -->
      <v-card
        class="mx-auto"
        elevation="0"
      >
      <br>
      <div style="font-size: small;">
        <v-icon small color="black">mdi-home</v-icon>
        <a :href="urlNagolab">HOME</a>/資格試験申し込み
      </div>
      <br><br>
      <v-card-title>
        <div class="txtTitle">■試験開催スケジュール</div><br>
      </v-card-title>
      <v-card-text>
        <v-row>
          <div class="text-h6"
            v-for="(dt, index) in examMonths" :key="index"
          >
            {{dt.getMonth()+1}}月
            <table>
              <tr><th>日時</th><th>残数</th></tr>
              <tr v-for="(e, index) of getEventData(index)" :key="index">
                <td>
                  <v-btn
                    class="text-h6"
                    text
                    :disabled="activatBtn(e)"
                    @click="showForm(e)"
                  >{{e.code_schedule + setMark(e.name)}}</v-btn>
                </td>
                <td>{{e.num - e.num_reserved}}</td>
              </tr>
            </table>
          </div>
        </v-row>
        <v-row class="text-h6">
          ※受験したい日程をクリックして申込してください。
        </v-row>
      </v-card-text><br><br>

      <!-- 科目リスト -->
      <v-card-title>
        <div  class="txtTitle">■受験可能な資格試験</div>
      </v-card-title>
      <v-card-text>
        <v-row class="text-h6">
          <table>
            <tr><th>資格</th><th>科目</th></tr>
            <tr v-for="(ex, index) of examlist" :key="index">
              <td
                v-if="ex.span !== 0"
                :rowspan="ex.span"
              >{{ex.certification}}</td>
              <td
                v-if="ex.flgLongTime==='normal'"
              >
                <a target="_blank" :href="ex.url">{{ex.subject}}</a>
              </td>
              <td
                v-else
              >
                <a target="_blank" :href="ex.url">{{ex.subject}}★</a>
              </td>
            </tr>
          </table>
        </v-row>
        <v-row class="text-h6">
          ※概要・受験料・再受験ルールについては各試験のリンクページをご参照ください。<br>
          ※科目名に「★」がついている試験科目については、試験開催スケジュールの「★」がついている日程のみ受験可能です。
        </v-row>
      </v-card-text><br><br>

      <!-- 支払方法   -->
      <v-card-title>
        <div  class="txtTitle">■試験料の支払方法</div>
      </v-card-title>
      <v-card-text class="text-h6">
        ※試験料金は前払いとなっております。<br>
        ・銀行振込<br>
            試験の5営業日前の15時までにお振込みください。<br>
        ・現金払い<br>
            試験の5営業日前の15時までに下記の試験会場にてお支払いください。<br>
      </v-card-text><br><br>

      <!-- 試験会場 -->
      <v-card-title>
        <div  class="txtTitle">■試験会場</div>
      </v-card-title>
      <v-card-text class="text-h6">
        株式会社スプリングナレッジラボ<br>
        沖縄県名護市城１－２２－９
      </v-card-text><br><br>

      <MsgForm
        :flgDialogMsgForm="flgDialogMsgForm"
        :msglist="msglist"
        :btntext="btntext"
        @flgReset="flgDialogMsgForm = $event"
        @doFunc="showFormReserve"
      >
      </MsgForm>
      <ReserveOfHPDetail
        :flgDialogExam="flgDialogExam"
        :selectedevent="selectedevent"
        @flgReset="flgDialogExam = $event"
      >
      </ReserveOfHPDetail>
      </v-card>

      <!-- フッター調整 -->
      <v-footer
        color= FAFAFA
        height="100"
        style="border-top: solid 1px #337ab7;"
      >
        <v-col class="text-center">
          <p style="font-size: small;">Copyright © なごらぼ All Rights Reserved.</p>
        </v-col>
      </v-footer>
    </v-app>
  </v-card>
</template>

<style scoped>
.txtTitle {
  font-size: xx-large;
  font-weight: bold;
  color: #54595F;
  border-bottom: solid 1px #54595F;
}
v-card-text {
  font-size: medium;
}
table {
  border-collapse: collapse;
  border: 0.5px solid
}
th {
  border: 0.5px solid gray;
  background-color:beige;
  font-weight: bold;
  height: 2em;
  color:black;
}
td {
  border: 0.5px solid gray;
  padding-left:1em;
  padding-right:1em;
  height: 2em;
  color:black;
}
</style>

<script>
import axios from 'axios'
import MsgForm from './MsgForm.vue'
import ReserveOfHPDetail from './ReserveOfHPDetail.vue'
export default {
  components: {
    MsgForm,
    ReserveOfHPDetail
  },
  data: () => ({
    examlist: {},
    eventlist: [],
    selectedevent: {},
    headers: [
      '試験日',
      '申込・支払期日',
      '残席',
      '申込'
    ],
    dt_today: null,
    btntext: '',
    msglist: [],
    flgDialogExam: false,
    flgDialogMsgForm: false,
    examMonths: [],
    urlNagolab: 'https://www.nagolab.com/'
  }),
  mounted () {
    this.getData()
    this.dt_today = new Date()
    this.examMonths.push(this.comGetFirstDate(this.dt_today))
    this.examMonths.push(this.comAddMonthDate(this.examMonths[0], 1))
    this.examMonths.push(this.comAddMonthDate(this.examMonths[1], 1))
  },
  methods: {
    // データ取得
    getData: async function () {
      await axios.get('/api/reserveforhp').then(response => {
        // 試験科目リスト
        this.examlist = response.data.exam
        sessionStorage.setItem('examlist', JSON.stringify(response.data.exam))
        this.examlist = this.examlist.sort((a, b) => a.code_schedule - b.code_schedule)

        // テーブルの資格欄マージのための要素追加
        this.examlist[0].span = this.countCertification(this.examlist[0].certification)
        for (let k = 1; k < this.examlist.length; k++) {
          if (this.examlist[k].certification !== this.examlist[k - 1].certification) {
            this.examlist[k].span = this.countCertification(this.examlist[k].certification)
          } else {
            this.examlist[k].span = 0
          }
        }
        // 試験の注意事項(モーダル画面に表示するメッセージ)
        this.msglist = response.data.msg
        // イベントリスト
        this.eventlist = response.data.event
      })
    },
    // イベント情報の1ヵ月単位での取得
    getEventData: function (index) {
      var tmpExam = []
      for (var k = 0; k < this.eventlist.length; k++) {
        if (this.eventlist[k].code_schedule.slice(0, 7) === this.comDateToStr(this.examMonths[index]).slice(0, 7)) {
          if (this.eventlist[k].status === '受付中') {
            tmpExam.push(this.eventlist[k])
          }
        }
      }
      return tmpExam
    },
    countCertification: function (cert) {
      return this.examlist.filter(exam => exam.certification === cert).length
    },
    activatBtn: function (e) {
      // 対象データの支払期日(日付)を取得
      var tmpDate = new Date(e.dt_entry_limit)
      // 支払期日が過ぎているかチェック
      if (tmpDate < this.dt_today) {
        return true
      }

      // 満席かチェック
      if (e.num - e.num_reserved === 0) {
        return true
      }

      // チェックに抵触しない場合
      return false
    },
    setMark: function (eventname) {
      if (eventname === 'exam/全') {
        return '★'
      } else {
        return ''
      }
    },
    // メッセージ画面の起動
    showForm: function (item) {
      this.selectedevent = {}
      this.btntext = {
        yes: '同意する',
        no: '同意しない'
      }
      this.flgDialogMsgForm = true
      this.selectedevent = item
    },
    // 予約画面の起動
    showFormReserve: function () {
      this.flgDialogMsgForm = false
      this.flgDialogExam = true
    }
  }
}
</script>
