<template>
  <v-dialog
    persistent
    v-model="tmpdialog"
    max-width="400px"
  >
    <v-card>
      <v-card-title>
        <span class="text-h5">一括更新</span>
        <span class="text-subtitle-1">/{{tmpselectedtickets.length}}件</span>
      </v-card-title>
      <v-card-text>
        <v-container>
            <v-row>
                <v-text-field
                label="項目名(文頭に追加します。)"
                v-model="ticket.subject"
                dense
                ></v-text-field>
            </v-row>
            <v-row>
                <v-col>
                <v-select
                    :items="masterdata.type"
                    label="種別"
                    max-width="100px"
                    v-model="ticket.type"
                    dense
                ></v-select>
                </v-col>
                <v-col>
                <v-select
                    :items="masterdata.status"
                    label="状況"
                    max-width="100px"
                    v-model="ticket.status"
                    dense
                ></v-select>
                </v-col>
                <v-col>
                <v-select
                    :items="masterdata.priority"
                    label="優先"
                    max-width="100px"
                    v-model="ticket.priority"
                    dense
                ></v-select>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                <v-menu
                    v-model="menu1"
                    :close-on-content-click="false"
                    max-width="290"
                >
                    <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        label="開始"
                        v-model="ticket.dt_start"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        dense
                    ></v-text-field>
                    </template>
                    <v-date-picker
                    v-model="ticket.dt_start"
                    no-title
                    scrollable
                    @input="menu1=false"
                    ></v-date-picker>
                </v-menu>
                </v-col>
                <v-col>
                <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    max-width="290"
                >
                    <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        label="終了"
                        v-model="ticket.dt_end"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        dense
                    ></v-text-field>
                    </template>
                    <v-date-picker
                    v-model="ticket.dt_end"
                    no-title
                    scrollable
                    @input="menu2=false"
                    ></v-date-picker>
                </v-menu>
                </v-col>
            </v-row>
            <v-row>
              <v-col cols='6'>
              <v-text-field
                  label="フェーズ"
                  v-model="ticket.phase"
                  dense
              ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col v-if="selectedproject != '[***全検索***]'">
                <v-select
                  :items="usersname"
                  label="担当"
                  max-width="100px"
                  v-model="ticket.user"
                  dense
                ></v-select>
               </v-col>
            </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="blue darken-1"
          text
          @click="closeForm"
        >
          キャンセル
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="uploadData"
        >
          更新
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
const axios = require('axios')
export default {
  props: [
    'tmpdialog',
    'tmpselectedtickets',
    'usersname',
    'selectedproject'
  ],
  data () {
    return {
      dialog: false,
      menu1: false,
      menu2: false,
      ticket: {
        subject: '',
        type: '',
        status: '',
        priority: '',
        dt_start: null,
        dt_end: null,
        phase: '',
        user: ''
      },
      masterdata: {}
    }
  },
  mounted () {
    this.masterdata = JSON.parse(sessionStorage.getItem('master'))
  },
  methods: {
    // 更新
    uploadData: async function () {
      // 対象チケット毎に変更を反映
      for (let i = 0; i < this.tmpselectedtickets.length; i++) {
        // 各項目単位に確認して反映
        for (const key in this.ticket) {
          if (this.ticket[key] !== '' && this.ticket[key] !== null) {
            if (key === 'subject') {
              // 項目名については、文頭に付加して反映
              this.tmpselectedtickets[i][key] = this.ticket[key] + this.tmpselectedtickets[i][key]
            } else {
              // その他項目については変更
              this.tmpselectedtickets[i][key] = this.ticket[key]
            }
          }
        }
      }
      await axios.post('/api/tickets', this.tmpselectedtickets).then(
        this.closeForm()
      )
    },
    closeForm: function () {
      this.ticket = {
        subject: '',
        type: '',
        status: '',
        priority: '',
        dt_start: null,
        dt_end: null,
        phase: '',
        user: ''
      }
      this.tmpdialog = false
      this.$emit('flgReset', false)
      this.$router.go({ path: '/ticketlist/0', force: true })
    }
  }
}
</script>
