var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","width":"1000"},model:{value:(_vm.flgDialogExamList),callback:function ($$v) {_vm.flgDialogExamList=$$v},expression:"flgDialogExamList"}},[_c('v-card',[_c('v-card-title',[_vm._v(" 試験一覧 ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-file-input',{staticClass:"mt-2",attrs:{"label":"upload file","dense":""},model:{value:(_vm.selectedfile),callback:function ($$v) {_vm.selectedfile=$$v},expression:"selectedfile"}})],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{staticClass:"mr-5 mt-2",attrs:{"text":""},on:{"click":_vm.uploadfile}},[_c('v-icon',[_vm._v(" mdi-upload ")])],1)],1)],1),_c('v-row',[_c('v-data-table',{staticClass:"my-class",attrs:{"headers":_vm.headers,"items":_vm.examdata,"dense":""},scopedSlots:_vm._u([{key:"item.normal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.normal)),_c('br'),_vm._v("("+_vm._s(item.normal_bk)+") ")]}},{key:"item.student",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.student)),_c('br'),_vm._v("("+_vm._s(item.student_bk)+") ")]}},{key:"item.organization",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.organization)),_c('br'),_vm._v("("+_vm._s(item.organization_bk)+") ")]}},{key:"item.exam",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.exam)),_c('br'),_vm._v("("+_vm._s(item.exam_bk)+") ")]}}],null,true)})],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"deep-purple lighten-2","outlined":"","right":""},on:{"click":_vm.closeForm}},[_vm._v(" 閉じる ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }