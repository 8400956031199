<template>
  <v-dialog
    persistent
    v-model="flgDialog"
    width="500"
  >
    <v-card
      class="mx-auto"
    >
      <v-card-title class="ml-2">プロジェクト登録</v-card-title>
      <v-card-text class="mt-3">
        <v-row dense>
          <v-col cols=5>
            <v-text-field
              label="ID"
              v-model="projectdetail.id"
              :disabled = "flgEdit"
            >
            </v-text-field>
          </v-col><v-col cols="7">
            <v-text-field
              label="プロジェクト名"
              v-model="projectdetail.name"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
          <v-checkbox
            v-model="projectdetail.external"
            label="外部"
            :disabled = "flgEdit"
            @click="getDataUsernamesEx(projectdetail.external)"
          ></v-checkbox>
          </v-col><v-col cols="8">
          <v-textarea
            outlined
            v-model="projectdetail.memo"
            label="メモ"
          >
          </v-textarea>
          </v-col>
        </v-row>
        <v-row dense>
          <v-select
            v-model="projectdetail.member"
            :items="usernamelist"
            multiple
            label="メンバー"
          >
          </v-select>
        </v-row>
        <v-row dense>
          <v-col cols=4>
            <v-select
              v-model="projectdetail.status"
              :items="statuslist"
              label="status"
            >
            </v-select>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="deep-purple lighten-2"
          outlined
          right
          @click="closeForm"
        >
          キャンセル
        </v-btn>
        <v-btn
          color="green lighten-2"
          outlined
          right
          @click="uploadData"
        >
          更新
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>>

<script>
const axios = require('axios')
export default {
  props: [
    'flgDialog',
    'flgEdit',
    'projectdetail',
    'usernamelist',
    'userlist'
  ],
  data: () => ({
    statuslist: []
  }),
  mounted () {
    this.statuslist = JSON.parse(sessionStorage.getItem('master')).project.status
  },
  methods: {
    closeForm: function () {
      this.flgDialog = false
      this.$emit('flgReset', false)
      this.$router.go({ path: this.$router.currentRoute.path, force: true })
    },
    // 登録
    uploadData: async function () {
      if (this.projectdetail.id !== '' && this.isHanEisu(this.projectdetail.id) === true) {
        await axios.post('/api/projects', this.projectdetail).then((response) => {
          if (response.data.status === false) {
            alert(response.data.msg)
          } else if (response.data.status === true) {
            this.flgDialog = false
            this.$emit('flgReset', false)
            this.$router.go({ path: this.$router.currentRoute.path, force: true })
          }
        })
      } else {
        alert('IDもしくは半角英数字でIDを設定してください。')
      }
    },
    // 内部プロジェクトの場合にユーザーリストから外部ユーザーの除外
    getDataUsernamesEx: function (flgExternal) {
      this.usernamelist = []
      for (var key in this.userlist) {
        if (flgExternal === false) {
          if (this.userlist[key].external === false) {
            this.usernamelist.push(this.userlist[key].username)
          }
        } else {
          this.usernamelist.push(this.userlist[key].username)
        }
      }
    },
    // 半角英数アルファベットチェック
    isHanEisu: function (str) {
      if (str.match(/^[A-Za-z0-9]*$/)) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>
