<template>
  <v-card
    class="mx-auto my-12"
    max-width="374"
    outlined
  >
    <v-card-title>
      Nudibranch Ticket Manager
    </v-card-title>
    <v-card-text>
      {{msg}}{{storeduser}}
    </v-card-text>
    <v-card-actions>
      <v-btn
        color="primary"
        outlined
        @click="login"
      >
        <v-icon left>
          mdi-login
        </v-icon>
        ログイン
      </v-btn>
      <v-btn
        color="green"
        outlined
        @click="getUser"
      >
        <v-icon left>
          mdi-account
        </v-icon>
        ユーザ
      </v-btn>
      <v-btn
        color="red"
        outlined
        @click="logout"
      >
        <v-icon left>
          mdi-logout
        </v-icon>
        ログアウト
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { getAuth, GoogleAuthProvider, signInWithPopup, signOut } from 'firebase/auth'
const axios = require('axios')
export default {
  data: () => ({
    tokenaccess: null,
    tokenid: null,
    user_cl: null,
    msg: '',
    storeduser: null
  }),
  methods: {
    // ログイン処理
    login: async function () {
      const provider = new GoogleAuthProvider()
      provider.setCustomParameters({
        prompt: 'select_account'
      })

      // googleへのログイン処理
      const auth = getAuth()
      await signInWithPopup(auth, provider
      ).then(async (result) => {
        // Googleへの認証処理
        const credential = GoogleAuthProvider.credentialFromResult(result)
        this.tokenaccess = credential.accessToken
        this.user_cl = result.user
        this.tokenid = await this.user_cl.getIdTokenResult(true)
        // サーバーへの認証処理
        await axios.post('/api/login', { token: this.tokenid }
        ).then(async (result) => {
          if (result.data.status === true) {
            // セッション情報の登録
            // ***ユーザー関連情報***
            sessionStorage.setItem('user', JSON.stringify(result.data.info.user))
            sessionStorage.setItem('userinfo', JSON.stringify(result.data.info))
            // ***マスター情報***
            await axios.get('/api/master').then(response => {
              sessionStorage.setItem('master', JSON.stringify(response.data))
              // Homeページへ遷移→出勤簿画面をトップ画面に変更

              // var ticketid = JSON.parse(sessionStorage.getItem('ticketid'))
              // sessionStorage.removeItem('ticketid')
              // if (ticketid == null) {
              //  ticketid = 0
              // }
              // this.$router.push({ path: '/ticketlist/' + ticketid, force: true })
              // this.$router.go({ path: '/ticketlist/' + ticketid, force: true })
              this.$router.push({ path: '/workreport', force: true })
              this.$router.go({ path: '/workreport', force: true })
            }).catch(error => {
              alert(error)
            })
          } else {
            this.msg = 'ログインに失敗しました。(server)'
          }
        }).catch((error) => {
          this.msg = 'ログインに失敗しました。(server)' + error
        })
      }).catch((error) => {
        this.msg = 'ログインに失敗しました。(Google)' + error
      })
    },
    // ログアウト
    logout: async function () {
      // セッション情報/ログイン情報の削除
      sessionStorage.removeItem('user')

      // サーバーのログアウト処理
      await axios.post('/api/logout')

      // Googleへのログアウト処理
      const auth = getAuth()
      await signOut(auth).then(() => {
        alert('サインアウトしました。')
      }).catch((error) => {
        alert(error)
      })
    }
  }
}
</script>
