<template>
  <v-card>
    <v-toolbar
        dense
      >
      <v-col cols="1">
        <v-btn
          fab
          small
          color="green"
          @click="showFormNew"
        >
        <v-icon>
          mdi-pencil
        </v-icon>
        </v-btn>
      </v-col>
      <v-col>
        <v-text-field
          hide-details
          prepend-icon="mdi-magnify"
          single-line
          v-model="txtFilter"
          class="mt-2"
          label="User ID/ユーザー名"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-select
          hide-details
          v-model="corpFilter"
          :items="corps"
          label="会社"
          clearable
          class="mt-2"
        >
        </v-select>
      </v-col>
    </v-toolbar>
    <UserDetail
      :flgDialog="flgDialog"
      :userdetail="targetuser"
      :corps="corps"
      :flgEdit="flgEdit"
      :permissionlist="permissionlist"
      :statuslist="statuslist"
      @flgReset="flgDialog = $event"
    ></UserDetail>
    <v-data-table
      :headers="headers"
      :items="usersitems"
      :search="txtFilter"
      :options.sync="options"
      :page.sync="page"
      multi-sort
      dense
    >
    <template
      v-slot:[`item.id`]="{ item }"
    >
      <v-btn
        text
        class="custom-transform-class text-none"
        @click="showForm(item)"
      >
        {{ item.id }}
      </v-btn>
    </template>
    </v-data-table>
  </v-card>
</template>

<script>
import axios from 'axios'
import UserDetail from './UserDetail.vue'
export default {
  components: {
    UserDetail
  },
  data: () => ({
    flgDialog: false,
    flgEdit: false,
    txtFilter: '',
    corpFilter: '',
    users: [],
    usersitems: [],
    targetuser: {
      id: '',
      staff_id: '',
      name: '',
      username: '',
      corp: '',
      permission: '一般',
      work: false,
      dt_entry: '',
      dt_update: '',
      status: '在職',
      external: false
    },
    corps: [],
    options: {
      page: 1,
      itemsPerPage: 10,
      sortBy: ['corp', 'staff_id'],
      sortDesc: [false, false]
    },
    permissionlist: [],
    statuslist: []
  }),
  computed: {
    headers () {
      return [
        { text: 'ID', value: 'id' },
        { text: '社員番号', value: 'staff_id' },
        { text: '名前', value: 'name' },
        { text: 'ユーザー名', value: 'username' },
        { text: '会社', value: 'corp', filter: this.setFilterCorps },
        { text: '権限', value: 'permission' },
        { text: '出勤簿', value: 'work' },
        { text: '入社日', value: 'dt_entry' },
        { text: '更新日', value: 'dt_update' },
        { text: 'Status', value: 'status' },
        { text: '外部', value: 'external' }
      ]
    }
  },
  mounted () {
    this.getDataCorps()
    this.getDataUsers().then(() => {
      this.setCondition()
    })
  },
  methods: {
    // データ取得
    getDataUsers: async function () {
      await axios.get('/api/users').then(response => {
        // 表示用配列の生成
        for (var key in response.data) {
          this.usersitems.push(response.data[key])
        }
        // オリジナルのデータ
        this.users = response.data
      })
    },
    // 会社マスタの取得
    getDataCorps: async function () {
      await axios.get('/api/master').then(response => {
        this.corps = response.data.corps
        this.permissionlist = response.data.permissionlist
        this.statuslist = response.data.statuslist
      })
    },
    // 会社キーでのフィルタ
    setFilterCorps: function (value) {
      if (!this.corpFilter) {
        return true
      }
      return value.includes(this.corpFilter)
    },
    // 入力/編集画面起動
    showForm: function (item) {
      this.flgDialog = true
      this.targetuser = item
      this.flgEdit = true
      this.saveCondition()
    },
    // 入力/編集画面起動(新規)
    showFormNew: function () {
      this.flgDialog = true
      this.flgEdit = false
      this.targetuser = {
        id: '',
        staff_id: '',
        name: '',
        username: '',
        corp: '',
        permission: '一般',
        work: false,
        dt_entry: '',
        dt_update: '',
        status: '在職',
        external: false
      }
      this.saveCondition()
    },
    // 状態保存
    saveCondition: function () {
      sessionStorage.setItem('condition_userlist', JSON.stringify(this.options))
    },
    // 状態反映
    setCondition: function () {
      const tmpOptions = JSON.parse(sessionStorage.getItem('condition_userlist'))
      if (tmpOptions !== null) {
        this.options = tmpOptions
      }
    }
  }
}
</script>
