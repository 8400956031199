<template>
  <v-dialog
    persistent
    v-model="flgdialogFile"
    width="500"
  >
    <v-card>
      <v-card-title>ファイルリスト</v-card-title>
      <v-card-text>
        <v-row style="height:40px">
          <v-col cols="5">
            <v-text-field
                label="New Folder"
                v-model="tmpFolder"
                prepend-icon="mdi-folder-plus-outline"
                dense
            >
            </v-text-field>
          </v-col>
          <v-col cols="1" class = "mt-2">/</v-col>
        </v-row>
        <v-row style="height:40px">
          <v-col cols="10">
            <v-file-input
              label="Upload File"
              v-model="selectedfile"
              dense
            ></v-file-input>
          </v-col>
          <v-col cols="2">
            <v-btn
              @click="uploadfile"
              class="mr-5 mt-4"
              text
            >
              <v-icon>
                mdi-upload
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row style="height:40px">
          <v-col cols="8">
            <v-btn
              v-for="(r,index) in routes" :key="index"
              text
              small
              @click="setRoute(r)"
              color = "indigo"
              outlined
            >
              {{r.name}}/
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-data-table
            :headers="headers"
            :items="fileitems"
            :search="txtFilter"
            item-key="name"
            v-model="selectedfiles"
            show-select

            dense
          >
            <template
              v-slot:[`item.name`]="{ item }"
            >
              <a
                v-if="item.type === 'file'"
                :href="item.url"
              >
                {{ item.name }}
              </a>
              <v-btn
                v-else
                text
                @click="setRoute(item)"
                class="custom-transform-class text-none text-truncate"
              >
                <v-icon>
                  mdi-folder-outline
                </v-icon>
                {{ item.name }}
              </v-btn>
            </template>
            <template
              v-slot:[`item.del`]="{ item }"
            >
              <v-btn
                v-if="item.type === 'file'"
                @click="delfile(item)"
                text
              >
                <v-icon>
                  mdi-trash-can
                </v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="deep-purple lighten-2"
          outlined
          @click="delfileall"
        >
          一括削除
        </v-btn>
        <v-btn
          color="deep-purple lighten-2"
          outlined
          right
          @click="closeForm"
        >
          閉じる
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>>

<script>
const axios = require('axios')
export default {
  props: [
    'flgdialogFile',
    'fileitems',
    'path'
  ],
  data: () => ({
    txtFilter: '',
    selectedfile: null,
    selectedfiles: [],
    tmpFolder: null
  }),
  computed: {
    headers () {
      return [
        { text: 'ファイル', value: 'name' },
        { text: '削除', value: 'del' }
      ]
    },
    routes () {
      return this.getPathArray()
    }
  },
  methods: {
    // 指定したフォルダのデータ取得とパスリンクの設定
    setRoute: async function (itm) {
      this.path = itm.path
      const tmpURL = '/api/storage?path=' + this.path
      this.fileitems = []
      await axios.get(tmpURL).then((response) => {
        this.fileitems = response.data
      })
    },
    // 対象のオブジェクトがファイルかフォルダかを判断
    isFile: function (obj) {
      if (obj.path.slice(-1) !== '/') {
        return true
      } else {
        return false
      }
    },
    // 各フォルダのパスリンクの生成
    getPathArray: function () {
      // パスの分割(最後の「/」を削除 空文字を発生させないため)
      const tmpPaths = this.path.slice(0, -1).split('/')
      // プロジェクト固定のパス(3階層目迄)
      const prefxPath = tmpPaths[0] + '/' +
                         tmpPaths[1] + '/' +
                         tmpPaths[2] + '/'

      // 4階層目以降の各フォルダ毎のデータの配列生成(バス/フォルダ名)
      // 4階層目が存在しない場合、処理を抜ける(基本存在しないケース)
      if (tmpPaths.length < 3) {
        return
      }
      // 4階層目から処理
      var tmpRoutes = []
      var tmpR = ''
      for (var i = 3; i < tmpPaths.length; i++) {
        tmpR = tmpR + tmpPaths[i] + '/'
        tmpRoutes.push({ path: prefxPath + tmpR, name: tmpPaths[i] })
      }
      return tmpRoutes
    },
    // 画面のクローズ
    closeForm: function () {
      this.flgdialogFile = false
      this.$emit('flgReset', false)
      this.$router.go({ path: '/ticketlist/0', force: true })
    },
    // ファイルのアップロード
    uploadfile: async function () {
      // アップロード用オブジェクトの生成
      var formData = new FormData()
      // ファイルの設定
      formData.append('file', this.selectedfile)
      // アップロード先のパス設定
      var tmpPath = ''
      if (this.tmpFolder !== '' && this.tmpFolder !== null) {
        // 新しいパスが設定されている場合にはパスを追加
        tmpPath = this.path + this.tmpFolder + '/'
      } else {
        tmpPath = this.path
      }
      formData.append('path', tmpPath)
      // アップロード
      await axios.post('/api/storage', formData).then((response) => {
        this.fileitems = response.data
        this.path = tmpPath
        this.selectedfile = null
        this.tmpFolder = null
      })
    },
    // ファイルの削除(個別)
    delfile: async function (fname) {
      var tmpPath = ''
      if (Array.isArray(fname) === false) {
        if (window.confirm('「' + fname.name + '」削除しますか?') === false) {
          return
        }
        tmpPath = fname.path
      } else {
        for (var i = 0; i < fname.length; i++) {
          // ファイルのみ削除対象とする
          if (this.isFile(fname[i]) === true) {
            tmpPath = tmpPath + fname[i].path + '|'
          }
        }
      }
      alert(tmpPath)
      await axios.delete('/api/storage?file=' + tmpPath).then((response) => {
        if (response.data.length === 1 && response.data[0].name === '') {
          this.fileitems = []
        } else {
          this.fileitems = response.data
        }
      })
    },
    // 一括削除
    delfileall: async function () {
      this.delfile(this.selectedfiles)
      this.selectedfiles = []
    }
  }
}
</script>
