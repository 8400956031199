<template>
  <v-dialog
    persistent
    v-model="tmpdialog"
    max-width="500px"
  >
    <v-card
      height="500"
    >
      <v-card-title>
        テーブル設定
      </v-card-title>
      <v-card-text>
         <v-data-table
          :headers = "headers"
          :items = "itemlist"
          :items-per-page="20"
          hide-default-footer
          dense
        >
          <template
            v-slot:[`item.visible`]="{ item }"
          >
            <v-icon
              v-if="item.visible === true"
              color="green"
              @click="changeVisible(item)"
            >
              mdi-checkbox-blank-circle
            </v-icon>
            <v-icon
              v-else
              color="red"
              @click="changeVisible(item)"
            >
              mdi-minus-thick
            </v-icon>
          </template>
          <template
            v-slot:[`item.ud`]="{ item }"
          >
            <v-icon
              color="blue"
              @click="movePos(item,'up')"
            >
              mdi-arrow-up-bold
            </v-icon>
            <v-icon
              color="red"
              @click="movePos(item,'down')"
            >
              mdi-arrow-down-bold
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions
      >
        <v-row
          class="justify-center"
          dense
          style="height:30px"
        >
        <v-btn
          color="blue darken-1"
          outlined
          right
          @click="closeForm"
        >
          閉じる
        </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// import axios from 'axios'
export default {
  props: [
    'tmpdialog',
    'itemlist'
  ],
  computed: {
    headers () {
      return [
        { text: '順序', value: 'order' },
        { text: '項目', value: 'headeritem' },
        { text: '表示', value: 'visible' },
        { text: 'U/D', value: 'ud' }
      ]
    }
  },
  data: () => ({
  }),
  methods: {
    // 並べ替え処理
    refreshData: function () {
      this.itemlist.sort((a, b) => a.order - b.order)
    },
    // 表示/非表示設定
    changeVisible: function (it) {
      if (it.visible === true) {
        it.visible = false
      } else {
        it.visible = true
      }
    },
    // 位置移動
    movePos: function (it, updown) {
      // 1番の場合は上位に移動できない
      if (it.order === 1 && updown === 'up') {
        return
      }

      // 一番最後の場合には下位に移動できない
      if (it.order === this.itemlist.length && updown === 'down') {
        return
      }

      // 移動処理
      var tmpID = it.order - 1
      if (updown === 'up') {
        this.itemlist[tmpID].order = this.itemlist[tmpID].order - 1
        this.itemlist[tmpID - 1].order = this.itemlist[tmpID - 1].order + 1
      } else {
        this.itemlist[tmpID].order = this.itemlist[tmpID].order + 1
        this.itemlist[tmpID + 1].order = this.itemlist[tmpID + 1].order - 1
      }

      // 並べ替え処理
      this.refreshData()
    },
    // 終了
    closeForm: function () {
      this.tmpdialog = false
      this.$emit('closeform')
      // this.$router.go({ path: this.$router.currentRoute.path, force: true })
    }
  }
}
</script>
