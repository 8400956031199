<template>
  <v-dialog
    persistent
    v-model="flgDialog"
    width="600"
  >
    <v-card
      class="mx-auto"
    >
      <v-card-title class="ml-2">休業データ</v-card-title>
      <v-card-text class="mt-3">
        <v-row>
          <v-col cols="2">
            <v-select
              v-model="selected_leavedata.leavetype"
              :items="leavetypelist"
              label="休業"
            >
            </v-select>
          </v-col>
          <v-col cols="3">
            <v-row>
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                max-width="290"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    label="開始"
                    v-model="selected_leavedata.dt_start"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    dense
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="selected_leavedata.dt_start"
                  no-title
                  scrollable
                  @input="menu1=false"
                ></v-date-picker>
              </v-menu>
            </v-row>
            <v-row>
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                max-width="290"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    label="終了"
                    v-model="selected_leavedata.dt_end"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    dense
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="selected_leavedata.dt_end"
                  no-title
                  scrollable
                  @input="menu2=false"
                ></v-date-picker>
              </v-menu>
            </v-row>
          </v-col>
          <v-col cols="5">
            <v-textarea
              label="メモ"
              v-model="selected_leavedata.memo"
              outlined
              rows="3"
            >
            </v-textarea>
          </v-col>
          <v-col cols="2">
            <v-btn
            color="green lighten-2"
            outlined
            right
            @click="updateData"
          >
            更新
          </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-data-table
            :headers="headers"
            :items="leavedata"
            dense
          >
            <template
              v-slot:[`item.leavetype`]="{ item }"
            >
              <v-btn
                text
                class="custom-transform-class text-none"
                @click="setData(item)"
              >
                {{ item.leavetype }}
              </v-btn>
            </template>
            <template
              v-slot:[`item.del`]="{ item }"
            >
              <v-btn
                @click="delData(item)"
                text
                small
              >
                <v-icon>
                  mdi-trash-can-outline
                </v-icon>
              </v-btn>
            </template>
          </v-data-table>
          <v-card-actions>
            <v-btn
              color="deep-purple lighten-2"
              outlined
              right
              @click="closeForm"
            >
              キャンセル
            </v-btn>
          </v-card-actions>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
const axios = require('axios')
export default {
  props: [
    'flgDialog',
    'leavedata',
    'yyyymm',
    'id',
    'leavetypelist'
  ],
  data: () => ({
    menu1: false,
    menu2: false,
    selected_leavedata: {
      leavetype: '産休',
      dt_start: '',
      dt_end: '',
      memo: '',
      id: ''
    }
  }),
  computed: {
    headers () {
      return [
        { text: '休業種別', value: 'leavetype' },
        { text: '開始', value: 'dt_start' },
        { text: '終了', value: 'dt_end' },
        { text: '備考', value: 'memo' },
        { text: '削除', value: 'del' }
      ]
    }
  },
  mounted () {
  },
  methods: {
    // データ更新
    updateData: async function () {
      // 日付チェック
      if (this.checkDt() === false) {
        alert('日付を入力してください。')
        return
      }

      // ユーザーidの設定
      this.selected_leavedata.id = this.id
      await axios.post('/api/workreport', this.selected_leavedata).then((response) => {
        this.leavedata = response.data
        this.clearLeavedata()
      }).catch(error => {
        alert(error)
      })
    },
    // データ削除
    delData: async function (item) {
      await axios.delete('/api/workreport', { data: item }).then((response) => {
        this.leavedata = response.data
      }).catch(error => {
        alert(error)
      })
    },
    // データの入力ボックスへのセット
    setData: function (item) {
      this.selected_leavedata = item
    },
    // データクリア
    clearLeavedata: function () {
      this.selected_leavedata = {
        leavetype: '産休',
        dt_start: '',
        dt_end: '',
        memo: '',
        id: ''
      }
    },
    // 画面を閉じる
    closeForm: function () {
      this.flgDialog = false
      this.$emit('flgReset', false)
      this.$emit('flgyyyymm', this.yyyymm)
      // this.$router.go({ path: this.$router.currentRoute.path, force: true })
    },
    checkDt: function () {
      var dts = new Date(this.selected_leavedata.dt_start)
      var dte = new Date(this.selected_leavedata.dt_end)
      if (isNaN(dts.getDate()) === true || isNaN(dte.getDate()) === true) {
        return false
      } else {
        return true
      }
    }
  }
}
</script>
