<template>
  <v-dialog
    persistent
    v-model="flgDialogExam"
    width = "800"
  >
  <v-card
    class="mx-auto"
  >
  <v-card-title>
    予約詳細  (ID:{{reservedetail.id}})
  </v-card-title>
  <v-card-text>
    <v-row>
      <v-col
        cols="6"
      >
        <!-- 区分 -->
        <v-row
          class="mt-2 ml-3 mr-2"
          style="height:50px"
        >
          <v-select
            v-model="reservedetail.type_reservation"
            label="区分"
            :items="mdata.event.type"
            @change="resetItems('etype')"
          >
          </v-select>
        </v-row>
        <!-- 種別1 -->
        <v-row
          class="mt-4 ml-3 mr-2"
          style="height:50px"
        >
          <v-select
            v-model="reservedetail.item"
            label="種別1"
            :items="setType1(reservedetail.type_reservation)"
            @change="resetItems('certi1')"
          >
          </v-select>
        </v-row>
        <!-- 種別2 -->
        <v-row
          class="mt-4 ml-3 mr-2"
          style="height:50px"
        >
          <v-select
            v-model="reservedetail.item_reservation"
            label="種別2"
            :items="setType2(reservedetail.type_reservation,reservedetail.item)"
            @change="resetItems('certi2')"
          >
          </v-select>
        </v-row>
        <!-- 日付 -->
        <v-row
          class="mt-4 ml-3 mr-2"
          style="height:50px"
        >
          <v-select
            v-if="reservedetail.type_reservation=='exam'"
            v-model="reservedetail.code_schedule"
            label="日付"
            :items="setScheduleCode('exam')"
            @change="setEventCode()"
          >
          </v-select>
          <v-select
            v-if="reservedetail.type_reservation=='workshop'"
            v-model="reservedetail.code_schedule"
            label="日付"
            :items="setScheduleCode('workshop')"
            @change="setEventCode()"
          >
          </v-select>
        </v-row>
        <!-- イベントコード -->
        <v-row
          class="mt-4 ml-3 mr-2"
          style="height:30px"
        >
          <EventDetail
            :flgDialogEvent = "flgDialogEvent"
            :eventdetail = "selectedEvent"
            :flgEdit= "flgEditForEvent"
            :fromWhere= "fromWhere"
            @flgReset="flgDialogEvent = $event"
          >
          </EventDetail>
          <v-btn
            text
            x-small
            color="blue"
            @click="showFormEventDetail"
          >
            {{reservedetail.id_event}}
          </v-btn>
        </v-row>
        <!-- 支払 -->
        <v-row
          v-if="reservedetail.type_reservation=='exam'"
          class="mt-4 ml-3 mr-2"
          style="height:50px"
        >
          <v-radio-group
            v-model="reservedetail.type_payment.type_payment"
            mandatory
            row
            @change="resetItems('ticket')"
          >
            <v-radio
              v-for="py in mdata.exam.type_payment" :key="py"
              :label="py.label"
              :value="py.value"
            ></v-radio>
          </v-radio-group>
        </v-row>
        <v-row
          v-if="reservedetail.type_payment.type_payment=='受験チケット'"
          class="ml-3 mt-4 mr-2"
          style="height:40px"
        >
          <v-text-field
            label="チケット番号"
            v-model="reservedetail.type_payment.ticket_no"
          >
          </v-text-field>
        </v-row>
        <!-- 割引 -->
        <div
          v-if="reservedetail.type_payment.type_payment!=='受験チケット'"
        >
          <v-row
            v-if="reservedetail.type_reservation=='exam'"
            class="ml-3 mt-4 mr-2"
            style="height:40px"
          >
            <v-radio-group
              v-model="reservedetail.type_discount.type_discount"
              mandatory
              row
              @change="resetItems('discount')"
            >
              <v-radio
                v-for="dis in mdata.exam.type_discount" :key="dis"
                :label="dis.label"
                :value="dis.value"
              ></v-radio>
            </v-radio-group>
          </v-row>
        <!-- 割引/学割 -->
        <div v-if="reservedetail.type_discount.type_discount=='学割'">
          <v-row
            class="ml-3 mt-4 mr-2"
            style="height:40px"
          >
            <v-text-field
              v-model="reservedetail.type_discount.organization_name"
              label="学校名"
            >
            </v-text-field>
          </v-row>
          <v-row
            class="ml-3 mt-4 mr-2"
            style="height:40px"
          >
            <v-radio-group
              v-model="reservedetail.type_discount.student_teacher"
              mandatory
              row
              class="ml-3 mt-4 mr-2"
              style="height:40px"
            >
              <v-radio
                v-for="t in mdata.exam.student_teacher"
                :key="t"
                :label="t.label"
                :value="t.value"
              ></v-radio>
            </v-radio-group>
          </v-row>
        </div>
        <!-- 割引/会員 -->
        <div>
          <v-row
            v-if="reservedetail.item_reservation=='リユース営業士' && reservedetail.type_discount.type_discount=='会員割引'"
            class="mt-4 ml-3 mr-2"
            style="height:50px"
          >
            <v-text-field
              label="検定コード"
              v-model="reservedetail.type_discount.test_code"
            >
            </v-text-field>
          </v-row>
          <v-row
            v-if="reservedetail.type_discount.type_discount=='会員割引'"
            class="ml-3 mt-4 mr-2"
            style="height:40px"
          >
            <v-text-field
              label="会社名"
              v-model="reservedetail.type_discount.organization_name"
            >
            </v-text-field>
          </v-row>
        </div>
        </div>
        <!-- 割引/受験 -->
        <!-- 試験料金 -->
        <v-row
          class="mt-8 ml-4"
        >
          <label>
            試験料金：{{reservedetail.price.toLocaleString()}}円
          </label>
        </v-row>
        <!-- 更新情報 -->
        <v-row>
          <v-data-table
            class="mt-7"
            hide-default-header
            hide-default-footer
            :headers="headers"
            :items="reservedetail.status_hist"
            dense
          >
          </v-data-table>
        </v-row>
      </v-col>
      <!-- 名前 -->
      <v-col
        cols="6"
      >
        <v-row
          class="mt-2 ml-3 mr-2"
          style="height:50px"
        >
          <v-text-field
            label="名前"
            v-model="reservedetail.name"
          >
          </v-text-field>
        </v-row>
        <v-row
          class="mt-4 ml-3 mr-2"
          style="height:50px"
        >
          <v-text-field
            label="名前(カナ)"
            v-model="reservedetail.name_kana"
          >
          </v-text-field>
        </v-row>
        <v-row
          v-if="reservedetail.item=='子供向けセミナー'"
          class="mt-4 ml-3 mr-2"
          style="height:50px"
        >
          <v-text-field
            label="保護者名"
            v-model="reservedetail.parent_name"
          >
          </v-text-field>
        </v-row>
        <v-row
          class="mt-4 ml-3 mr-2"
          style="height:50px"
          v-if="reservedetail.type_reservation=='exam'"
        >
          <v-menu
            v-model="menu1"
            :close-on-content-click="false"
            max-width="290"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                label="生年月日"
                v-model="reservedetail.dt_birth"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="reservedetail.dt_birth"
              no-title
              scrollable
              @input="menu1=false"
            ></v-date-picker>
          </v-menu>
        </v-row>
        <v-row
          class="mt-4 ml-3 mr-2"
          style="height:50px"
          v-if="reservedetail.item=='子供向けセミナー'"
        >
          <v-text-field
            label="年齢"
            v-model="reservedetail.age"
          >
          </v-text-field>
        </v-row>
        <v-row
          class="mt-4 ml-3 mr-2"
          style="height:50px"
          v-if="reservedetail.item=='大人向けセミナー'"
        >
          <v-text-field
            label="会員番号"
            v-model="reservedetail.num"
          >
          </v-text-field>
        </v-row>
        <v-row
          class="mt-4 ml-3 mr-2"
          style="height:50px"
          v-if="reservedetail.type_reservation=='exam'"
        >
          <v-text-field
            label="郵便番号"
            v-model="reservedetail.post_no"
          >
          </v-text-field>
        </v-row>
        <v-row
          class="mt-4 ml-3 mr-2"
          style="height:50px"
          v-if="reservedetail.type_reservation=='exam'"
        >
          <v-text-field
            label="住所"
            v-model="reservedetail.address"
          >
          </v-text-field>
        </v-row>
        <v-row
          class="mt-4 ml-3 mr-2"
          style="height:50px"
        >
          <v-text-field
            label="電話番号"
            v-model="reservedetail.tel"
          >
          </v-text-field>
        </v-row>
        <v-row
          class="mt-4 ml-3 mr-2"
          style="height:50px"
        >
          <v-text-field
            label="メールアドレス"
            v-model="reservedetail.email"
          >
          </v-text-field>
        </v-row>
        <v-row
          class="mt-4 ml-3 mr-2"
          style="height:60px"
        >
          <v-textarea
            label="その他"
            rows="3"
            v-model="reservedetail.memo"
            readonly
          >
          </v-textarea>
        </v-row>
        <v-row
          class="mt-4 ml-3 mr-2"
          style="height:60px"
        >
          <v-textarea
            label="備考"
            rows="3"
            v-model="reservedetail.memo_admin"
          >
          </v-textarea>
        </v-row>
        <v-row
          style="height:50px"
        >
          <v-col cols="6">
            <v-select
              class="ml-3"
              v-model="reservedetail.status"
              label="Status"
              :items="mdata.reservation.status"
            >
            </v-select>
          </v-col>
          <v-col cols="6">
            <v-select
              class="mr-2"
              v-if="reservedetail.status=='申込不可'"
              label="申込不可の事由"
              v-model="reservedetail.reason_notaccept"
              :items="Object.keys(mdata.reservation.reason_notaccept)"
            >
            </v-select>
          </v-col>
        </v-row>
        <v-row
          v-if="reservedetail.status=='申込不可' && reservedetail.reason_notaccept=='その他'"
          class="mt-6 ml-3 mr-2"
          style="height:50px"
        >
          <v-text-field
            label="その他の事由"
            v-model="reservedetail.reason_memo_notaccept"
          >
          </v-text-field>
        </v-row>
      </v-col>
    </v-row>
  </v-card-text>
  <!-- 処理ボタン -->
  <v-card-actions>
    <MailForm
      :flgDialogMail="flgDialogMail"
      :mailaddress="reservedetail.email"
      :toName="reservedetail.name"
      @flgReset="flgDialogMail = $event"
    ></MailForm>
    <v-btn
      text
      color ="green"
      class ="ml-4"
      @click="showFormMail"
    >
      メール送信
    </v-btn>
    <v-btn
      text
      color ="green"
      class ="ml-4"
      @click="updateReservation(flgP = true)"
    >
      駐車場
    </v-btn>
    <v-spacer></v-spacer>
    <v-btn
      text
      color ="red"
      class ="ml-4"
      @click="closeForm"
    >
      キャンセル
    </v-btn>
    <v-btn
      text
      color ="green"
      @click="updateReservation(flgP = false)"
      :disabled ="flgUpdateBtn"
    >
      更新
    </v-btn>
  </v-card-actions>
  </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'
import EventDetail from './EventDetail.vue'
import MailForm from './MailForm.vue'
export default {
  components: {
    EventDetail,
    MailForm
  },
  props: [
    'flgDialogExam',
    'flgEdit',
    'reservedetail',
    'eventlist'
  ],
  data: () => ({
    flgUpdateBtn: false,
    flgDialogExam: false,
    flgDialogMail: false,
    menu1: false,
    menu2: false,
    menu3: false,
    mdata: null,
    edata: null,
    eventcode: '',
    selectedEvent: {},
    flgDialogEvent: false,
    flgEditForEvent: true,
    fromWhere: 'reserveDetail'
  }),
  computed: {
    headers () {
      return [
        { text: 'Status', value: 'status' },
        { text: 'Date', value: 'dt_update' },
        { text: 'ID', value: 'id_update' }
      ]
    }
  },
  mounted () {
    // マスターデータの設定
    this.mdata = JSON.parse(sessionStorage.getItem('master'))
    // 試験データの設定
    this.getDataExam()
  },
  methods: {
    // 試験データの取得
    getDataExam: async function () {
      await axios.get('/api/masterexam').then(response => {
        this.edata = response.data
      })
    },
    // イベント関連の変更時にイベントデータのクリア処理
    resetItems: function (itype) {
      if (itype === 'etype') {
        // 資格等データのクリア
        this.reservedetail.item = ''
        // 科目データのクリア
        this.reservedetail.item_reservation = ''
        // スケジュールデータのクリア
        this.reservedetail.code_schedule = ''
        // イベントIDのクリア
        this.reservedetail.id_event = ''
        // 支払方法のクリア(支払方法、チケット番号)
        this.reservedetail.type_payment.type_payment = '現金払い'
        this.reservedetail.type_payment.ticket_no = ''
        // 割引のクリア（割引名、学校/会社名、学生/教員）
        this.reservedetail.type_discount.type_discount = 'なし'
        this.reservedetail.type_discount.organization_name = ''
        this.reservedetail.type_discount.student_teacher = '学生'
      } else if (itype === 'certi1') {
        // 科目データのクリア
        this.reservedetail.item_reservation = ''
        // スケジュールデータのクリア
        this.reservedetail.code_schedule = ''
        // イベントIDのクリア
        this.reservedetail.id_event = ''
        // 支払方法のクリア(支払方法、チケット番号)
        this.reservedetail.type_payment.type_payment = '現金払い'
        this.reservedetail.type_payment.ticket_no = ''
        // 割引のクリア（割引名、学校/会社名、学生/教員）
        this.reservedetail.type_discount.type_discount = 'なし'
        this.reservedetail.type_discount.organization_name = ''
        this.reservedetail.type_discount.student_teacher = '学生'
      } else if (itype === 'certi2') {
        // スケジュールデータのクリア
        this.reservedetail.code_schedule = ''
        // イベントIDのクリア
        this.reservedetail.id_event = ''
        // 支払方法のクリア(支払方法、チケット番号)
        this.reservedetail.type_payment.type_payment = '現金払い'
        this.reservedetail.type_payment.ticket_no = ''
        // 割引のクリア（割引名、学校/会社名、学生/教員）
        this.reservedetail.type_discount.type_discount = 'なし'
        this.reservedetail.type_discount.organization_name = ''
        this.reservedetail.type_discount.student_teacher = '学生'
      } else if (itype === 'dt') {
        // 支払方法のクリア(支払方法、チケット番号)
        this.reservedetail.type_payment.type_payment = '現金払い'
        this.reservedetail.type_payment.ticket_no = ''
        // 割引のクリア（割引名、学校/会社名、学生/教員）
        this.reservedetail.type_discount.type_discount = 'なし'
        this.reservedetail.type_discount.organization_name = ''
        this.reservedetail.type_discount.student_teacher = '学生'
      } else if (itype === 'ticket') {
        // 支払方法のクリア(チケット番号)
        this.reservedetail.type_payment.ticket_no = ''
        // 割引のクリア（割引名、学校/会社名、学生/教員）
        this.reservedetail.type_discount.type_discount = 'なし'
        this.reservedetail.type_discount.organization_name = ''
        this.reservedetail.type_discount.student_teacher = '学生'
      } else if (itype === 'discount') {
        // 割引のクリア（学校/会社名、学生/教員）
        this.reservedetail.type_discount.organization_name = ''
        this.reservedetail.type_discount.student_teacher = '学生'
      }
    },
    // イベントコードの表示
    setEventCode: function () {
      for (var i = 0; i < this.eventlist.length; i++) {
        if (this.reservedetail.type_reservation === this.eventlist[i].type_event &&
              this.reservedetail.code_schedule === this.eventlist[i].code_schedule) {
          // 選択したイベントデータの設定
          this.selectedEvent = this.eventlist[i]
          // 選択したイベントデータのidの設定
          this.reservedetail.id_event = this.selectedEvent.id
          this.resetItems('dt')
          return
        }
      }
      // 該当がない場合には空のデータを返す
      this.selectedEvent = {}
    },
    // 試験/ワークショップ種別1
    setType1: function (et) {
      if (et === 'exam') {
        var tmpList = []
        for (var i in this.edata) {
          if (tmpList.includes(this.edata[i].certification) === false) {
            tmpList.push(this.edata[i].certification)
          }
        }
        return tmpList
      } else if (et === 'workshop') {
        return Object.keys(this.mdata.workshop.type)
      }
    },
    // 試験/ワークショップ種別2
    setType2: function (et, t1) {
      if (et === 'exam') {
        var tmpList = []
        for (var i in this.edata) {
          if (t1 === this.edata[i].certification) {
            tmpList.push(this.edata[i].subject)
          }
        }
        return tmpList
      } else if (et === 'workshop') {
        return this.mdata.workshop.type[t1]
      }
    },
    // スケジュールコードの設定
    setScheduleCode: function (typeE) {
      var tmpEventList = this.eventlist.filter(x => {
        if (x.type_event === typeE && x.status === '受付中') {
          return true
        }
      })
      return tmpEventList.map(x => x.code_schedule)
    },
    // キャンセル
    closeForm: function () {
      this.flgDialogExam = false
      this.$emit('flgReset', false)
      this.$router.go({ path: this.$router.currentRoute.path, force: true })
    },
    // データ更新
    updateReservation: async function (flgP = false) {
      this.flgUpdateBtn = true

      // flgP駐車場の更新によりアラートMsg制御
      var tmpMsg = ''
      if (flgP === false) {
        tmpMsg = 'ステータスを「' + this.reservedetail.status + '」に更新します'
      } else {
        tmpMsg = '駐車場利用のメールを送信しますか？'
      }

      // 更新内容の再確認
      var res = confirm(tmpMsg)
      if (res === false) {
        this.flgUpdateBtn = false
        return
      }

      // 支払の場合に入金日を入力
      if (this.reservedetail.status === '支払' && flgP === false) {
        var paymentdt = prompt('入金日を入力してください。', this.comDateToStr(new Date()))
        if (this.comisDate(paymentdt) === true) {
          this.reservedetail.paymentdt = paymentdt
        } else {
          alert('日付の形式が間違いです。再実行してください。')
          // 更新ボタンの活性化
          this.flgUpdateBtn = false
          return
        }
      }

      // 入力チェック
      if (this.checkItem() === false) {
        this.flgUpdateBtn = false
        return
      }

      // ステータス関連
      var tmpDt = new Date()
      this.reservedetail.dt_update = this.comDateToStr(tmpDt)
      this.reservedetail.id_update = JSON.parse(sessionStorage.getItem('user')).id

      // 新規の場合データ補完
      if (this.flgEdit === false) {
        // 新規の場合
        // 試験料金の設定
        var type2 = this.reservedetail.item_reservation
        var pay1 = this.reservedetail.type_payment.type_payment
        var dis1 = this.reservedetail.type_discount.type_discount
        this.reservedetail.price = this.getFeeOfExam(type2, pay1, dis1)
      }

      // 駐車場メール送信の場合
      if (flgP === true) {
        this.reservedetail.flg_parking_mail = true
      }

      // 予約データの登録
      await axios.post('/api/reservation', this.reservedetail).then((response) => {
        // 登録結果のチェック
        if (response.data.status === false) {
          // 登録エラーの場合
          alert('お申し込みできませんでした。' + response.data.msg)
        } else {
          // 登録成功の場合
          this.closeForm()
        }
      })
      // 駐車メールフラグのリセット
      this.reservedetail.flg_parking_mail = false
      // 更新ボタンの活性化
      this.flgUpdateBtn = false
    },
    // 試験料金の取得
    getFeeOfExam: function (t2, p1, d1) {
      // t2:試験名,p1:支払タイプ,d1:割引タイプ
      // 受験チケットの場合
      if (p1 === '受験チケット') {
        return 0
      }

      // 対象試験のデータ取得
      const examdata = this.getItemOfExam(t2)

      // 受験チケット以外の場合
      return examdata[this.mdata.exam.feecode[d1]]
    },
    // 入力チェック
    checkItem: function () {
      // 試験の場合
      if (this.reservedetail.type_reservation === 'exam') {
        // 入力チェック
        var requiredItem = [
          { name: 'item', label: '種別1' },
          { name: 'item_reservation', label: '種別2' },
          { name: 'code_schedule', label: '日付' },
          { name: 'name', label: '名前' },
          { name: 'name_kana', label: '名前(カナ)' },
          { name: 'post_no', label: '郵便番号' },
          { name: 'address', label: '住所' },
          { name: 'tel', label: '電話番号' },
          { name: 'email', label: 'メールアドレス' }
        ]
        for (const itm of requiredItem) {
          if (this.reservedetail[itm.name] === '') {
            alert(itm.label + 'を入力してください。')
            return false
          }
        }
        // 対象試験の取得
        const examdata = this.getItemOfExam(this.reservedetail.item_reservation)
        // 名前(カナ)
        if (this.reservedetail.name_kana.match(/^[ァ-ヴー]+$/) === null) {
          alert('名前(カナ)は、全角カタカナでご入力ください。')
          return false
        }
        // 郵便番号
        if (this.reservedetail.post_no.match(/^[0-9]{3}-[0-9]{4}$/) === null) {
          alert('郵便番号の指定に誤りがあります。xxx-xxxx形式で入力してください。')
          return false
        }
        // 電話
        if (this.reservedetail.tel.match(/^0\d{1,3}-\d{2,4}-\d{3,4}$/) === null) {
          alert('電話番号の指定に誤りがあります。xxxx-xx-xxxx形式で入力してください。')
          return false
        }
        // メールアドレス
        if (this.reservedetail.email.match(/.+@.+/) === null) {
          alert('メールアドレスが適切ではありません。')
          return false
        }
        // 支払・受験チケット／チケット番号
        if (this.reservedetail.type_payment.type_payment === '受験チケット') {
          if (examdata.ticket === 'NG') {
            alert('受験チケットでのお申込みは受付てません。')
            return false
          }
          if (this.reservedetail.type_payment.ticket_no === '') {
            alert('受験チケットの番号を入力してください。')
            return false
          }
        }
        // 割引関連
        if (this.reservedetail.type_discount.type_discount === '学割') {
          if (this.reservedetail.type_discount.organization_name === '') {
            alert('学校名を入力してください。')
            return false
          }
        } else if (this.reservedetail.type_discount.type_discount === '会員割引') {
          if (this.reservedetail.type_discount.organization_name === '') {
            alert('会社名を入力してください。')
            return false
          }
          if (this.reservedetail.item_reservation === 'リユース営業士') {
            alert('検定コードを入力してください。')
            return false
          }
        }
        // 割引選択が適切でない場合のチェック
        if (examdata[this.mdata.exam.feecode[this.reservedetail.type_discount.type_discount]] === 'no data') {
          alert('料金の設定が適切ではありません。')
          return false
        }
        // 申込結果（申込不可の場合）
        if (this.reservedetail.status === '申込不可') {
          if (this.reservedetail.reason_notaccept === '') {
            alert('申込結果を選択してください。')
            return false
          }
        }
        // 申込結果の理由（申込結果がその他の場合）
        if (this.reservedetail.reason_notaccept === 'その他') {
          if (this.reservedetail.reason_memo_notaccept === '') {
            alert('申込不可の理由を入力してください。')
            return false
          }
        }
        // ステータスが適切でない場合のチェック
        if (this.checkStatus() === false) {
          alert('選択しているステータスに変更することはできません。')
          return false
        }
        // チェックに抵触しない場合
        return true
      }
    },
    // 対象試験のデータ取得
    getItemOfExam: function (t2) {
      for (var i in this.edata) {
        if (this.edata[i].subject === t2) {
          return this.edata[i]
        }
      }
      // 対象が存在しない場合はnull(とりあえず)
      return null
    },
    // ステータスの更新チェック
    checkStatus: function () {
      // 各ステータスの遷移先の定義
      const pathStatus = {
        新規: ['申込'],
        申込: ['確定', 'キャンセル', '申込不可'],
        確定: ['支払', 'キャンセル', '中止'],
        支払: ['支払', '登録', 'キャンセル', '中止'],
        登録: ['登録', '実施', 'キャンセル', '中止'],
        実施: [],
        キャンセル: [],
        申込不可: [],
        中止: []
      }
      // 現在のステータス
      var tmpStatusPre = ''
      if (this.reservedetail.id === '') {
        tmpStatusPre = '新規'
      } else {
        tmpStatusPre = this.reservedetail.status_hist[this.reservedetail.status_hist.length - 1].status
      }
      // 現在のステータスからの可能先に存在しているか否かをチェック
      if (pathStatus[tmpStatusPre].includes(this.reservedetail.status)) {
        return true
      } else {
        return false
      }
    },
    // イベントデータの表示
    showFormEventDetail: function () {
      if (this.selectedEvent !== {}) {
        this.flgDialogEvent = true
        this.flgEditForEvent = true
      }
    },
    showFormMail: function () {
      // 登録済み顧客のみ対応
      if (this.reservedetail.id !== '') {
        this.flgDialogMail = true
      }
    }
  }
}
</script>
