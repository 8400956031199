<template>
  <div>
    <v-sheet
        tile
        height="54"
        class="d-flex"
      >
        <v-btn
          class="ma-2"
          fab
          color="orange"
          small
          @click="setToday"
        >
          今日
        </v-btn>
        <v-btn
          icon
          class="ma-2"
          @click="$refs.calendar.prev()"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-menu
          v-model="menu1"
          :close-on-content-click="false"
          max-width="290"
        >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
              label="日付"
              v-model="selecteddt"
              prepend-inner-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="selecteddt"
            no-title
            scrollable
            @input="menu1=false"
          ></v-date-picker>
        </v-menu>
        <v-btn
          icon
          class="ma-2"
          @click="$refs.calendar.next()"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
        <v-select
          v-model="typeevent"
          :items="typeeventlist"
          dense
          outlined
          hide-details
          class="ma-2"
          label="種別"
          @change="setEventsForCalendar"
        ></v-select>
        <v-select
          v-model="selectedinterval"
          :items="typeinterval"
          dense
          outlined
          hide-details
          class="ma-2"
        ></v-select>
    </v-sheet>
    <EventDetail
      :flgDialogEvent = "flgDialogEvent"
      :eventdetail = "selectedEvent"
      :flgEdit="flgEdit"
      @flgReset="flgDialogEvent = $event"
    >
    </EventDetail>
    <v-sheet height="600">
      <v-calendar
        ref="calendar"
        v-model="selecteddt"
        :weekdays="weekday"
        :type="type"
        :events="eventlistforcalender"
        :event-text-color="white"
        @click:event="showEvent"
        @change="setEventsForCalendar"
      ></v-calendar>
        <template v-slot:[`event`]="{ event }">
          {{ event.moreInformation }}
        </template>
    </v-sheet>
  </div>
</template>

<script>
import axios from 'axios'
import EventDetail from './EventDetail.vue'
export default {
  components: {
    EventDetail
  },
  data: () => ({
    flgDialogEvent: false,
    selectedEvent: {},
    flgEdit: false,
    eventlist: [],
    eventlistforcalender: [],
    txtFilterEvent: '',
    typeeventlist: [],
    typeevent: 'All',
    selecteddt: '',
    selectedinterval: 'month',
    typeinterval: ['month', 'week', 'day', '4day'],
    weekday: [0, 1, 2, 3, 4, 5, 6],
    mode: 'stack',
    menu1: false
  }),
  computed: {
  },
  mounted () {
    this.$refs.calendar.scrollToTime('8:00')
    this.setToday()
    this.getDataEvents()
    // マスターデータの設定
    this.mdata = JSON.parse(sessionStorage.getItem('master'))
    this.typeeventlist = this.mdata.event.type
    this.typeeventlist.push('All')
  },
  methods: {
    // イベントデータの取得
    getDataEvents: async function () {
      await axios.get('/api/events?active=False').then(response => {
        this.eventlist = response.data
        this.setEventsForCalendar()
      })
    },
    // カレンダー用のイベントデータの設定
    setEventsForCalendar: function () {
      this.eventlistforcalender = []
      if (this.typeevent === 'All') {
        // すべてのイベントを選択している場合
        for (var edata1 of this.eventlist) {
          this.eventlistforcalender.push(this.mdfEventdata(edata1))
        }
      } else {
        // イベント種別指定の場合
        for (var edata2 of this.eventlist) {
          if (edata2.type_event === this.typeevent) {
            this.eventlistforcalender.push(this.mdfEventdata(edata2))
          }
        }
      }
    },
    // カレンダーのイベントデータへのアジャスト
    mdfEventdata: function (edata) {
      var tmpData = {
        name: edata.name + ' (' + edata.num_reserved + '/' + edata.num + ')',
        start: Date.parse(edata.dt + ' ' + edata.time_start),
        end: Date.parse(edata.dt + ' ' + edata.time_start),
        timed: true,
        color: 'blue',
        num: edata.num - edata.num_reserved,
        moreInformation: edata.id
      }
      return tmpData
    },
    // 今日の日付の設定
    setToday: function () {
      var dt = new Date()
      this.selecteddt = this.comDateToStr(dt)
    },
    // イベントデータの詳細(編集画面)の表示
    showEvent: function (event) {
      this.selectedEvent = this.eventlist.filter(x => x.id === event.event.moreInformation)[0]
      this.flgDialogEvent = true
      this.flgEdit = true
    }
  }
}
</script>
