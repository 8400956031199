<template>
    <v-card elevation="0">
      <v-toolbar
        dense
        elevation="0"
      >
        <v-row>
          <v-col
            cols="2"
          >
              <TicketDetail
                :tmpdialog = "flgdialog"
                :tmpticket = "tmpticket"
                :tmpmaster = "master"
                :countfiles = "countfiles"
                :ticketall = "tickets"
                :projects = "projects"
                :projectsname = "projectsname"
                :usersname = "getDataProjectsFromLocal(profiles.project).member"
                :users = "users"
                @flgReset="flgdialog = $event"
              >
              </TicketDetail>
              <TicketEditAll
                :tmpdialog = "flgdialogEdit"
                :tmpselectedtickets = "selectedrow"
                :usersname = "getDataProjectsFromLocal(profiles.project).member"
                :selectedproject = "profiles.project"
                @flgReset="flgdialogEdit = $event"
              >
              </TicketEditAll>
              <TicketCondition
                :tmpdialog = "flgdialogCondition"
                :listStatus="master.status"
                :listUsername="usersname"
                :tmpfilter="profiles"
                @updateCurrentProfile = "closeFromCondition"
              >
              </TicketCondition>
              <SettingTable
                :tmpdialog = "flgdialogSettingTable"
                :itemlist= "profiles.headerlist"
                @closeform = "closeFromSettingTable"
              >
              </SettingTable>
              <v-btn
                color="deep-orange"
                dark
                fab
                x-small
                class="mt-2"
                @click="showFromNew()"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            <v-chip
              v-if="getDataProjectsFromLocal(profiles.project).external === false"
              color="green"
              small
              class ="ml-2 mt-2"
            >
              内部
            </v-chip>
            <v-chip
              v-if="getDataProjectsFromLocal(profiles.project).external === true"
              color="orange"
              small
              class ="ml-2 mt-2"
            >
              外部
            </v-chip>
          </v-col>
          <!-- プロジェクト選択 -->
          <v-col
            cols="3"
          >
            <v-select
              v-model="profiles.project"
              hide-details
              :items="projectsname"
              label="Project"
              class="mt-3"
              @change="changeProject"
            >
            </v-select>
          </v-col>
          <!-- フォルダアイコン -->
          <v-col
            cols="1"
          >
            <v-btn
              class="mt-2"
              text
              @click="openFolder"
            >
              <FileList
                :flgdialogFile="flgdialogFile"
                :fileitems="fileitems"
                :path="projectpath"
                @flgReset="flgdialogFile = $event"
              ></FileList>
              <v-icon>
                mdi-folder
              </v-icon>
              <v-icon v-if="countfilesOfProject !==0">
                mdi-attachment
              </v-icon>
            </v-btn>
          </v-col>
          <!-- ID検索 -->
          <v-col cols="1">
            <v-text-field
              placeholder="ID検索"
              v-model="selectedticketid"
              class="mt-2"
              :append-icon="'mdi-magnify'"
              @click:append="showFormFromID()"
              dense
            >
            </v-text-field>
          </v-col>
          <!-- 詳細検索 -->
          <v-col
            cols="2"
          >
            <v-btn
              outlined
              color="deep-orange"
              @click="showFormCondition"
              class ="ml-2 mt-2"
            >
              詳細検索
            </v-btn>
          </v-col>
          <v-col
            cols="2"
          >
            <v-btn
              icon
              @click="get_data"
            >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
            <RegFilter
              :flgDialog = "flgdialogfilter"
              :listFilter = "listFiltername"
              :tmpFilter="profiles"
              @flgResetFilter="flgdialogfilter = $event"
            >
            </RegFilter>
            <v-menu>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  class="mt-2"
                  text
                >
                  <v-icon>mdi-book-marker-outline</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item>
                  <v-list-item-title>
                    <v-btn
                      @click="showFormFilter"
                      color="primary"
                      small
                      outlined
                      width="150"
                    >
                      <v-icon left>
                        mdi-filter
                      </v-icon>
                      フィルタ登録
                    </v-btn>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>
                    <v-btn
                      @click="resetFilter"
                      color="primary"
                      small
                      outlined
                      width="150"
                    >
                      <v-icon left>
                        mdi-trash-can
                      </v-icon>
                      リセット
                    </v-btn>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item v-for="(fname,index) in listFiltername" :key="index">
                  <v-list-item-title>
                    <v-btn
                      @click="changeFilter(index)"
                      small
                      text
                      width="150"
                    >
                      {{fname.filtername}}
                    </v-btn>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            {{profiles.filtername}}
          </v-col>
          <!-- メニュー -->
          <v-col
            cols='1'
          >
            <v-menu>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  class="mt-2"
                  text
                >
                  <v-icon>mdi-menu</v-icon>
                </v-btn>
              </template>
              <v-list>
                <!-- csv -->
                <v-list-item>
                  <v-list-item-title>
                    <v-btn
                      @click="downloadCSV"
                      color="primary"
                      small
                      outlined
                      width="150"
                    >
                      <v-icon left>
                        mdi-file-download
                      </v-icon>
                      CSV
                    </v-btn>
                  </v-list-item-title>
                </v-list-item>
                <!-- クリップボード -->
                <v-list-item>
                  <v-list-item-title>
                    <v-btn
                      @click="copyClipboard"
                      color="primary"
                      small
                      outlined
                      width="150"
                    >
                      <v-icon left>
                        mdi-clipboard-edit
                      </v-icon>
                      クリップボード
                    </v-btn>
                  </v-list-item-title>
                </v-list-item>
                <!-- チケットコピー -->
                <v-list-item>
                  <v-list-item-title>
                    <v-btn
                      @click="showFromNew(true)"
                      color="primary"
                      small
                      outlined
                      width="150"
                    >
                      <v-icon left>
                        mdi-content-copy
                      </v-icon>
                      チケットコピー
                    </v-btn>
                  </v-list-item-title>
                </v-list-item>
                <!-- 設定 -->
                <v-list-item>
                  <v-list-item-title>
                    <v-btn
                      @click="showFormSettingTable"
                      color="primary"
                      small
                      outlined
                      width="150"
                    >
                      <v-icon left>
                        mdi-cog-outline
                      </v-icon>
                      設定
                    </v-btn>
                  </v-list-item-title>
                </v-list-item>
                <!-- ログアウト -->
                <v-list-item>
                  <v-list-item-title>
                    <v-btn
                      @click="logout"
                      color="primary"
                      small
                      outlined
                      width="150"
                    >
                      <v-icon left>
                        mdi-logout
                      </v-icon>
                      Logout
                    </v-btn>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </v-toolbar>
      <v-data-table
        elevation="0"
        :headers="headers"
        :items="tickets"
        :sort-by.sync="profiles.sortitem"
        :sort-desc.sync="profiles.sortdesc"
        :items-per-page="20"
        :footer-props = "{
          'items-per-page-text' : '行/ページ:',
          'items-per-page-options' : [ 5, 10, 15, 20, -1]
        }"
        class="elevation-1"
        dense
        show-select
        v-model="selectedrow"
        :page.sync="currentpage"
      >
        <template v-slot:[`item.subject`]="{ item }">
          <v-btn
            text
            @click="showForm(item)"
            class="custom-transform-class text-none text-truncate"
          >
            <p
              style="width:300px; text-align: left;"
              class="mt-5"
            >
            {{ item.subject }}
            </p>
          </v-btn>
        </template>
        <template
          v-slot:[`item.dt_end`]="{ item }"
        >
          <v-badge
            v-if = "checkDeadline(item)"
            color="red"
            icon="mdi-alert"
            left
          >
          </v-badge>
          {{ item.dt_end }}
        </template>
      </v-data-table>
    </v-card>
</template>

<script>
import TicketDetail from './TicketDetail.vue'
import TicketEditAll from './TicketEditAll.vue'
import RegFilter from './RegFilter.vue'
import FileList from './FileList.vue'
import TicketCondition from './TicketCondition.vue'
import SettingTable from './SettingTable.vue'
import { getAuth, signOut } from 'firebase/auth'
import { saveAs } from 'file-saver'

const axios = require('axios')

export default {
  components: {
    TicketDetail,
    RegFilter,
    TicketEditAll,
    FileList,
    TicketCondition,
    SettingTable
  },
  data () {
    return {
      user: {},
      users: [],
      usersname: [],
      tickets: [],
      tmpticket: {},
      selectedrow: [],
      selectedticketid: '',
      master: {},
      projects: [],
      projectsname: [],
      projectpath: '',
      projectall: '[***全検索***]',
      fileitems: [],
      countfiles: 0,
      countfilesOfProject: 0,
      flgdialog: false,
      flgdialogEdit: false,
      flgdialogfilter: false,
      flgdialogFile: false,
      flgdialogCondition: false,
      flgdialogSettingTable: false,
      profiles: {
        id: '',
        project: '',
        text: '',
        status: [],
        sortitem: '',
        sortdesc: false,
        usersname: [],
        filtername: '',
        headerlist: []
      },
      listFilter: [],
      listFiltername: [],
      dt_today: null,
      masterHeaders:
      {
        ID: { text: 'ID', value: 'ticket_id', filter: this.filterId },
        プロジェクト: { text: 'プロジェクト', value: 'project' },
        項目: { text: '項目', value: 'subject', filter: this.filterText, width: 300 },
        開始: { text: '開始', value: 'dt_start' },
        終了: { text: '終了', value: 'dt_end' },
        担当: { text: '担当', value: 'user', filter: this.filterUser },
        状況: { text: '状況', value: 'status', filter: this.filterStatus },
        種別: { text: '種別', value: 'type' },
        優先: { text: '優先', value: 'priority' },
        Ph: { text: 'Ph', value: 'phase' }
      },
      currentpage: 1
    }
  },
  computed: {
    headers () {
      return this.setHeaders(this.profiles)
    }
  },
  mounted () {
    this.user = JSON.parse(sessionStorage.getItem('user'))
    this.get_data_master().then(() => {
      this.get_data_profiles().then(() => {
        this.getDataProjects().then(() => {
          this.get_data().then(() => {
            this.showFormFromID()
          })
          this.getDataUsers().then(() => {
            this.setUsersnameList()
          })
        })
      })
    })
    this.dt_today = new Date()
  },
  methods: {
    test: function () {
      this.currentpage = 2
    },
    // チケットデータの取得
    get_data: async function () {
      // プロジェクトの設定がない場合に処理しない
      if (this.profiles.project === '') {
        return
      }
      // 全選択を考慮したURLの設定
      var tmpURL = ''
      if (this.profiles.project !== this.projectall) {
        tmpURL = '/api/tickets?project=' + this.getDataProjectsFromLocal(this.profiles.project).id
      } else {
        tmpURL = '/api/tickets?project=all&userid=' + this.user.id
      }
      // チケットデータの取得
      await axios.get(tmpURL).then((response) => {
        if (response.data != null) {
          this.tickets = response.data
          // ページの設定
          if (sessionStorage.getItem('currentpage') !== null) {
            this.currentpage = sessionStorage.getItem('currentpage')
            sessionStorage.removeItem('currentpage')
          } else {
            this.currentpage = 1
          }
        }
      }).catch(error => {
        alert(error)
      })
      // ユーザーリストの更新
      this.setUsersnameList()
      // プロジェクトフォルダーのファイル数の設定
      this.setCountFilesOfProject()
      // プロファイルの更新
      this.updateProfiles()
      // 選択解除
      this.clearSelected()
    },
    // マスターデータの取得
    get_data_master: async function () {
      await axios.get('/api/master').then(response => {
        this.master = response.data
      }).catch(error => {
        alert(error)
        // 最初のサーバーアクセスでエラーとなった場合にはログアウト
        this.logout()
      })
    },
    // プロジェクトデータの取得
    getDataProjects: async function () {
      await axios.get('/api/projects').then(response => {
        // ログインユーザーの登録されているプロジェクトのみの配列を生成
        this.projects = response.data
        for (var p of this.projects) {
          if (p.member.includes(this.user.username)) {
            this.projectsname.push(p.name)
          }
        }
        // 全検索用のプロジェクトの追加
        this.projectsname.unshift(this.projectall)
      }).catch(error => {
        alert(error)
      })
    },
    // ユーザーリストの取得
    getDataUsers: async function () {
      await axios.get('/api/users').then(response => {
        this.users = response.data
      }).catch(error => {
        alert(error)
      })
    },
    // ユーザーリスト プロジェクトの外部/内部に応じたリストの生成
    setUsersnameList: function () {
      // ユーザーリストの初期化
      this.usersname = []

      // プロジェクト未選択の場合、ユーザリストをセットしない
      if (this.profiles.project === '') {
        return
      }

      // 全件検索の場合、選択可能プロジェクトの全ユーザーをセット
      if (this.profiles.project === this.projectall) {
        for (var i = 0; i < this.projects.length; i++) {
          for (var n = 0; n < this.projects[i].member.length; n++) {
            if (this.usersname.includes(this.projects[i].member[n]) === false) {
              this.usersname.push(this.projects[i].member[n])
            }
          }
        }
      } else {
        // プロジェクトに応じたユーザーリストの生成
        this.usersname = this.getDataProjectsFromLocal(this.profiles.project).member
      }
    },
    // プロファイルデータ(取得)
    get_data_profiles: async function () {
      const loginid = JSON.parse(sessionStorage.getItem('user')).id
      await axios.get('/api/profiles?loginid=' + loginid).then(response => {
        // デフォルトのプロファイル設定
        this.profiles = response.data.current

        // filternameが存在しない場合に追加
        if ('filtername' in this.profiles === false) {
          this.profiles.filtername = ''
        }

        // フィルタ関連リストの取得/生成
        Object.keys(response.data).forEach((value) => {
          if (value !== 'current') {
            var tmpFilter = {}
            tmpFilter.filtername = value
            this.listFiltername.push(tmpFilter)
            this.listFilter.push(response.data[value])
          }
        })
      }).catch(error => {
        alert(error)
      })
    },
    // プロジェクトもしくはチケットのファイル数の取得(flg:project/ticket)
    setCountFilesOfProject: async function () {
      // プロジェクトが選択されていない場合及び全検索の場合
      if (this.profiles.project === '' || this.profiles.project === this.projectall) {
        return
      }
      const tmpPrj = this.getDataProjectsFromLocal(this.profiles.project)
      const parm = 'type=project' +
                      '&id_project=' + tmpPrj.id +
                      '&ex=' + tmpPrj.external +
                      '&id_ticket=0'
      await axios.get('/api/folder?' + parm).then((response) => {
        this.countfilesOfProject = response.data.countFile
      })
    },
    // チケットのファイル数の取得(flg:project/ticket)
    setCountFilesOfTicket: async function (item) {
      const tmpPrj = this.getDataProjectsFromLocal(item.project)
      const parm = 'type=ticket' +
                      '&id_project=' + tmpPrj.id +
                      '&ex=' + tmpPrj.external +
                      '&id_ticket=' + item.ticket_id
      await axios.get('/api/folder?' + parm).then((response) => {
        this.countfiles = response.data.countFile
      })
    },
    // デフォルト/プロファイルデータ(更新)
    updateProfiles: async function () {
      await axios.post('/api/profiles', this.profiles).then((response) => {
        this.profiles.filtername = response.data.filtername
      })
    },
    // デフォルトプロファイルデータの更新(リストからの更新と画面への反映)
    changeFilter: async function (item) {
      this.profiles = JSON.parse(JSON.stringify(this.listFilter[item]))
      this.get_data()
    },
    // プロジェクト切替
    changeProject: function () {
      this.get_data()
      this.profiles.filtername = ''
    },
    // チケットデータの入力画面起動(編集)
    showForm: async function (item) {
      // 状態保存としてのプロファイルの更新
      this.updateProfiles()

      // ページ保存
      sessionStorage.setItem('currentpage', this.currentpage)

      // 対象チケットの添付ファイル数のカウント
      this.countfiles = 0
      this.setCountFilesOfTicket(item)
      // 起動用フラグの設定
      this.flgdialog = true
      // 編集用データの設定
      this.tmpticket = item
    },
    // URLパラメータによる個別チケット表示
    showFormFromID: async function () {
      // 状態保存としてのプロファイルの更新
      this.updateProfiles()
      var ticketid = ''
      if (this.selectedticketid !== '' && isNaN(this.selectedticketid) === false) {
        ticketid = this.selectedticketid
      } else if (Number(this.$route.params.ticketid) !== 0) {
        ticketid = this.$route.params.ticketid
      } else {
        return
      }

      // 対象チケットデータの取得
      await axios.get('/api/tickets?id=' + ticketid + '&userid=' + this.user.id).then((response) => {
        // データがヒットした場合
        if (response.data.length !== 0) {
          this.tmpticket = response.data[0]

          // 対象のチケット情報からprojectを取得
          this.profiles.project = this.tmpticket.project

          // 対象チケットデータのプロジェクトに基づきデータ取得
          this.get_data().then(() => {
            this.showForm(this.tmpticket)
          })
        }
      })
    },
    // チケットデータの入力画面起動(新規)
    showFromNew: function (flgcopy = false) {
      // 状態保存としてのプロファイルの更新
      this.updateProfiles()
      // ページ保存
      sessionStorage.setItem('currentpage', this.currentpage)
      // 新規画面/複数データ一括更新の起動制御
      if (this.selectedrow.length === 0) {
        // 全件検索の場合にはプロジェクト特定できないため起動抑制
        if (this.profiles.project === this.projectall) {
          alert('プロジェクトを指定してください。')
          return
        }
        // プロジェクトデータのみ連携し、起動
        this.flgdialog = true
        this.tmpticket = {
          project: this.profiles.project,
          user: this.user.username,
          type: 'タスク',
          status: '未',
          priority: '中',
          dt_start: this.comDateToStr(this.dt_today),
          dt_end: this.comDateToStr(this.dt_today),
          projectid: this.getDataProjectsFromLocal(this.profiles.project).id
        }
      } else {
        if (flgcopy === false) {
          this.flgdialogEdit = true
        } else {
          if (this.selectedrow.length === 1) {
            this.flgdialog = true
            this.tmpticket = {
              project: this.selectedrow[0].project,
              subject: 'コピー' + this.selectedrow[0].subject,
              content: this.selectedrow[0].content,
              user: this.user.username,
              type: this.selectedrow[0].type,
              phase: this.selectedrow[0].phase,
              status: '未',
              priority: this.selectedrow[0].priority,
              dt_start: this.comDateToStr(this.dt_today),
              dt_end: this.comDateToStr(this.dt_today),
              projectid: this.selectedrow[0].projectid
            }
          } else {
            alert('複数選択されています。')
          }
        }
      }
    },
    // フィルター登録画面の起動
    showFormFilter: function () {
      this.flgdialogfilter = true
    },
    // フィルタのリセット
    resetFilter: async function () {
      // フィルタ情報を削除
      const tmpID = JSON.parse(sessionStorage.getItem('user')).id
      await axios.delete('/api/profiles?loginid=' + tmpID).then(() => {
        this.$router.go({ path: this.$router.currentRoute.path, force: true })
      })
    },
    // 選択解除
    clearSelected: function () {
      this.selectedrow = []
    },
    // ファイルリスト画面の起動
    openFolder: async function () {
      // 状態保存としてのプロファイルの更新
      this.updateProfiles()
      // プロジェクトの指定がない場合には、フォルダ画面を起動しない。
      if (['', this.projectall].includes(this.profiles.project)) {
        return
      }
      const tmpPrj = this.getDataProjectsFromLocal(this.profiles.project)
      const parm = 'type=project' +
              '&id_project=' + tmpPrj.id +
              '&ex=' + tmpPrj.external +
              '&id_ticket=0'
      var tmpPath = ''
      await axios.get('/api/folder?' + parm).then((response) => {
        tmpPath = response.data.path
      })
      if (tmpPrj.external === false) {
        window.open(tmpPath, null, 'width=500,toolbar=yes,menubar=yes,scrollbars=yes')
      } else {
        this.flgdialogFile = true
        this.projectpath = tmpPath
        const tmpURL = '/api/storage?path=' + this.projectpath
        this.fileitems = []
        await axios.get(tmpURL).then((response) => {
          this.fileitems = response.data
        })
      }
    },
    showFormCondition: function () {
      // 状態の保存
      sessionStorage.setItem('condition', JSON.stringify(this.profiles))
      this.flgdialogCondition = true
      this.clearSelected()
    },
    closeFromCondition: function () {
      this.flgdialogCondition = false
      this.updateProfiles()
    },
    // リストの設定画面の表示
    showFormSettingTable: function () {
      this.flgdialogSettingTable = true
    },
    // リストの設定画面のクローズ
    closeFromSettingTable: function () {
      this.flgdialogSettingTable = false
      this.updateProfiles()
    },
    // リストのヘッダーの設定
    setHeaders: function (prf) {
      // プロファイルの設定がない場合
      // データ取得前 エラー回避
      if (prf === {} || prf.id === '') {
        return
      }
      if (prf.headerlist.length === 0) {
        var i = 1
        Object.keys(this.masterHeaders).forEach((key) => {
          prf.headerlist.push({ order: i, headeritem: this.masterHeaders[key].text, visible: true })
          i = i + 1
        })
      }
      // ヘッダーの設定
      var tmpHeaders = []
      for (var k = 0; k < prf.headerlist.length; k++) {
        if (prf.headerlist[k].visible === true) {
          if (prf.headerlist[k].headeritem === 'プロジェクト') {
            if (prf.project === this.projectall) {
              tmpHeaders.push(this.masterHeaders[prf.headerlist[k].headeritem])
            }
          } else {
            tmpHeaders.push(this.masterHeaders[prf.headerlist[k].headeritem])
          }
        }
      }
      return tmpHeaders
    },
    // 抽出機能(ID)
    filterId: function (value) {
      if (!this.profiles.text) {
        return true
      }
      if (isNaN(this.profiles.text)) {
        return true
      }
      return this.profiles.text.includes(value)
    },
    // 抽出機能(項目)
    filterText: function (value) {
      if (!this.profiles.text) {
        return true
      }
      if (!isNaN(this.profiles.text)) {
        return true
      }
      return value.includes(this.profiles.text)
    },
    // 抽出機能(ステータス)
    filterStatus: function (value) {
      if (this.profiles.status.length === 0) {
        return true
      }
      return this.profiles.status.includes(value)
    },
    // 抽出機能(ユーザー)
    filterUser: function (value) {
      if (this.profiles.usersname.length === 0) {
        return true
      }
      return this.profiles.usersname.includes(value)
    },
    // 抽出条件の設定(Dialogから)→削除
    // setConditionFromDialog: function (data) {
    //  this.profiles.text = data.text
    //  this.profiles.status = data.status
    //  this.profiles.usersname = data.username
    // },
    // 期限アラートbadgeの表示判断
    checkDeadline: function (item) {
      if (Number(this.dt_today) > Number(Date.parse(item.dt_end)) &&
               ['対応済', '完了'].includes(item.status) === false) {
        return true
      } else {
        return false
      }
    },
    // ログアウト
    logout: function () {
      this.updateProfiles()
      axios.post('/api/logout')
      const auth = getAuth()
      signOut(auth).then(() => {
        sessionStorage.removeItem('user')
        alert('サインアウトしました。')
        this.$router.push({ path: '/login' })
      }).catch((error) => {
        alert(error)
      })
    },
    // CSVファイルのダウンロード
    downloadCSV: function () {
      var bom = new Uint8Array([0xEF, 0xBB, 0xBF])
      var txt = ''
      for (const row of this.selectedrow) {
        txt = txt + row.ticket_id + ','
        txt = txt + row.subject + ','
        txt = txt + row.dt_start + ','
        txt = txt + row.dt_end + ','
        txt = txt + row.user + '\n'
      }
      const csvdata = txt
      const fileName = window.prompt('ファイル名を入力してください', 'ticket') + '.csv'
      console.log(fileName)
      const blob = new Blob([bom, csvdata], { type: 'text/csv;charset=sjis' })
      saveAs(blob, fileName)
    },
    // チケット項目データのクリップボードコピー
    copyClipboard: async function () {
      var txt = ''
      for (const row of this.selectedrow) {
        txt = txt + row.ticket_id + ' '
        txt = txt + row.subject + '\n'
      }
      const copyText = txt
      await navigator.clipboard
        .writeText(copyText)
        .then(() => {
          alert('Copy to Clipboard.')
        })
        .catch(e => {
          alert(e)
        })
    },
    // プロジェクト詳細の取得
    getDataProjectsFromLocal: function (pjname) {
      for (var p of this.projects) {
        if (p.name === pjname) {
          return p
        }
      }
      return {
        id: '',
        name: '',
        external: false,
        member: [],
        memo: '',
        drive: '',
        dt_update: ''
      }
    }
  }
}
</script>
