<template>
  <v-dialog
    persistent
    v-model="flgDialogMail"
    width="800"
  >
    <v-card>
      <v-card-title>
        メール送信
      </v-card-title>
      <v-card-text>
        宛先:{{toName}}({{mailaddress}})
        <v-text-field
          label="件名"
          v-model="mailsubject"
        ></v-text-field>
        <v-textarea
          label="内容"
          v-model="mailcontent"
        >
        </v-textarea>
      </v-card-text>
      <v-card-action>
        <v-btn
          text
          color ="green"
          class ="ml-4"
          @click="sendMail"
        >
          送信
        </v-btn>
        <v-btn
          text
          color ="green"
          class ="ml-4"
          @click="closeForm"
        >
          キャンセル
        </v-btn>
      </v-card-action>
    </v-card>
  </v-dialog>
</template>

<script>
const axios = require('axios')
export default {
  props: [
    'flgDialogMail',
    'mailaddress',
    'toName'
  ],
  data: () => ({
    mailsubject: '',
    mailcontent: ''
  }),
  computed: {
    headers () {
      return [
        { text: 'ファイル', value: 'name' },
        { text: '削除', value: 'del' }
      ]
    },
    routes () {
      return this.getPathArray()
    }
  },
  methods: {
    // メール送信
    sendMail: async function () {
      // メール送信の確認
      var res = window.confirm('メールを送信してもいいですか?')

      // メール送信処理
      if (res === true) {
        // メール内容
        var tmpmsg = {
          to: [this.mailaddress],
          subject: this.mailsubject,
          content: this.mailcontent
        }
        // メール内容設定
        const maildata = {
          mailaction: 'send',
          type: 'exam',
          content: tmpmsg
        }
        // メール送信処理
        await axios.post('/api/mail', maildata).then(async (responsemail) => {
          if (responsemail.data.res === true) {
            alert('送信しました。')
            this.mailsubject = ''
            this.mailcontent = ''
            // フォームのクローズ
            this.closeForm()
          } else {
            alert(responsemail.data.msg)
          }
        })
      }
    },
    // フォームのクローズ
    closeForm: function () {
      this.flgDialogMail = false
      this.$emit('flgReset', false)
    }
  }
}
</script>
