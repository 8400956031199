<template>
  <v-dialog
    persistent
    v-model="flgDialogCash"
    width="800"
  >
    <v-card>
      <v-card-title>オデッセイ残高</v-card-title>
      <v-card-text>
        <!-- 入金データ入力 -->
        <v-row style="height:40px">
          <v-col cols="3">
            <v-menu
              v-model="menu1"
              :close-on-content-click="false"
              max-width="290"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  label="入金日"
                  v-model="tmpCash.dt"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  dense
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="tmpCash.dt"
                no-title
                scrollable
                @input="menu1=false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="2">
            <v-text-field
              label="金額"
              v-model="tmpCash.amount"
              dense
            >
            </v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              label="メモ"
              v-model="tmpCash.memo"
              dense
            >
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-btn
              @click="addCashDB"
            >
              登録
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            オデッセイ残高({{comDateToStr(dtpre).slice(5, 10)}})<br>
            (予約基準日:{{comDateToStr(dt2).slice(5, 10)}})<br>
            {{calcSumAmount().sumpre.toLocaleString()}}円<br>
            全試験予定残高<br>
            {{calcSumAmount().sum.toLocaleString()}}円<br>
          </v-col>
          <v-col cols="9">
            <v-data-table
              :headers="headers"
              :items="Cash"
              dense
            >
              <template
                v-slot:[`item.del`]="{ item }"
              >
                <v-btn
                  @click="delCashDB(item)"
                  text
                >
                  <v-icon>
                    mdi-trash-can
                  </v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="deep-purple lighten-2"
          outlined
          right
          @click="closeForm"
        >
          閉じる
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'
export default {
  props: [
    'flgDialogCash',
    'reservedata'
  ],
  data: () => ({
    menu1: false,
    Cash: [],
    tmpCash: {
      id: '',
      dt: '',
      type: '入金',
      amount: 0,
      memo: '',
      update_dt: '',
      update_id: ''
    },
    dt0: null,
    dt2: null,
    dtpre: null
  }),
  computed: {
    headers () {
      return [
        { text: '入金日', value: 'dt' },
        { text: '金額', value: 'amount' },
        { text: '備考', value: 'memo' },
        { text: '削除', value: 'del' }
      ]
    }
  },
  mounted () {
    this.getCashDB()
    this.dtpre = new Date()
    this.dtpre.setDate(this.dtpre.getDate() - 1)
    this.dt2 = this.comGetBusinessDate(this.dtpre, 2)
  },
  methods: {
    getCashDB: async function () {
      // チケットデータの取得
      await axios.get('/api/cashdb').then((response) => {
        if (response.data != null) {
          this.Cash = response.data
        }
      }).catch(error => {
        alert(error)
      })
    },
    addCashDB: async function () {
      this.tmpCash.update_dt = this.comDateToStr(new Date())
      this.tmpCash.update_id = JSON.parse(sessionStorage.getItem('user')).id
      await axios.post('/api/cashdb', this.tmpCash).then((response) => {
        this.getCashDB()
        this.clearCash()
      })
    },
    delCashDB: async function (itm) {
      await axios.delete('/api/cashdb?id=' + itm.id).then((response) => {
        this.getCashDB()
      })
    },
    closeForm: function () {
      this.flgDialogCash = false
      this.$emit('flgReset', false)
    },
    clearCash: function () {
      this.tmpCash = {
        id: '',
        dt: '',
        type: '入金',
        amount: 0,
        memo: '',
        update_dt: '',
        update_id: ''
      }
    },
    calcSumAmount: function () {
      // cashデータの単純全合計
      const sumcash = this.Cash.reduce((sum, i) => sum + Number(i.amount), 0)

      // 試験料金の単純全合計
      const tmpReserveAll = this.reservedata.filter(reserve => ['申込不可', '中止', 'キャンセル'].includes(reserve.status) === false)
      const sumfee = tmpReserveAll.reduce((sum, i) => sum + Number(i.price_bk), 0)

      // 前日までのcashデータを抽出し、合計
      const tmpCashpre = this.Cash.filter(ch => new Date(ch.dt) <= this.dtpre)
      const sumcashpre = tmpCashpre.reduce((sum, i) => sum + Number(i.amount), 0)

      // 2営業日前までの予約データを抽出し、合計
      const tmpreserve2 = tmpReserveAll.filter(reserve => new Date(reserve.code_schedule.slice(0, 10)) <= this.dt2)
      const sumfee2 = tmpreserve2.reduce((sum, i) => sum + Number(i.price_bk), 0)

      return {
        sumpre: sumcashpre - sumfee2,
        sum: sumcash - sumfee
      }
    }
  }
}
</script>
