<template>
  <v-dialog
    persistent
    v-model="flgDialog"
    width="400"
  >
    <v-card
      class="mx-auto"
    >
      <v-card-title class="ml-2">勤務入力--{{workdata.dt}}--</v-card-title>
      <v-card-text class="mt-3">
        <v-col>
          <!-- 勤務種別 -->
          <v-row dense>
            <v-col cols="4">
              <v-select
                v-model="workdata.type"
                :items="worktypelist"
                label="勤務"
                @change="setTimeDefault"
                :disabled = "flgNew()"
              >
              </v-select>
            </v-col>
            <v-col cols="6">
            <v-radio-group
              v-if="isHalfDay()=== true"
              v-model="workdata.timetype"
              column
              @change="setTimeDefault"
            >
              <v-row>
                <v-col>
                  <v-radio
                    label="午前"
                    value="[午前]"
                  ></v-radio>
                </v-col>
                <v-col>
                  <v-radio
                    label="午後"
                    value="[午後]"
                  ></v-radio>
                </v-col>
              </v-row>
            </v-radio-group>
            </v-col>
          </v-row>
          <!-- 時間 -->
          <v-row dense>
            <v-col cols="4">
              <v-menu
                ref="menu_start"
                v-model="menu1"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="workdata.time_start"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="workdata.time_start"
                    label="開始"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="menu1"
                  v-model="workdata.time_start"
                  full-width
                  @click:minute="$refs.menu_start.save(workdata.time_start)"
                >
                </v-time-picker>
              </v-menu>
            </v-col>
            <v-col cols="4">
              <v-menu
                ref="menu_end"
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="workdata.time_end"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="workdata.time_end"
                    label="終了"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="menu2"
                  v-model="workdata.time_end"
                  full-width
                  @click:minute="$refs.menu_end.save(workdata.time_end)"
                >
                </v-time-picker>
              </v-menu>
            </v-col>
            <v-col cols="4">
              <v-menu
                ref="menu_break"
                v-model="menu3"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="workdata.time_break"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="workdata.time_break"
                    label="休憩"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="menu3"
                  v-model="workdata.time_break"
                  full-width
                  @click:minute="$refs.menu_break.save(workdata.time_break)"
                >
                </v-time-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-textarea
                label="メモ"
                v-model="workdata.memo"
                counter
              >
              </v-textarea>
              {{workdata.seq}}
            </v-col>
          </v-row>
        </v-col>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="deep-purple lighten-2"
          outlined
          right
          @click="delData"
        >
          削除
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="deep-purple lighten-2"
          outlined
          right
          @click="closeForm"
        >
          キャンセル
        </v-btn>
        <v-btn
          color="green lighten-2"
          outlined
          right
          @click="updateData"
        >
          更新
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
const axios = require('axios')
export default {
  props: [
    'flgDialog',
    'workdata',
    'workdatalist',
    'regulartime'
  ],
  data: () => ({
    menu1: false,
    menu2: false,
    menu3: false,
    mdata: {}
  }),
  mounted () {
    // デフォルト設定
    this.workdata.time_start = '00:00'
    this.workdata.time_end = '00:00'
    this.workdata.time_break = '00:00'
    // マスターデータ
    this.mdata = JSON.parse(sessionStorage.getItem('master'))
    this.worktypelist = this.mdata.worktypelist
  },
  methods: {
    // 半日勤務種別判断
    isHalfDay: function () {
      const tmpList = ['勤務(半)', '有休(半)', '振勤(半)', '振休(半)', '欠勤(半)', '特休(半)']
      if (tmpList.includes(this.workdata.type) === true) {
        return true
      } else {
        return false
      }
    },
    // 勤務種別による時間設定
    setTimeDefault: function () {
      if (this.isHalfDay() === true) {
        if (this.workdata.timetype === '[午前]') {
          this.workdata.time_start = this.regulartime[0]
          this.workdata.time_end = ('0' + (Number(this.regulartime[0].slice(0, 2)) + 4)).slice(-2) + this.regulartime[0].slice(-3)
          this.workdata.time_break = '00:00'
        } else if (this.workdata.timetype === '[午後]') {
          this.workdata.time_start = ('0' + (Number(this.regulartime[1].slice(0, 2)) - 4)).slice(-2) + this.regulartime[1].slice(-3)
          this.workdata.time_end = this.regulartime[1]
          this.workdata.time_break = '00:00'
        } else {
          // ブランクの場合に午前をデフォルトとする。
          this.workdata.timetype = '[午前]'
          this.workdata.time_start = this.regulartime[0]
          this.workdata.time_end = ('0' + (Number(this.regulartime[0].slice(0, 2)) + 4)).slice(-2) + this.regulartime[0].slice(-3)
          this.workdata.time_break = '00:00'
        }
      } else {
        this.workdata.timetype = ''
        this.workdata.time_start = this.regulartime[0]
        this.workdata.time_end = this.regulartime[1]
        this.workdata.time_break = '01:00'
      }
    },
    // データ更新
    updateData: async function () {
      // 勤務種別のチェック
      if (this.checkAvailableType() === false) {
        alert('対象の勤務種別は選択できません。')
        return
      }

      // 申請データチェック
      if (this.workdata.seq === '') {
        // 新規の場合
        if (this.checkApplyData('new') === false) {
          alert('取得日の近い申請は、「申請事由はxxx」と記載してください。')
          return
        }
      } else {
        // 変更の場合
        if (this.checkApplyData('edit') === false) {
          alert('休暇等の変更の場合には、備考欄に「変更事由はxxx」と記載してください。')
          return
        }
      }

      // 文字数制限
      if (this.workdata.memo.length > 41) {
        alert('備考の文字数が多すぎます。40字以内にしてください。')
        return
      }

      if (this.checkWorkData() === false) {
        alert('時間の重複があります。')
        return
      }

      // データの更新
      await axios.post('/api/workreport', this.workdata).then(() => {
        this.closeForm()
      }).catch(error => {
        alert(error)
      })
    },
    // データ削除
    delData: async function () {
      if (this.checkApplyData('del') === false) {
        alert('休暇等の取消の場合には、備考欄に「取消事由はxxx」と記載してください。')
        return
      }
      await axios.delete('/api/workreport', { data: this.workdata }).then(() => {
        this.closeForm()
      }).catch(error => {
        alert(error)
      })
    },
    // 画面を閉じる
    closeForm: function () {
      this.flgDialog = false
      this.$emit('flgReset', false)
      this.$emit('flgyyyymm', this.workdata.dt.substr(0, 7))
      // this.$router.go({ path: this.$router.currentRoute.path, force: true })
    },
    // 勤務種別の変更を抑制するためのフラグ
    flgNew: function () {
      if (this.workdata.seq === '') {
        return false
      } else {
        return true
      }
    },
    // 重複登録チェック
    checkAvailableType: function () {
      // 制約 時間休は通常勤務の時のみ取得可能とする。
      // 振替勤務、休日勤務の場合には取得しない
      const availableList = [
        { code: '勤務', available: ['勤務', '有休(時)'] },
        { code: '勤務(半)', available: ['勤務', '有休(半)', '有休(時)', '振休(半)', '欠勤(半)', '特休(半)'] },
        { code: '有休', available: [] },
        { code: '有休(半)', available: ['勤務', '勤務(半)', '有休(時)', '振休(半)', '欠勤(半)', '特休(半)'] },
        { code: '有休(時)', available: ['勤務', '勤務(半)', '有休(半)', '有休(時)', '振休(半)', '欠勤(半)', '特休(半)'] },
        { code: '振勤', available: [] },
        { code: '振勤(半)', available: [] },
        { code: '振休', available: [] },
        { code: '振休(半)', available: ['勤務', '勤務(半)', '有休(半)', '有休(時)', '振休(半)', '欠勤(半)', '特休(半)'] },
        { code: '休勤', available: [] },
        { code: '休勤(半)', available: [] },
        { code: '欠勤', available: [] },
        { code: '欠勤(半)', available: ['勤務', '勤務(半)', '有休(半)', '有休(時)', '振休(半)', '特休(半)'] },
        { code: '特休', available: [] },
        { code: '特休(半)', available: ['勤務', '勤務(半)', '有休(半)', '有休(時)', '振休(半)', '欠勤(半)'] }
      ]

      // 選択可能な勤務種別の取得
      var selectedList = {}
      for (var k = 0; k < availableList.length; k++) {
        if (this.workdata.type === availableList[k].code) {
          selectedList = availableList[k]
          break
        }
      }
      // すでに登録されている勤務種別が、今回選択した勤務種別と不整合がないかチェック
      for (var i = 0; i < this.workdatalist.length; i++) {
        if (selectedList.available.includes(this.workdatalist[i].type) === false &&
              this.workdata.seq !== this.workdatalist[i].seq) {
          return false
        }
      }
      // すべての取引種別が選択可能なものであった場合
      return true
    },
    // 休暇振替チェック
    checkApplyData: function (updatetype) {
      // updatetype new/edit/del
      // 休暇/振替の期限チェック
      const applyList = ['有休', '有休(半)', '有休(時)', '振勤', '振勤(半)', '振休', '振休(半)']
      if (applyList.includes(this.workdata.type) === true) {
        if (updatetype === 'new') {
          const dtToday5 = this.comGetBusinessDate(new Date(), 5)
          if (new Date(this.workdata.dt) < dtToday5 && this.workdata.memo.includes('申請事由') === false) {
            return false
          }
        } else if (updatetype === 'edit') {
          if (this.workdata.memo.includes('変更事由') === false) {
            return false
          }
        } else if (updatetype === 'del') {
          if (this.workdata.memo.includes('取消事由') === false) {
            return false
          }
        }
      }
    },
    // 時間重複チェック
    checkWorkData: function () {
      var wts = new Date(this.workdata.dt + ' ' + this.workdata.time_start)
      var wte = new Date(this.workdata.dt + ' ' + this.workdata.time_end)
      var i = 0
      while (i < this.workdatalist.length) {
        var tmpwts = new Date(this.workdatalist[i].dt + ' ' + this.workdatalist[i].time_start)
        var tmpwte = new Date(this.workdatalist[i].dt + ' ' + this.workdatalist[i].time_end)
        if (wte <= tmpwts || wts >= tmpwte) {
          // 重複しないケース
        } else {
          // 重複するケース
          if (this.workdata.seq !== this.workdatalist[i].seq) {
            return false
          }
        }
        i = i + 1
      }
      return true
    }
  }
}
</script>
