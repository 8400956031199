<template>
  <v-dialog
    persistent
    v-model="flgDialog"
    max-width="300px"
  >
    <v-card>
      <v-card-title>
        フィルター
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-text-field
              v-model="nameFilter"
            >
            </v-text-field>
          </v-col>
          <v-col>
            <v-btn
              x-small
              @click="addFilter"
            >
              ADD
            </v-btn>
          </v-col>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="listFilter"
          :items-per-page="5"
          class="elevation-1"
          dense
        >
          <template v-slot:[`item.update`]="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="updateFilter(item)"
            >
              mdi-content-save-edit
            </v-icon>
            <v-icon
              small
              @click="deleteFilter(item)"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          @click="closeForm"
        >
          キャンセル
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'
export default {
  props: [
    'flgDialog',
    'listFilter',
    'tmpFilter'
  ],
  data () {
    return {
      headers:
        [
          { text: 'フィルター', value: 'filtername' },
          { text: 'Update', value: 'update' }
        ],
      filters: [],
      nameFilter: '',
      projects: [],
      projectnew: ''
    }
  },
  methods: {
    addFilter: async function () {
      if (this.nameFilter !== 'current' && this.nameFilter !== '') {
        this.tmpFilter.filtername = this.nameFilter
        await axios.post('/api/profiles?nameFilter=' + this.nameFilter, this.tmpFilter)
        this.$router.go({ path: this.$router.currentRoute.path, force: true })
      } else {
        alert('「current」は利用できません。')
      }
    },
    updateFilter: async function (item) {
      await axios.post('/api/profiles?nameFilter=' + item.filtername, this.tmpFilter)
      this.$router.go({ path: this.$router.currentRoute.path, force: true })
    },
    deleteFilter: async function (item) {
      await axios.delete('/api/profiles?loginid=' + this.tmpFilter.id + '&nameFilter=' + item.filtername)
      this.$router.go({ path: this.$router.currentRoute.path, force: true })
    },
    closeForm: function () {
      this.flgDialog = false
      this.$emit('flgResetFilter', false)
    }
  }
}
</script>
