<template>
  <v-card>
    <v-toolbar
        dense
        elevation="0"
      >
      <ReserveDetail
        :flgDialogExam="flgDialogExam"
        :reservedetail="reservedetail"
        :flgEdit="flgEdit"
        :eventlist="eventlist"
        @flgReset="flgDialogExam = $event"
      >
      </ReserveDetail>
      <v-col cols="1">
        <v-btn
          fab
          x-small
          color="green"
          class="mt-2"
          @click="showFormNew"
        >
        <v-icon>
          mdi-pencil
        </v-icon>
        </v-btn>
      </v-col>
      <v-col>
        <v-select
          hide-details
          v-model="reservetypeFilter"
          :items="mdata.event.type"
          label="種別"
          clearable
          class="mt-2"
        >
        </v-select>
      </v-col>
      <v-col>
        <v-text-field
          hide-details
          prepend-icon="mdi-magnify"
          single-line
          v-model="txtFilter"
          class="mt-2"
          label="検索"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-select
          hide-details
          v-model="statusFilter"
          :items="mdata.reservation.status"
          label="Status"
          clearable
          class="mt-2"
        >
        </v-select>
      </v-col>
      <v-col>
        <CashForExam
          :flgDialogCash="flgDialogCash"
          :reservedata="reservationlist"
          @flgReset="flgDialogCash = $event"
        >
        </CashForExam>
        <v-btn
          outlined
          color = "red"
          small
          @click="showFormCash"
        >
          預り金
        </v-btn>
      </v-col>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="reservationlist"
      :search="txtFilter"
      :options.sync="options"
      :page.sync="page"
      multi-sort
      dense
    >
      <template
      v-slot:[`item.code_schedule`]="{ item }"
      >
        <v-btn
          text
          class="custom-transform-class text-none"
          @click="showForm(item)"
        >
          {{ item.code_schedule }}
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import axios from 'axios'
import ReserveDetail from './ReserveDetail.vue'
import CashForExam from './CashForExam.vue'
export default {
  components: {
    ReserveDetail,
    CashForExam
  },
  data: () => ({
    reservationlist: [],
    eventlist: [],
    txtFilter: '',
    statusFilter: '',
    reservetypeFilter: '',
    flgDialogExam: false,
    flgEdit: false,
    flgDialogCash: false,
    reservedetail: {},
    dt_today: ''
  }),
  computed: {
    headers () {
      return [
        { text: 'ID', value: 'id', align: ' d-none' },
        { text: '日付', value: 'code_schedule' },
        { text: '区分', value: 'type_reservation', filter: this.setFilterType },
        { text: '種別', value: 'item' },
        { text: '名前(カナ)', value: 'name_kana' },
        { text: 'Status', value: 'status', filter: this.setFilterStatus },
        { text: 'イベントID', value: 'id_event' },
        { text: '更新日', value: 'dt_update' }
      ]
    }
  },
  mounted () {
    // マスターデータの設定
    this.mdata = JSON.parse(sessionStorage.getItem('master'))
    // 予約データの取得
    this.getDataReserve()
    // イベントデータの取得
    this.getDataEvents()
    // イベントリストからの遷移時にイベント特定用データ
    var tmpEventid = sessionStorage.getItem('selected_event_id')
    if (tmpEventid !== null) {
      this.txtFilter = tmpEventid
      sessionStorage.removeItem('selected_event_id')
    }
    // 今日日付の設定
    this.dt_today = new Date()
  },
  methods: {
    // データ取得
    getDataReserve: async function () {
      await axios.get('/api/reservation').then(response => {
        this.reservationlist = response.data
      })
    },
    getDataEvents: async function () {
      await axios.get('/api/events?active=False').then(response => {
        this.eventlist = response.data
      })
    },
    // 抽出機能(区分)
    setFilterType: function (value) {
      if (!this.reservetypeFilter) {
        return true
      }
      return value.includes(this.reservetypeFilter)
    },
    // 抽出機能(Status)
    setFilterStatus: function (value) {
      if (!this.statusFilter) {
        return true
      }
      return value.includes(this.statusFilter)
    },
    showFormNew: function () {
      this.flgDialogExam = true
      this.reservedetail = {
        id: '',
        id_event: '',
        code_schedule: '',
        type_reservation: 'exam',
        item: '',
        item_reservation: '',
        name: '',
        name_kana: '',
        parent_name: '',
        dt_birth: this.comDateToStr(this.dt_today),
        num: '',
        post_no: '',
        address: '',
        tel: '',
        email: '',
        type_payment: {
          type_payment: '',
          ticket_no: ''
        },
        type_discount: {
          type_discount: '',
          organization_name: '',
          test_code: '',
          student_teacher: '',
          type_exam: '',
          item_exam: '',
          dt_exam: ''
        },
        memo: '',
        status: '申込',
        dt_update: '',
        id_update: '',
        status_hist: [],
        price: 0,
        reason_notaccept: '',
        reason_memo_notaccept: '',
        memo_admin: '',
        flg_parking_mail: false
      }
    },
    showForm: function (item) {
      this.flgDialogExam = true
      this.reservedetail = item
      this.flgEdit = true
      this.examschedules = [this.reservedetail.code_schedule]
      this.workshopschedules = [this.reservedetail.code_schedule]
    },
    showFormCash: function () {
      console.log(this.reservationlist)
      this.flgDialogCash = true
    }
  }
}
</script>
