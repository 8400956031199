<template>
  <v-dialog
    persistent
    v-model="flgDialogEvent"
    width="600"
  >
    <v-card
      class="mx-auto"
    >
      <v-card-title
        class = "ml-4"
      >
        イベント詳細
        <p class ="text-caption mt-3">
        (ID:{{eventdetail.id}})
        </p>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-row
              class="mt-2"
              style="height:50px"
            >
              <v-col cols="10">
                <v-select
                  class="ml-3"
                  v-model="eventdetail.type_event"
                  :items="masterevent.type"
                  label ="種別"
                  :disabled ="flgEdit"
                  dense
                ></v-select>
              </v-col>
            </v-row>
            <v-row
              class="mt-4"
              style="height:50px"
            >
              <v-col cols="10">
                <v-menu
                  v-model="menu1"
                  :close-on-content-click="false"
                  max-width="290"
                >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      label="日付"
                      class="ml-3"
                      v-model="eventdetail.dt"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      dense
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="eventdetail.dt"
                    no-title
                    scrollable
                    @input="menu1=false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row
              class="mt-4"
              style="height:50px"
            >
              <v-col cols="6">
                <v-menu
                  ref="menu_start"
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="eventdetail.time_start"
                  transition="scale-transition"
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      class="ml-3"
                      v-model="eventdetail.time_start"
                      label="開始"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      dense
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="menu2"
                    v-model="eventdetail.time_start"
                    full-width
                    @click:minute="$refs.menu_start.save(eventdetail.time_start)"
                  >
                  </v-time-picker>
                </v-menu>
              </v-col>
              <v-col cols="6">
                <v-menu
                  ref="menu_end"
                  v-model="menu3"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="eventdetail.time_end"
                  transition="scale-transition"
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      class="mr-2"
                      v-model="eventdetail.time_end"
                      label="終了"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      dense
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="menu3"
                    v-model="eventdetail.time_end"
                    full-width
                    @click:minute="$refs.menu_end.save(eventdetail.time_end)"
                  >
                  </v-time-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row
              class="mt-6 ml-4"
              v-if="eventdetail.dt_entry_limit!=None"
            >
              <label>
                申込期日(ユーザー)：{{eventdetail.dt_entry_limit}}
              </label>
            </v-row>
            <v-row
              class="mt-4 ml-4"
              v-if="eventdetail.dt_payment_limit_user!=None"
            >
              <label>
                支払期日(ユーザー)：{{eventdetail.dt_payment_limit_user}}
              </label>
            </v-row>
            <v-row
              class="mt-4 ml-4"
              v-if="eventdetail.dt_payment_limit_center!=None"
            >
              <label>
                支払期日(センター)：{{eventdetail.dt_payment_limit_center}}
              </label>
            </v-row>
          </v-col>
          <v-col>
            <v-row
              class="mt-2 ml-2 mr-3"
              style="height:50px"
            >
              <v-text-field
                v-model="eventdetail.name"
                label="名前"
              ></v-text-field>
            </v-row>
            <v-row
              class="mt-4 ml-2 mr-3"
              style="height:50px"
            >
              <v-text-field
                label="内容"
                v-model = "eventdetail.content"
              ></v-text-field>
            </v-row>
            <!-- イベント毎表示制御(回/人数) -->
            <v-row
              class="mt-4"
              style="height:50px"
            >
              <v-col cols="6">
                <v-text-field
                  class="ml-2"
                  label="人数"
                  v-model ="eventdetail.num"
                  dense
                ></v-text-field>
              </v-col>
              <v-col
                cols="6"
                v-if="eventdetail.type_event=='workshop'"
              >
                <v-text-field
                  class="mr-3"
                  label="回"
                  v-model="eventdetail.series"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row
              class="mt-6 ml-2 mr-3"
            >
              <v-textarea
                label="備考"
                v-model ="eventdetail.memo"
                rows ="5"
                dense
              ></v-textarea>
            </v-row>
            <v-row
              class="mt-4"
              style="height:50px"
            > <v-col cols="6">
              <v-select
                class="ml-2"
                :items="masterevent.status"
                label="Status"
                v-model ="eventdetail.status"
                dense
              ></v-select>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          text
          color ="green"
          class ="ml-4"
          @click="showReserve"
        >
          予約明細
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          text
          color ="red"
          class ="ml-4"
          @click="closeForm"
        >
          キャンセル
        </v-btn>
        <v-btn
          text
          color ="green"
          @click="updateData"
        >
          更新
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'
export default {
  props: [
    'flgDialogEvent',
    'eventdetail',
    'flgEdit',
    'fromWhere'
  ],
  data: () => ({
    menu1: false,
    menu2: false,
    menu3: false,
    masterevent: {
      status: '',
      type: ''
    }
  }),
  mounted () {
    // sessionから種類とステータスを取得
    this.getMasterEvent()
  },
  methods: {
    checkItems: function () {
      // 時刻の前後関係チェック
      if (this.eventdetail.time_start > this.eventdetail.time_end) {
        alert('開始時刻と終了時刻が正しくありません')
        return false
      }

      // 必須チェック、データ型チェック
      if (this.eventdetail.name === '') {
        alert('名前を入力してください。')
        return false
      }
      if (this.eventdetail.num === '' || isNaN(this.eventdetail.num) === true) {
        alert('人数を数値で入力してください。')
        return false
      }
      if (isNaN(this.eventdetail.series) === true) {
        alert('回数は数値で入力してください。')
        return false
      }

      // チェックに抵触しない場合
      return true
    },
    closeForm: function () {
      this.flgDialogEvent = false
      this.$emit('flgReset', false)
      if (this.fromWhere !== 'reserveDetail') {
        this.$router.go({ path: this.$router.currentRoute.path, force: true })
      }
    },
    updateData: async function () {
      // 必須チェック
      if (this.checkItems() === false) {
        return
      }
      await axios.post('/api/events', [this.eventdetail])
      this.closeForm()
    },
    showReserve: function () {
      sessionStorage.setItem('selected_event_id', this.eventdetail.id)
      this.$router.push({ path: '/reservationlist', force: true })
    },
    getMasterEvent: function () {
      // sessionかeventmaster取得
      this.masterevent = JSON.parse(sessionStorage.getItem('master')).event
    }
  }
}
</script>
