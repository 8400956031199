<template>
  <v-dialog
    persistent
    v-model="flgDialogExamList"
    width="1000"
  >
    <v-card>
      <v-card-title>
        試験一覧
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="3">
            <v-file-input
              class="mt-2"
              label="upload file"
              v-model="selectedfile"
              dense
            ></v-file-input>
          </v-col>
          <v-col cols="1">
            <v-btn
              @click="uploadfile"
              class="mr-5 mt-2"
              text
            >
              <v-icon>
                mdi-upload
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-data-table
            :headers="headers"
            :items="examdata"
            dense
            class="my-class"
          >
            <template
              v-slot:[`item.normal`]="{ item }"
            >
              {{ item.normal }}<br>({{item.normal_bk}})
            </template>
            <template
              v-slot:[`item.student`]="{ item }"
            >
              {{ item.student }}<br>({{item.student_bk}})
            </template>
                        <template
              v-slot:[`item.organization`]="{ item }"
            >
              {{ item.organization }}<br>({{item.organization_bk}})
            </template>
                        <template
              v-slot:[`item.exam`]="{ item }"
            >
              {{ item.exam }}<br>({{item.exam_bk}})
            </template>
          </v-data-table>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="deep-purple lighten-2"
          outlined
          right
          @click="closeForm"
        >
          閉じる
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
const axios = require('axios')
export default {
  props: [
    'flgDialogExamList'
  ],
  data: () => ({
    selectedfile: '',
    examdata: [],
    filename: 'examlist.xlsx'
  }),
  computed: {
    headers () {
      return [
        { text: '区分', value: 'type', width: 100 },
        { text: '資格', value: 'certification', width: 100 },
        { text: '科目名', value: 'subject', width: 150 },
        { text: '一般', align: 'right', value: 'normal' },
        { text: '学割', align: 'right', value: 'student' },
        { text: '会員', align: 'right', value: 'organization' },
        { text: '受験', align: 'right', value: 'exam' },
        { text: 'tkt', align: 'center', value: 'ticket' },
        { text: 'URL', align: 'left', value: 'url', width: 150 }
      ]
    }
  },
  mounted () {
    // マスターデータの設定
    this.refreshData()
  },
  methods: {
    // 試験データの取得
    refreshData: async function () {
      await axios.get('/api/masterexam').then((response) => {
        this.examdata = response.data
      })
      // this.mdata = JSON.parse(sessionStorage.getItem('master'))
    },
    // ファイルのアップロード
    uploadfile: async function () {
      // ファイルチェック
      if (this.selectedfile.name !== this.filename) {
        alert('適切にファイルを設定してください。')
        return
      }
      // アップロード用オブジェクトの生成
      var formData = new FormData()
      // ファイルのアップロード処理
      formData.append('file', this.selectedfile)
      await axios.post('/api/fileupload', formData)
      // 試験データの取得
      this.refreshData()
      // 選択ファイルのクリア
      this.selectedfile = null
    },
    // 画面のクローズ
    closeForm: function () {
      this.flgDialogExamList = false
      this.$emit('flgReset', false)
      this.$router.go({ path: this.$router.currentRoute.path, force: true })
    }
  }
}
</script>
