import Vue from 'vue'
import Router from 'vue-router'
import Login from '../components/Login.vue'
import TicketList from '../components/TicketList.vue'
import WorkReport from '../components/WorkReport.vue'
import UserList from '../components/UserList.vue'
import ProjectList from '../components/ProjectList.vue'
import ReservationList from '../components/ReservationList.vue'
import EventList from '../components/EventList.vue'
import Calendars from '../components/Calendars.vue'
import ReserveOfHP from '../components/ReserveOfHP.vue'
import TypingGame from '../components/TypingGame.vue'
import testupload from '../components/testupload.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/ticketlist/:ticketid',
      name: 'TicketList',
      component: TicketList
    },
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '/workreport',
      name: 'WorkReport',
      component: WorkReport
    },
    {
      path: '/userlist',
      name: 'UserList',
      component: UserList
    },
    {
      path: '/projectlist',
      name: 'ProjectList',
      component: ProjectList
    },
    {
      path: '/reservationlist',
      name: 'ReservationList',
      component: ReservationList
    },
    {
      path: '/eventlist',
      name: 'EventList',
      component: EventList
    },
    {
      path: '/calendars',
      name: 'Calendars',
      component: Calendars
    },
    {
      path: '/reserveofhp',
      name: 'ReserveOfHP',
      component: ReserveOfHP
    },
    {
      path: '/typinggame',
      name: 'TypingGame',
      component: TypingGame
    },
    {
      path: '/testupload',
      name: 'testupload',
      component: testupload
    }
  ]
})

router.beforeEach((to, from, next) => {
  // テスト用ルーティング
  if (['ReserveOfHP', 'TypingGame', 'testupload'].includes(to.name)) {
    next()
    return
  }

  // 認証/権限ルーティング
  const userauth = sessionStorage.getItem('user')

  // セッションにユーザーデータがあれば認証済
  if (userauth !== null) {
    // Route Nameと機能の対応表
    const listroute = {
      TicketList: 'ticket',
      Login: 'login',
      WorkReport: 'workreport',
      UserList: 'user',
      ProjectList: 'project',
      ReservationList: 'reservation',
      EventList: 'events',
      Calendars: 'calendar'
    }

    // ログインユーザーの許可機能
    const listfunction = JSON.parse(sessionStorage.getItem('userinfo')).function

    // 許可機能リストに入っている場合には遷移
    if (listfunction.includes(listroute[to.name])) {
      next()
    }
  } else {
    if (to.name === 'login') {
      next()
    } else {
      // セッション情報がない場合にはログインへ
      // チケットデータをダイレクトに表示するための対応
      if (to.name === 'TicketList') {
        var ticketid = to.params.ticketid
        sessionStorage.setItem('ticketid', JSON.stringify(ticketid))
      }
      next({ name: 'login' })
    }
  }
})

export default router
