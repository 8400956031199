<template>
  <v-dialog
    persistent
    v-model="tmpdialog"
    max-width="1000px"
  >
    <v-card>
      <v-card-title>
        <span class="text-h5">チケット ID:{{tmpticket.ticket_id}}</span>
        <FileList
          :flgdialogFile="flgdialogFile"
          :fileitems="fileitems"
          :path="ticketpath"
          @flgReset="flgdialogFile = $event"
        ></FileList>
        <v-btn
            class="mt-2"
            text
          @click="openFolder"
        >
          <v-icon>
            mdi-folder
          </v-icon>
          <v-icon v-if="countfiles !== 0">
            mdi-attachment
          </v-icon>
        </v-btn>
        <v-btn
          class="mt-2"
          text
          @click="copyUrlToClipboard"
        >
          <v-icon>
            mdi-content-copy
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-text-field
              label="項目名"
              v-model="tmpticket.subject"
              dense
            ></v-text-field>
          </v-row>
          <v-row >
            <v-col cols=7>
              <v-textarea
                label="内容"
                v-model="tmpticket.content"
                dense
                rows="16"
              ></v-textarea>
            </v-col>
            <v-col cols=5>
                <v-row>
                  <v-col>
                    <v-select
                      :items="tmpmaster.type"
                      label="種別"
                      max-width="100px"
                      v-model="tmpticket.type"
                      dense
                    ></v-select>
                  </v-col>
                  <v-col>
                    <v-select
                      :items="tmpmaster.status"
                      label="状況"
                      max-width="100px"
                      v-model="tmpticket.status"
                      dense
                    ></v-select>
                  </v-col>
                  <v-col>
                    <v-select
                      :items="tmpmaster.priority"
                      label="優先"
                      max-width="100px"
                      v-model="tmpticket.priority"
                      dense
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-menu
                      v-model="menu1"
                      :close-on-content-click="false"
                      max-width="290"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          label="開始"
                          v-model="tmpticket.dt_start"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          dense
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="tmpticket.dt_start"
                        no-title
                        scrollable
                        @input="menu1=false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col>
                    <v-menu
                      v-model="menu2"
                      :close-on-content-click="false"
                      max-width="290"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          label="終了"
                          v-model="tmpticket.dt_end"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          dense
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="tmpticket.dt_end"
                        no-title
                        scrollable
                        @input="menu2=false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-select
                      :items="projectsname"
                      label="プロジェクト"
                      max-width="100px"
                      v-model="tmpticket.project"
                      disabled
                      dense
                    ></v-select>
                  </v-col>
                  <v-col>
                    <v-text-field
                      label="フェーズ"
                      v-model="tmpticket.phase"
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-select
                      :items="usersname"
                      label="担当"
                      max-width="100px"
                      v-model="tmpticket.user"
                      dense
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  最終更新日 : {{tmpticket.dt_update}}
                </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="blue darken-1"
          text
          @click="delData()"
        >
          削除
        </v-btn>
        <v-spacer></v-spacer>
        <v-checkbox
          v-model="flgmail"
          label="メール送信"
          class = "mt=2"
        >
        </v-checkbox>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          outlined
          @click="closeForm"
        >
          キャンセル
        </v-btn>
        <v-btn
          color="blue darken-1"
          outlined
          @click="uploadData(false)"
        >
          更新
        </v-btn>
        <v-btn
          color="blue darken-1"
          outlined
          @click="uploadData(true)"
        >
          更新/閉じる
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style>
  .v-textarea textarea {
    font-size: 14px;
    line-height: 15px
  }
</style>

<script>
import FileList from './FileList.vue'
const axios = require('axios')
export default {
  props: [
    'tmpdialog',
    'tmpticket',
    'tmpmaster',
    'countfiles',
    'ticketall',
    'projects',
    'projectsname',
    'usersname',
    'users'
  ],
  components: {
    FileList
  },
  data () {
    return {
      dialog: false,
      menu1: false,
      menu2: false,
      flgdialogFile: false,
      fileitems: [],
      ticketpath: '',
      flgmail: false
    }
  },
  methods: {
    checkItems: function () {
      // 必須チェック
      if (this.tmpticket.project === '') {
        alert('Projectを設定してください。')
        return false
      }
      if (typeof this.tmpticket.subject === 'undefined' || this.tmpticket.subject === '') {
        alert('項目名を入力してください。')
        return false
      }
      if (typeof this.tmpticket.user === 'undefined' || this.tmpticket.user === '') {
        alert('担当を設定してください。')
        return false
      }
      if (this.tmpticket.dt_start > this.tmpticket.dt_end) {
        alert('開始日と終了日が正しくありません')
        return false
      }

      // チェックに抵触しない場合
      return true
    },
    // 登録
    uploadData: async function (flgclose) {
      // 同じ題名のチケット登録リマインド
      if (this.checkSubjectForTicket() === true && ('id' in this.tmpticket) === false) {
        const res = window.confirm('同じ題名のチケットが存在します。登録しますか?')
        if (res === false) {
          return
        }
      }

      // 必須チェック
      if (this.checkItems() === false) {
        return
      }

      // 登録処理
      var tmpticketarr = []
      tmpticketarr.push(this.tmpticket)
      await axios.post('/api/tickets', tmpticketarr).then(async (response) => {
        // 登録データのセット
        this.tmpticket = response.data[0]

        // メール送信処理
        if (this.flgmail === true) {
          // メール内容設定
          const maildata = { mailaction: 'send', content: this.mkMsgForMail() }

          // メール送信処理
          await axios.post('/api/mail', maildata).then(async (responsemail) => {
            if (responsemail.data.res === false) {
              alert(responsemail.data.res + '\n' + responsemail.data.msg)
            }
          })
        }

        // フォームのクローズ処理
        if (flgclose === true) {
          this.closeForm()
        }
      })
    },
    // 削除
    delData: async function () {
      if (window.confirm('削除しますか?')) {
        if (this.tmpticket.id !== '') {
          const tmpUrl = '/api/tickets?id=' + this.tmpticket.id + '&projectid=' + this.getDataProjectsFromLocal(this.tmpticket.project).id
          await axios.delete(tmpUrl).then(() => {
            this.closeForm()
          })
        }
      }
    },
    closeForm: function () {
      this.tmpticket = {}
      this.tmpdialog = false
      this.$emit('flgReset', false)
      this.$router.go({ path: '/ticketlist/0', force: true })
    },
    // ファイルリスト画面の起動
    openFolder: async function () {
      if ('ticket_id' in this.tmpticket === false) {
        // 新規の場合には処理しない。
        return
      }
      const tmpPrj = this.getDataProjectsFromLocal(this.tmpticket.project)
      const parm = 'type=ticket' +
              '&id_project=' + tmpPrj.id +
              '&ex=' + tmpPrj.external +
              '&id_ticket=' + this.tmpticket.ticket_id
      var tmpPath = ''
      await axios.get('/api/folder?' + parm).then((response) => {
        tmpPath = response.data.path
      })
      if (tmpPrj.external === false) {
        window.open(tmpPath, null, 'width=500,toolbar=yes,menubar=yes,scrollbars=yes')
      } else {
        this.flgdialogFile = true
        this.ticketpath = tmpPath
        const tmpURL = '/api/storage?path=' + this.ticketpath
        this.fileitems = []
        await axios.get(tmpURL).then((response) => {
          this.fileitems = response.data
        })
      }
    },
    // 同一チケット名が存在しているかの存在確認
    checkSubjectForTicket: function () {
      return this.ticketall.some((t) => t.subject === this.tmpticket.subject)
    },
    // プロジェクト名からプロジェクトオブジェクトを取得
    getDataProjectsFromLocal: function (pjname) {
      for (var p of this.projects) {
        if (p.name === pjname) {
          return p
        }
      }
    },
    // メールデータ作成
    mkMsgForMail: function () {
      var tmpEditFlg = '更新'
      if ('ticket_id' in this.tmpticket === false) {
        tmpEditFlg = '新規'
      }
      var tmpmsg = {
        to: this.getAddressList(),
        subject: '[Ticket]' + tmpEditFlg + '(ID:' + this.tmpticket.ticket_id + '/' + this.tmpticket.subject + ')',
        content: '[Ticket]' + tmpEditFlg + '(ID:' + this.tmpticket.ticket_id + '/' + this.tmpticket.subject + ')' + '\n' +
                  '内容' + '\n' +
                  this.tmpticket.subject + '\n' +
                  this.getURL() + '\n' +
                  '\n' +
                  '※更新されました。内容を確認してください'
      }
      return tmpmsg
    },
    getAddressList: function () {
      var addlist = []
      var tmpMember = this.getDataProjectsFromLocal(this.tmpticket.project).member
      for (const key in tmpMember) {
        addlist.push(this.getAddressFromUsers(tmpMember[key]))
      }
      return addlist
    },
    getAddressFromUsers: function (usr) {
      for (const u in this.users) {
        if (this.users[u].username === usr) {
          return this.users[u].id
        }
      }
    },
    getURL: function () {
      var strURL = (document.URL).split('/')
      var tmpURL = ''
      for (let i = 0; i < strURL.length - 1; i++) {
        tmpURL = tmpURL + strURL[i] + '/'
      }
      tmpURL = tmpURL + this.tmpticket.ticket_id
      return tmpURL
    },
    copyUrlToClipboard: async function () {
      await navigator.clipboard
        .writeText(this.getURL())
        .then(() => {
          alert('Ticket URL to Clipboard.')
        })
        .catch(e => {
          alert(e)
        })
    }
  }
}
</script>
