<template>
  <v-dialog
    persistent
    v-model="flgDialogMsgForm"
    width = "600"
  >
  <v-card
    class="my-auto"
  >
  <div v-for ="(msg, title) of msglist" :key="title">
    <v-card-title>
      {{title}}
    </v-card-title>
    <v-card-text
      style="font-size: 10pt ; white-space:pre-line;"
    >
      {{msg}}
    </v-card-text>
  </div>
  <v-card-actions>
    <v-btn
      color ="red"
      class ="ml-4"
      outlined
      @click="doClose"
    >
      {{btntext.no}}
    </v-btn>
    <v-btn
      color ="green"
      outlined
      @click="doFunc"
    >
      {{btntext.yes}}
    </v-btn>
  </v-card-actions>
  </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    flgDialogMsgForm: {
    },
    msglist: {
    },
    btntext: {
      required: false,
      default: () => ({
        yes: 'OK',
        no: 'キャンセル'
      })
    }
  },

  data: () => ({
  }),
  methods: {
    // キャンセル
    doClose: function () {
      // 親画面の起動フラグをfalseにするための変数を渡す
      this.$emit('flgReset', false)
    },
    // OK
    doFunc: function () {
      // 下記イベントをトリガーとして親画面側で処理を起動する。
      this.$emit('doFunc')
    }
  }
}
</script>
