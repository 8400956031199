<template>
  <v-dialog
    persistent
    v-model="flgDialogExam"
    width = "600"
  >
  <v-card
    class="my-auto"
  >
  <v-card-title>
    受験日：{{selectedevent.code_schedule}}
  </v-card-title>
  <v-card-text>
    <v-row>
      <v-col>
        <!-- 名前 -->
        <v-row
          class="ml-3 mr-2"
          style="height:50px"
        >
          <v-text-field
            label="氏名"
            v-model="reservedetail.name"
          >
          </v-text-field>
        </v-row>
        <v-row
          class="mt-4 ml-3 mr-2"
          style="height:50px"
        >
          <v-text-field
            label="名前(カナ)"
            v-model="reservedetail.name_kana"
          >
          </v-text-field>
        </v-row>
        <v-row
          class="mt-4 ml-3 mr-2"
          style="height:50px"
        >
          <v-menu
            v-model="menu1"
            :close-on-content-click="false"
            max-width="290"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                label="生年月日"
                v-model="reservedetail.dt_birth"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="reservedetail.dt_birth"
              no-title
              scrollable
              @input="menu1=false"
            ></v-date-picker>
          </v-menu>
        </v-row>
        <v-row
          class="mt-4 ml-3 mr-2"
          style="height:50px"
        >
          <v-text-field
            label="郵便番号"
            v-model="reservedetail.post_no"
            @blur="getAddressFromPostCode"
            append-icon="mdi-refresh"
            @click:append="getAddressFromPostCode"
          >
          </v-text-field>
        </v-row>
        <v-row
          class="mt-4 ml-3 mr-2"
          style="height:50px"
        >
          <v-text-field
            label="都道府県"
            v-model="address1"
          >
          </v-text-field>
        </v-row>
        <v-row
          class="mt-4 ml-3 mr-2"
          style="height:50px"
        >
          <v-text-field
            label="市町村郡"
            v-model="address2"
          >
          </v-text-field>
        </v-row>
        <v-row
          class="mt-4 ml-3 mr-2"
          style="height:50px"
        >
          <v-text-field
            label="番地"
            v-model="address3"
          >
          </v-text-field>
        </v-row>
        <v-row
          class="mt-4 ml-3 mr-2"
          style="height:50px"
        >
          <v-text-field
            label="アパートマンション名"
            v-model="address4"
          >
          </v-text-field>
        </v-row>
        <v-row
          class="mt-4 ml-3 mr-2"
          style="height:50px"
        >
          <v-text-field
            label="電話番号"
            v-model="reservedetail.tel"
          >
          </v-text-field>
        </v-row>
        <v-row
          class="mt-4 ml-3 mr-2"
          style="height:50px"
        >
          <v-text-field
            label="メールアドレス"
            v-model="reservedetail.email"
          >
          </v-text-field>
        </v-row>
        <!-- 試験選択 -->
        <v-row
          class="mt-4 ml-3 mr-2"
          style="height:50px"
        >
          <v-select
            v-model="reservedetail.item"
            label="試験選択"
            :items="setType1()"
            @change="resetItems('certi1')"
          >
          </v-select>
        </v-row>
        <!-- 試験科目 -->
        <v-row
          class="mt-4 ml-3 mr-2"
          style="height:50px"
        >
          <v-select
            v-model="reservedetail.item_reservation"
            label="試験科目"
            :items="setType2(reservedetail.item)"
            @change="resetItems('certi2')"
          >
          </v-select>
        </v-row>
        <!-- 支払 -->
        <v-row
          class="mt-6 ml-3"
          style="height:30px"
        >
          <label style="color:red; font-size:16px">
            ※支払期日：{{selectedevent.dt_payment_limit_user}}
          </label>
        </v-row>
        <v-row
          class="mt-4 ml-3 mr-2"
          style="height:50px"
        >
          <v-select
            v-model="reservedetail.type_payment.type_payment"
            label="支払方法"
            :items="mdata.type_payment"
            item-text="label"
            item-value="value"
            @change="resetItems('ticket')"
          >
          </v-select>
        </v-row>
        <v-row
          v-if="reservedetail.type_payment.type_payment=='受験チケット'"
          class="mt-6 ml-3 mr-2"
          style="height:50px"
        >
          <v-text-field
            label="チケット番号"
            v-model="reservedetail.type_payment.ticket_no"
          >
          </v-text-field>
        </v-row>
        <!-- 割引 -->
        <v-row
          class="mt-4 ml-3 mr-2"
          style="height:50px"
        >
          <v-select
            v-model="reservedetail.type_discount.type_discount"
            label="割引選択"
            :items="mdata.type_discount"
            item-text="label"
            item-value="value"
            @change="resetItems('discount')"
          >
          </v-select>
        </v-row>
        <!-- 割引/学割 -->
        <div v-if="reservedetail.type_discount.type_discount=='学割'">
          <v-row
            class="ml-3 mt-4 mr-2"
            style="height:40px"
          >
            <v-text-field
              v-model="reservedetail.type_discount.organization_name"
              label="学校名"
            >
            </v-text-field>
          </v-row>
          <v-row
            class="ml-3 mt-4 mr-2"
            style="height:40px"
          >
            <v-radio-group
              v-model="reservedetail.type_discount.student_teacher"
              mandatory
              row
              class="ml-3 mt-4 mr-2"
              style="height:40px"
            >
              <v-radio
                v-for="sttype in mdata.student_teacher" :key="sttype"
                :label="sttype.label"
                :value="sttype.value"
              ></v-radio>
            </v-radio-group>
          </v-row>
        </div>
        <!-- 割引/会員 -->
        <div>
          <v-row
            v-if="reservedetail.item_reservation=='リユース営業士' && reservedetail.type_discount.type_discount=='会員割引'"
            class="mt-4 ml-3 mr-2"
            style="height:50px"
          >
            <v-text-field
              label="検定コード"
              v-model="reservedetail.type_discount.test_code"
            >
            </v-text-field>
          </v-row>
          <v-row
            v-if="reservedetail.type_discount.type_discount=='会員割引'"
            class="ml-3 mt-4 mr-2"
            style="height:50px"
          >
            <v-text-field
              label="会社名"
              v-model="reservedetail.type_discount.organization_name"
            >
            </v-text-field>
          </v-row>
        </div>
        <v-row
          class="mt-4 ml-3 mr-2"
        >
          <v-textarea
            label="その他"
            rows="3"
            v-model="reservedetail.memo"
          >
          </v-textarea>
        </v-row>
      </v-col>
    </v-row>
  </v-card-text>
  <!-- 処理ボタン -->
  <v-card-actions>
    <v-spacer></v-spacer>
    <v-btn
      text
      color ="red"
      class ="ml-4"
      @click="closeForm"
    >
      キャンセル
    </v-btn>
    <v-btn
      text
      color ="green"
      @click="updateReservation"
      :disabled ="flgUpdateBtn"
    >
      申込
    </v-btn>
  </v-card-actions>
  </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'
export default {
  props: [
    'flgDialogExam',
    'selectedevent'
  ],
  data: () => ({
    flgUpdateBtn: false,
    mdata: { type_payment: {}, type_discount: {} },
    menu1: false,
    menu2: false,
    menu3: false,
    address1: '',
    address2: '',
    address3: '',
    address4: '',
    urlpostcode: '',
    reservedetail: {
      id: '',
      id_event: '',
      code_schedule: '',
      type_reservation: 'exam',
      item: '',
      item_reservation: '',
      name: '',
      name_kana: '',
      parent_name: '',
      dt_birth: '',
      num: '',
      post_no: '',
      address: '',
      tel: '',
      email: '',
      type_payment: {
        type_payment: '現金払い',
        ticket_no: ''
      },
      type_discount: {
        type_discount: 'なし',
        organization_name: '',
        test_code: '',
        student_teacher: '',
        type_exam: '',
        item_exam: '',
        dt_exam: ''
      },
      memo: '',
      status: '',
      dt_update: '',
      id_update: '',
      status_hist: [],
      price: 0,
      reason_notaccept: '',
      reason_memo_notaccept: '',
      memo_admin: '',
      flg_parking_mail: false
    }
  }),
  mounted () {
    // マスターデータの設定
    this.getData()
    // 誕生日のデフォルト設定
    this.reservedetail.dt_birth = this.comDateToStr(new Date())
  },
  methods: {
    // 郵便番号から住所の取得
    getAddressFromPostCode: async function () {
      await axios.get(this.urlpostcode + '?zipcode=' + this.reservedetail.post_no).then(response => {
        if (response.data.status === 200) {
          this.address1 = response.data.results[0].address1
          this.address2 = response.data.results[0].address2 + response.data.results[0].address3
        } else {
          alert(JSON.stringify(response.data.message))
        }
      })
    },
    // マスターデータの取得
    getData: async function () {
      await axios.get('/api/reserveforhp').then(response => {
        // マスターデータの取得
        this.mdata = response.data.master
        this.urlpostcode = response.data.urlpostcode
      })
    },
    // 試験種別1
    setType1: function () {
      var tmpList = []
      var examlist = JSON.parse(sessionStorage.getItem('examlist'))
      for (var i in examlist) {
        if (tmpList.includes(examlist[i].certification) === false) {
          tmpList.push(examlist[i].certification)
        }
      }
      return tmpList
    },
    // 試験種別2
    setType2: function (t1) {
      var tmpList = []
      var examlist = JSON.parse(sessionStorage.getItem('examlist'))
      for (var i in examlist) {
        if (t1 === examlist[i].certification) {
          if (this.selectedevent.name === 'exam') {
            if (examlist[i].flgLongTime === 'normal') {
              tmpList.push(examlist[i].subject)
            }
          } else {
            tmpList.push(examlist[i].subject)
          }
        }
      }
      return tmpList
    },
    // 予約データから試験科目をリセットする
    resetItems: function (itype) {
      if (itype === 'certi1') {
        // 試験科目のクリア
        this.reservedetail.item_reservation = ''
        // 支払方法のクリア(支払方法、受験チケット)
        this.reservedetail.type_payment.type_payment = '現金払い'
        this.reservedetail.type_payment.ticket_no = ''
        // 割引のクリア(割引名、学校/会社名、学生/教員)
        this.reservedetail.type_discount.type_discount = 'なし'
        this.reservedetail.type_discount.organization_name = ''
        this.reservedetail.type_discount.student_teacher = '学生'
      } else if (itype === 'certi2') {
        // 支払方法のクリア(支払方法、受験チケット)
        this.reservedetail.type_payment.type_payment = '現金払い'
        this.reservedetail.type_payment.ticket_no = ''
        // 割引のクリア(割引名、学校/会社名、学生/教員)
        this.reservedetail.type_discount.type_discount = 'なし'
        this.reservedetail.type_discount.organization_name = ''
        this.reservedetail.type_discount.student_teacher = '学生'
      } else if (itype === 'ticket') {
        // 支払方法のクリア(受験チケット)
        this.reservedetail.type_payment.ticket_no = ''
        // 割引のクリア(割引名、学校/会社名、学生/教員)
        this.reservedetail.type_discount.type_discount = 'なし'
        this.reservedetail.type_discount.organization_name = ''
        this.reservedetail.type_discount.student_teacher = '学生'
      } else if (itype === 'discount') {
        this.reservedetail.type_discount.organization_name = ''
        this.reservedetail.type_discount.student_teacher = '学生'
      }
    },
    // キャンセル
    closeForm: function () {
      this.$emit('flgReset', false)
      this.$router.go({ path: this.$router.currentRoute.path, force: true })
    },
    // データ更新
    updateReservation: async function () {
      this.flgUpdateBtn = true

      // 住所データの成形
      this.reservedetail.address = ''
      this.reservedetail.address = this.reservedetail.address +
                                                this.address1 +
                                                this.address2 +
                                                this.address3 +
                                                this.address4

      // 入力チェック
      if (this.checkItem() === false) {
        this.flgUpdateBtn = false
        return
      }

      // 申込した日にち／申込したユーザー(アドレス)を設定
      var tmpDt = new Date()
      this.reservedetail.dt_update = this.comDateToStr(tmpDt)
      this.reservedetail.id_update = this.reservedetail.email

      // イベント関連データの設定
      this.reservedetail.id_event = this.selectedevent.id
      this.reservedetail.code_schedule = this.selectedevent.code_schedule

      // 試験料金の設定
      var type2 = this.reservedetail.item_reservation
      var pay1 = this.reservedetail.type_payment.type_payment
      var dis1 = this.reservedetail.type_discount.type_discount
      this.reservedetail.price = this.getFeeOfExam(type2, pay1, dis1)

      // 予約データの登録
      await axios.post('/api/reserveforhp', this.reservedetail).then((response) => {
        // 登録結果のチェック
        if (response.data.status === false) {
          // 登録エラーの場合
          alert('お申し込みできませんでした。' + response.data.msg)
          this.flgUpdateBtn = false
        } else {
          // 登録成功の場合
          this.closeForm()
        }
      })
      this.flgUpdateBtn = false
    },
    // 試験料金の取得
    getFeeOfExam: function (t2, p1, d1) {
      // t2:試験名,p1:支払タイプ,d1:割引タイプ
      // 受験チケットの場合
      if (p1 === '受験チケット') {
        return 0
      }

      // 対象試験のデータ取得
      const examdata = this.getItemOfExam(t2)

      // 受験チケット以外の場合
      return examdata[this.mdata.feecode[d1]]
    },
    // 入力チェック
    checkItem: function () {
      // 入力チェック
      var requiredItem = [
        { name: 'item', label: '資格' },
        { name: 'item_reservation', label: '科目' },
        { name: 'name', label: '氏名' },
        { name: 'name_kana', label: '名前(カナ)' },
        { name: 'post_no', label: '郵便番号' },
        { name: 'address', label: '住所' },
        { name: 'tel', label: '電話番号' },
        { name: 'email', label: 'メールアドレス' }
      ]
      for (const itm of requiredItem) {
        if (this.reservedetail[itm.name] === '') {
          alert(itm.label + 'を入力してください。')
          return false
        }
      }
      // 試験料金が存在有無にてチェック
      const examdata = this.getItemOfExam(this.reservedetail.item_reservation)
      if (examdata[this.mdata.feecode[this.reservedetail.type_discount.type_discount]] === 'no data') {
        alert('料金の設定が適切ではありません。')
        return false
      }
      // 名前(カナ)
      if (this.reservedetail.name_kana.match(/^[ァ-ヴー]+$/) === null) {
        alert('名前(カナ)は、全角カタカナでご入力ください。')
        return false
      }
      if (this.reservedetail.post_no.match(/^[0-9]{3}-[0-9]{4}$/) === null) {
        alert('郵便番号の指定に誤りがあります。xxx-xxxx形式で入力してください。')
        return false
      }
      // 電話
      if (this.reservedetail.tel.match(/^0\d{1,3}-\d{2,4}-\d{3,4}$/) === null) {
        alert('電話番号の指定に誤りがあります。xxxx-xx-xxxx形式で入力してください。')
        return false
      }
      // メールアドレス
      if (this.reservedetail.email.match(/.+@.+/) === null) {
        alert('メールアドレスが適切ではありません。')
        return false
      }
      // 支払・受験チケット／チケット番号
      if (this.reservedetail.type_payment.type_payment === '受験チケット') {
        if (examdata.ticket === 'NG') {
          alert('受験チケットでのお申込みは受付てません。')
          return false
        }
        if (this.reservedetail.type_payment.ticket_no === '') {
          alert('受験チケットの番号を入力してください。')
          return false
        }
      }
      // 割引・学割／学校名
      if (this.reservedetail.type_discount.type_discount === '学割') {
        if (this.reservedetail.type_discount.organization_name === '') {
          alert('学校名を入力してください。')
          return false
        }
      }
      // 割引・会員／会社名
      if (this.reservedetail.type_discount.type_discount === '会員割引') {
        if (this.reservedetail.type_discount.organization_name === '') {
          alert('会社名を入力してください。')
          return false
        }
      }
      // 割引・会員（リユース営業士）／検定コード
      if (this.reservedetail.item_reservation === 'リユース営業士' && this.reservedetail.type_discount.type_discount === '会員割引') {
        if (this.reservedetail.type_discount.test_code === '') {
          alert('検定コードを入力してください。')
          return false
        }
      }
      // チェックに抵触しない場合
      return true
    },
    // 対象試験のデータ取得(セッションストレージから)
    getItemOfExam: function (t2) {
      var examlist = JSON.parse(sessionStorage.getItem('examlist'))
      for (var i in examlist) {
        if (examlist[i].subject === t2) {
          return examlist[i]
        }
      }
      // 対象が存在しない場合はnull(とりあえず)
      return null
    }
  }
}
</script>
