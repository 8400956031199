<template>
  <v-card>
    <EventDetail
      :flgDialogEvent = "flgDialogEvent"
      :eventdetail = "selectedEvent"
      :flgEdit="flgEdit"
      @flgReset="flgDialogEvent = $event"
    >
    </EventDetail>
    <v-toolbar
      dense
      elevation="0"
    >
      <!-- 新規登録ボタン -->
      <v-row
        style="height:40px"
      >
        <v-col cols="1">
          <v-row>
          <v-btn
            fab
            x-small
            color="green"
            @click="showFormNew"
          >
            <v-icon>
              mdi-pencil
            </v-icon>
          </v-btn>
          </v-row>
        </v-col>
        <!-- 有効イベントのみ表示フラグ -->
        <v-col cols="1">
          <v-checkbox
            v-model="flg_active"
            label="active"
            @click="getDataEvents()"
          ></v-checkbox>
        </v-col>
        <!-- 種別 -->
        <v-col cols="2">
          <v-select
            v-model="typeFilterEvent"
            :items="eventtype"
            label ="種別"
            append-icon="mdi-close-circle-outline"
            @click:append="clearItems"
            dense
          ></v-select>
        </v-col>
        <!-- 汎用検索テキスト -->
        <v-col cols="3">
          <v-text-field
            hide-details
            prepend-icon="mdi-magnify"
            single-line
            v-model="txtFilterEvent"
            label="検索"
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="1">
          <v-row>
          <ExamList
            :flgDialogExamList = "flgDialogExamList"
            @flgReset="flgDialogExamList = $event"
          >
          </ExamList>
          <v-btn
            color="deep-purple lighten-2"
            outlined
            @click="showExamList"
          >
            試験一覧
          </v-btn>
          </v-row>
        </v-col>
        <v-col cols="2">
          <v-file-input
            label="Upload File"
            v-model="selectedfile"
            dense
          ></v-file-input>
        </v-col>
        <v-col cols="1">
          <v-btn
            small
            @click="updateEventFromCsv"
            text
          >
            <v-icon>
              mdi-upload
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="eventlist"
      :search="txtFilterEvent"
      dense
    >
      <template
        v-slot:[`item.name`]="{ item }"
      >
        <v-btn
          text
          class="custom-transform-class text-none"
          @click="showForm(item)"
        >
          {{ item.name }}
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import axios from 'axios'
import EventDetail from './EventDetail.vue'
import ExamList from './ExamList.vue'
export default {
  components: {
    EventDetail,
    ExamList
  },
  data: () => ({
    eventlist: [],
    txtFilterEvent: '',
    typeFilterEvent: '',
    flgDialogEvent: false,
    flgDialogExamList: false,
    flgEdit: false,
    selectedEvent: {},
    flg_active: true,
    mData: {},
    selectedfile: null,
    tmpEventList: []
  }),
  computed: {
    headers () {
      return [
        { text: 'ID', value: 'id', align: ' d-none' },
        { text: '日付', value: 'dt' },
        { text: '名前', value: 'name' },
        { text: '開始', value: 'time_start' },
        { text: '終了', value: 'time_end' },
        { text: '回', value: 'series' },
        { text: '内容', value: 'content' },
        { text: '人数', value: 'num' },
        { text: '予約人数', value: 'num_reserved' },
        { text: '種別', value: 'type_event', filter: this.setFilterType },
        { text: '支払期限', value: 'dt_payment_limit_user' },
        { text: '備考', value: 'memo' },
        { text: 'Status', value: 'status' }
      ]
    }
  },
  mounted () {
    this.getDataEvents()
    this.eventtype = JSON.parse(sessionStorage.getItem('master')).event.type
  },
  methods: {
    // データ取得
    getDataEvents: async function () {
      await axios.get('/api/events?active=' + this.flg_active.toString()).then(response => {
        this.eventlist = response.data
      })
    },
    showFormNew: function () {
      this.flgEdit = false
      this.flgDialogEvent = true
      const dttoday = new Date()
      this.selectedEvent = {
        id: '',
        dt: this.comDateToStr(dttoday),
        time_start: '08:30',
        time_end: '09:30',
        name: '',
        series: '',
        content: '',
        num: '',
        type_event: 'exam',
        memo: '',
        status: '準備中'
      }
    },
    showForm: function (item) {
      this.flgEdit = true
      this.flgDialogEvent = true
      this.selectedEvent = item
    },
    showExamList: function () {
      this.flgDialogExamList = true
    },
    // 種別でのフィルタ
    setFilterType: function (value) {
      if (!this.typeFilterEvent) {
        return true
      }
      return value.includes(this.typeFilterEvent)
    },
    clearItems: function () {
      this.typeFilterEvent = null
    },
    updateEventFromCsv: function () {
      const reader = new FileReader()
      reader.onload = () => {
        const lines = reader.result.split('\n')
        const tmpHeader = lines[0].split(',')
        for (var k = 1; k < lines.length; k++) {
          var tmpDat = lines[k].split(',')
          if (tmpDat.length === 10) {
            var tmpEvent = {}
            for (var i = 0; i < tmpDat.length; i++) {
              tmpEvent[tmpHeader[i].replace('\r', '')] = tmpDat[i].replace('\r', '')
            }
            tmpEvent.id = ''
            const tmpEventDt = tmpEvent.dt.split('/')
            tmpEvent.dt = this.comDateToStr(new Date(tmpEventDt[0], tmpEventDt[1] - 1, tmpEventDt[2]))
            if (this.checkItems(tmpEvent) === true) {
              this.tmpEventList.push(tmpEvent)
            }
          }
        }
        axios.post('/api/events', this.tmpEventList).then(response => {
          this.getDataEvents()
        })
        this.selectedfile = null
      }
      reader.readAsText(this.selectedfile)
    },
    checkItems: function (itm) {
      // 時刻の前後関係チェック
      if (itm.time_start > itm.time_end) {
        alert('開始時刻と終了時刻が正しくありません')
        return false
      }

      // 必須チェック、データ型チェック
      if (itm.name === '') {
        alert('名前を入力してください。')
        return false
      }
      if (itm.num === '' || isNaN(itm.num) === true) {
        alert('人数を数値で入力してください。')
        return false
      }
      if (isNaN(itm.series) === true) {
        alert('回数は数値で入力してください。')
        return false
      }

      // チェックに抵触しない場合
      return true
    }
  }
}
</script>
