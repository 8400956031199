export default {
  methods: {
    // 文字列を日付に変換(yyyymmddhhMMss)
    comStrToDate: function (strD) {
      var y = strD.slice(0, 4)
      var m = strD.slice(4, 6)
      var d = strD.slice(6, 8)
      var h = strD.slice(8, 10)
      var mi = strD.slice(10, 12)
      var s = strD.slice(12, 14)
      alert(new Date(y, m, d, h, mi, s))
      return new Date(y, m, d, h, mi, s)
    },
    // 日付の文字列を取得
    comDateToStr: function (dt, flgsec = false) {
      var y = dt.getFullYear()
      var m = ('00' + (dt.getMonth() + 1)).slice(-2)
      var d = ('00' + dt.getDate()).slice(-2)
      var strday = (y + '-' + m + '-' + d)
      if (flgsec === true) {
        var h = ('00' + (dt.getHours())).slice(-2)
        var mi = ('00' + (dt.getMinutes())).slice(-2)
        strday = strday + ' ' + h + ':' + mi
      }
      return strday
    },
    // 当該月の1日を返す
    comGetFirstDate: function (dt) {
      var strDt = this.comDateToStr(dt)
      strDt = strDt.slice(0, 8) + '01'
      return new Date(strDt)
    },
    // 1ヵ月加算する(年またぎ考慮)
    comAddMonthDate: function (dt, mm) {
      var tmpDt = new Date(this.comDateToStr(dt))
      var y = tmpDt.getFullYear()
      var m = tmpDt.getMonth() + 1
      if (m + mm > 12) {
        tmpDt.getFullYear(y + 1)
        tmpDt.setMonth(m + mm - 12 - 1)
      } else {
        tmpDt.setMonth(m + mm - 1)
      }
      return tmpDt
    },
    // 1日加算する(月跨ぎ考慮)
    comGetBusinessDate: function (dt, num) {
      // 祝日の取得
      var count = 0
      var tmpDt = new Date(this.comDateToStr(dt))
      while (count < num) {
        tmpDt = new Date(tmpDt.setDate(tmpDt.getDate() + 1))
        if (this.comisHoliday(tmpDt) === false) {
          count++
        }
      }
      return tmpDt
    },
    // 複数の配列から共通する要素を配列として取得する。
    getArrayOfSameValue: function (val) {
      // val は比較する配列の配列
      const countArray = val.length
      // 配列が存在しない場合は終了
      if (countArray === 0) {
        return []
      }
      // 1番目の配列を基準の配列として設定
      var stdArray = val[0].concat()
      var tmpArray = []

      var n = 0
      while (n < stdArray.length) {
        var i = 1
        var flg = true
        while (i < countArray) {
          if (val[i].includes(stdArray[n]) === false) {
            flg = false
            break
          }
          i = i + 1
        }
        // flgがfalseの場合には追加しない
        if (flg === true) {
          tmpArray.push(stdArray[n])
        }
        n = n + 1
      }
      return tmpArray
    },
    // 特定キーの値の配列(重複排除)の取得
    getArrayOfMergeItem: function (val, valkey) {
      if (val.length === 0) {
        return []
      }
      var tmpArray = []
      var i = 0
      while (i < val.length) {
        if (tmpArray.includes(val[i][valkey]) === false) {
          tmpArray.push(val[i][valkey])
        }
        i = i + 1
      }
      return tmpArray
    },
    // 曜日の取得
    comgetWeekday: function (dt) {
      // 引数は文字列
      var tmpdt = new Date(dt)
      return ['日', '月', '火', '水', '木', '金', '土'][tmpdt.getDay()]
    },
    // 文字列チェック
    isString: function (value) {
      if (typeof value === 'string' || value instanceof String) {
        return true
      } else {
        return false
      }
    },
    // 休日チェック
    comisHoliday: function (dt) {
      // 引数データ型は日付型
      // 祝日チェック
      const holiday = JSON.parse(sessionStorage.getItem('master')).holiday
      // var tmpDt = new Date(this.comDateToStr(dt))
      if (holiday.includes(this.comDateToStr(dt)) === true) {
        return true
      }
      // 土日チェック
      if ([0, 6].includes(dt.getDay()) === true) {
        return true
      }

      return false
    },
    // 日付の形式チェック(yyyy-mm-dd)
    comisDate: function (strDt) {
      const dtarr = strDt.split('-')
      // 3つの配列に分割できない場合にエラー
      if (dtarr.length !== 3) { return false }

      // 各要素が数字でない場合にエラー
      for (var i = 0; i < dtarr.length; i++) {
        if (isNaN(dtarr[i]) === true) { return false }
      }

      // 日付データの生成
      const dt0 = new Date(parseInt(dtarr[0]), parseInt(dtarr[1]) - 1, parseInt(dtarr[2]))

      // 元の月と日付データから生成された月の一致を確認
      if (parseInt(dtarr[1]) === dt0.getMonth() + 1) {
        return true
      } else {
        return false
      }
    },
    // 指定月の日付データの生成(文字列yyyy-mm-dd)
    comgetDateOfMonth: function (yyyy, mm) {
      // yyyy,mmは文字列

      // 対象月の1日の日付の生成
      var tmpDt = new Date(yyyy + '-' + mm + '-01')

      // 当該月の日付の配列
      var tmpDts = []
      for (var i = 1; i < 32; i++) {
        // 対象月の日付部分を動的に変更する
        tmpDt.setDate(i)

        // 動的に生成している日付の月が翌月になるまで、日付の文字列を生成
        if ((tmpDt.getMonth() + 1) === Number(mm)) {
          var d = this.comDateToStr(tmpDt)
          tmpDts.push(d)
        } else {
          break
        }
      }
      return tmpDts
    }
  }
}
