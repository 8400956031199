<template>
  <v-dialog
    persistent
    v-model="flgDialog"
    width="600"
  >
    <v-card
      class="mx-auto"
    >
      <v-card-title class="ml-2">有給休暇 特別付与</v-card-title>
      <v-card-text class="mt-3">
        <v-row>
          <v-col cols="3">
            <!-- 付与日 -->
            <v-row>
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                max-width="290"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    label="開始"
                    v-model="selected_data.dt_holidayex"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    dense
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="selected_data.dt_holidayex"
                  no-title
                  scrollable
                  @input="setDate"
                ></v-date-picker>
              </v-menu>
            </v-row>
            <!-- 付与月 -->
            <v-row>
              {{ selected_data.yyyymm_holidayex }}
            </v-row>
          </v-col>
          <!-- 付与日数 -->
          <v-col cols="2">
            <v-text-field
              label="付与日数"
              v-model="selected_data.count"
              outlined
              rows="3"
            >
            </v-text-field>
          </v-col>
          <!-- 備考 -->
          <v-col cols="5">
            <v-textarea
              label="メモ"
              v-model="selected_data.memo"
              outlined
              rows="3"
            >
            </v-textarea>
          </v-col>
          <v-col cols="2">
            <v-btn
            color="green lighten-2"
            outlined
            right
            @click="updateData"
          >
            更新
          </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-data-table
            :headers="headers"
            :items="holidayex"
            dense
          >
            <template
              v-slot:[`item.dt_holidayex`]="{ item }"
            >
              <v-btn
                text
                class="custom-transform-class text-none"
                @click="setData(item)"
              >
                {{ item.dt_holidayex }}
              </v-btn>
            </template>
            <template
              v-slot:[`item.del`]="{ item }"
            >
              <v-btn
                @click="delData(item)"
                text
                small
              >
                <v-icon>
                  mdi-trash-can-outline
                </v-icon>
              </v-btn>
            </template>
          </v-data-table>
          <v-card-actions>
            <v-btn
              color="deep-purple lighten-2"
              outlined
              right
              @click="closeForm"
            >
              キャンセル
            </v-btn>
          </v-card-actions>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
const axios = require('axios')
export default {
  props: [
    'flgDialog',
    'yyyymm',
    'id',
    'holidayex'
  ],
  data: () => ({
    menu1: false,
    selected_data: {
      dt_holidayex: '',
      yyyymm_holidayex: '',
      count: 0,
      memo: '',
      id: '',
      seq: ''
    }
  }),
  computed: {
    headers () {
      return [
        { text: '付与日', value: 'dt_holidayex' },
        { text: '付与月', value: 'yyyymm_holidayex' },
        { text: '付与日数', value: 'count' },
        { text: '備考', value: 'memo' },
        { text: '削除', value: 'del' }
      ]
    }
  },
  mounted () {
    this.selected_data.dt_holidayex = this.comDateToStr(new Date())
    this.setDate()
  },
  methods: {
    // 付与月等の設定
    setDate: function () {
      this.selected_data.yyyymm_holidayex = this.selected_data.dt_holidayex.substr(0, 7)
      this.menu1 = false
    },
    // データ更新
    updateData: async function () {
      // 数字のチェック
      if (isNaN(this.selected_data.count) === true) {
        alert('付与日数に数字を入れてください。')
        return
      }

      // ユーザーidの設定
      this.selected_data.id = this.id
      await axios.post('/api/workreport', this.selected_data).then((response) => {
        this.holidayex = response.data
        this.clearData()
      }).catch(error => {
        alert(error)
      })
    },
    // データ削除
    delData: async function (item) {
      await axios.delete('/api/workreport', { data: item }).then((response) => {
        this.holidayex = response.data
      }).catch(error => {
        alert(error)
      })
    },
    // データの入力ボックスへのセット
    setData: function (item) {
      this.selected_data = item
    },
    // データクリア
    clearData: function () {
      this.selected_data = {
        dt_holidayex: this.comDateToStr(new Date()),
        yyyymm_holidayex: '',
        count: 0,
        memo: '',
        id: '',
        seq: ''
      }
      // 付与月の設定
      this.setDate()
    },
    // 画面を閉じる
    closeForm: function () {
      this.flgDialog = false
      this.$emit('flgReset', false)
      this.$emit('flgyyyymm', this.yyyymm)
    }
  }
}
</script>
