import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import { initializeApp } from 'firebase/app'
import vuetify from './plugins/vuetify'
import VueAxios from 'vue-axios'
import myVueMixin from './com_func_mixin'

Vue.config.productionTip = false

const firebaseConfig = {
  apiKey: 'AIzaSyCOuy5sTUnS5PCa4JPBcoAv7dxt2dgnQ6M',
  authDomain: 'reservemanage.firebaseapp.com',
  databaseURL: 'https://reservemanage.firebaseio.com',
  projectId: 'reservemanage',
  storageBucket: 'reservemanage.appspot.com',
  messagingSenderId: '587391042314',
  appId: '1:587391042314:web:47e0df19aef432a88b30f3',
  measurementId: 'G-6DEP4T9VBZ'
}
initializeApp(firebaseConfig)

Vue.use(VueAxios, axios)
Vue.mixin(myVueMixin)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
